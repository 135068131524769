import styled from 'styled-components';

export const BirthDate = styled.div``;

export const Name = styled.div``;

export const Email = styled.div``;

export const ListItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 32px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 1px 1px 0px rgba(10, 13, 61, 0.08), 0px 1px 3px 0px rgba(67, 71, 146, 0.16);
`;

export const PartnerInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Status = styled.div`
  padding: 0 16px;
  background: ${({ theme }) => theme.colors.blue.lighterBlue};
  border-radius: 4px;
  height: fit-content;
  margin-top: 8px;

  font-size: 18px;
  line-height: 24px;
  /* should be added to list of colors */
  color: ${({ theme }) => theme.colors.blue.darkBlue};

  text-align: center;
  @media ${({ theme }) => theme.queries.minTablet} {
    margin-top: 0px;
  }
`;
