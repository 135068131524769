import React from 'react';

import { PurchaseConfirmation } from 'screens/Book/steps/Success/PurchaseConfirmation/PurchaseConfirmation';

import { DashboardButton } from './Success.styled';
import { Props } from './Success.types';
import { getAppointmentName } from './Success.utils';

export const SuccessStep = ({
  appointmentType,
  selectedAppointmentTypes,
  selectedProduct,
  purchasedProduct,
  partnerClinicSelected
}: Props): JSX.Element => {
  const appointmentName = getAppointmentName(
    selectedProduct,
    selectedAppointmentTypes,
    appointmentType
  );

  return (
    <>
      <PurchaseConfirmation
        purchase={purchasedProduct}
        partnerClinicDetails={partnerClinicSelected}
        appointmentName={appointmentName}
      />
      <DashboardButton href="/">Go To Dashboard</DashboardButton>
    </>
  );
};
