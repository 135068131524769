import * as React from 'react';

import Button from 'components/Buttons/NewButton';
import { themes } from 'kb-shared';
import styled from 'styled-components';

import { ConsentOption } from '../InformedConsent.types';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 28px 16px 28px 16px;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.2);
  :hover {
    box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.4);
  }
  @media screen and (min-width: ${themes.breakpoints.tabletLandscape}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleText = styled.span`
  font-size: 24px;
  color: ${themes.colors.neutral.primaryNavy};
`;

const SubtitleText = styled.span`
  font-size: 14px;
  color: ${themes.colors.neutral.primaryNavy};
  white-space: pre-wrap;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 18px;

  @media screen and (min-width: ${themes.breakpoints.tabletLandscape}) {
    padding-top: 0px;
  }
`;

type Props = {
  option: ConsentOption;
  onSelect: (option: ConsentOption) => Promise<void>;
  partnerDecisionOptionId?: number;
  partnersToMismatch: boolean | null;
};

export const AdditionalOptionQuestion = ({
  option,
  partnerDecisionOptionId,
  onSelect,
  partnersToMismatch
}: Props): JSX.Element => {
  const { id, name, description } = option;

  const partnerSelectedThisOption = partnerDecisionOptionId === Number(id);
  const buttonText = `Select this option ${partnerSelectedThisOption ? '*' : ''}`;

  const isDisabled = shouldDisableButton(
    partnerDecisionOptionId,
    partnerSelectedThisOption,
    partnersToMismatch
  );

  return (
    <Container>
      <TextContainer>
        <TitleText>{name}</TitleText>
        <SubtitleText>{description}</SubtitleText>
      </TextContainer>
      <ButtonContainer>
        <Button text={buttonText} disabled={isDisabled} onClick={() => onSelect(option)} />
        {partnerSelectedThisOption && (
          <SubtitleText>* Your partner has selected this option.</SubtitleText>
        )}
      </ButtonContainer>
    </Container>
  );
};

const shouldDisableButton = (
  partnerDecisionOptionId: number | undefined,
  partnerSelectedThisOption: boolean,
  partnersToMismatch: boolean | null
) => {
  if (!partnerDecisionOptionId || partnersToMismatch == null) return false;

  // if they need to mismatch
  // disable if partner did select this option
  // if they need to match
  // disable if partner did not select this option
  return partnersToMismatch ? partnerSelectedThisOption : !partnerSelectedThisOption;
};
