import { Heading, Text } from 'components/v2/Typography';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 14px;
  gap: 32px;

  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzE0IiBoZWlnaHQ9IjEyMyIgdmlld0JveD0iMCAwIDMxNCAxMjMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMzkuODI1MiA5LjEzNzIxTDM0LjMwOTkgMC4yNDc4MDFMMC44NDE3OTMgMjMuNjAyOEw1LjU2ODkyIDMxLjI0OUMzMS40MjI3IDczLjAzNDQgNzguNzI4OCAxMjIuODQyIDE1Ny4yNjQgMTIyLjg0MkMyMzUuODAyIDEyMi44NDIgMjgzLjEwOCA3My4wMjk4IDMwOC45NjcgMzEuMjM4NUwzMTMuNzUzIDIzLjQ5NzlMMjgwLjE0IDAuNzE2NTAyTDI3NC43MDQgOS40ODYwM0MyNDYuMzM1IDU1LjE4NjYgMjAzLjUzMiA4MS4zOTY0IDE1Ny4yNjQgODEuMzk2NEMxMTEuMTMxIDgxLjM5NjQgNjguMzIyMSA1NS4wNTc4IDM5LjgyNSA5LjEzNzFMMzkuODI1MiA5LjEzNzIxWiIgZmlsbD0iI0Y5RTE2OCIvPgo8L3N2Zz4K');
  background-position: top 60px left 0;
  background-repeat: no-repeat;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const PaymentFormWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const Headline = styled(Heading).attrs({ tag: 'h1', styledAs: 'h1' })`
  text-align: center;
  justify-content: center;
`;

export const SupportText = styled(Text).attrs({ tag: 'p' })`
  text-align: left;

  @media ${({ theme }) => theme.queries.minTablet} {
    text-align: center;
  }
`;

export const BookedWithText = styled(Heading).attrs({ tag: 'div', styledAs: 'h4' })`
  padding: 8px 16px;
  background-color: ${({ theme }) => theme.colors.yellow.primary};
`;
