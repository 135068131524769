import { YellowButton } from 'components/Buttons/Buttons';
import styled from 'styled-components';

export const CardListItem = styled.li`
  width: 100%;
  padding: 16px 24px;
  margin: 12px 0;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardContentRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CardContentRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;

  > div {
    margin-top: 1.5em;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
    flex-wrap: wrap;
  }
`;

export const CardContentRowTextItems = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CardContentRowItem = styled.div`
  min-width: 130px;

  *:nth-child(2) {
    margin-top: 0.5em;
  }
`;

export const CardContentRowButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;

  > button {
    margin-right: 1em;
  }
`;

export const PayButton = styled(YellowButton)`
  margin-left: 8px;
  padding: 8px 24px;

  font-size: 14px;
  text-transform: uppercase;
`;

export const LabelHolder = styled.div<{ $status?: 'paid' | 'outstanding' | 'default' }>`
  text-align: center;
  background: ${({ theme, $status }) => {
    if ($status === 'paid') return theme.colors.green.lightestGreen;
    if ($status === 'outstanding') return theme.colors.semantic.lightPink;
    return theme.colors.neutral.lavenderLight;
  }};
  color: ${({ theme, $status }) => {
    if ($status === 'paid') return theme.colors.green.darkestGreen;
    if ($status === 'outstanding') return theme.colors.semantic.darkRed;
    return theme.colors.neutral.primaryNavy;
  }};
  padding: 6px 16px;
  border-radius: 4px;
  width: 100%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
    width: 100%;
  }
`;

export const LabelsColumn = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 140px;

  > span {
    margin-bottom: 2px;
  }
`;
