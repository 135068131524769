import { capitalize } from 'lodash';

type Fields = {
  [key: string]: string | undefined;
};

export const validate = (fields: Fields) => {
  const errors = Object.keys(fields).reduce((errorMap: Fields, key: string) => {
    if (!fields[key]) {
      errorMap[key] = "Can't be blank";
    }

    return errorMap;
  }, {});

  return errors;
};

export const validateAddressFields = (fields: Fields, optionalFields?: Array<string>) => {
  const missingFields: string[] = [];
  Object.keys(fields).forEach(field => {
    if (optionalFields?.includes(field)) {
      return;
    }

    if (!fields[field as keyof Fields]) {
      missingFields.push(field === 'zipcode' ? 'Zip Code' : capitalize(field));
    }
  });

  return missingFields;
};
