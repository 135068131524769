import { gql } from '@apollo/client';

export const CUSTOM_TILES_QUERY = gql`
  query customTile($customTitleId: Int!) {
    customTile(customTitleId: $customTitleId) {
      id
      name
      tileType
      employerId
      header
      boldText
      linkUrl
      regularText
      buttonText
      buttonStyle
      listItems
      listStyle
    }
  }
`;
