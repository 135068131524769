import { useQuery } from '@apollo/client';

import { PATIENT_APPOINTMENTS } from 'kb-shared/graphql/queries';
import { BugTracker } from 'kb-shared/utilities/bugTracker';

import { MY_JOURNEY_QUERY } from './Dashboard.graphql';
import { JourneyResponse, PatientAppointments } from './Dashboard.types';

export const usePatientAppointments = () => {
  const { data, loading, refetch } = useQuery<PatientAppointments>(PATIENT_APPOINTMENTS, {
    onError: error => BugTracker.notify(error, 'PatientAppointmentsFetch')
  });

  return { data, loading, refetch };
};

export const useMyJourney = () => {
  const { data, loading } = useQuery<JourneyResponse>(MY_JOURNEY_QUERY, {
    onError: error => BugTracker.notify(error, 'PatientJourneyFetch')
  });

  return { data, loading };
};
