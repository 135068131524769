import { fontCSS, defaultState } from 'components/v2/common.styled';
import styled from 'styled-components';

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.white};
  position: relative;
`;

export const OptionList = styled.div`
  margin: 8px 0 0 0;
  overflow: auto;
  position: absolute;
  z-index: 100;
  background: white;
  max-height: 370px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 8px 12px 0px rgba(10, 13, 61, 0.08), 0px 1px 4px 0px rgba(10, 13, 61, 0.12),
    0px 1px 4px 1px rgba(67, 71, 146, 0.16);
  width: 100%;
  max-width: 100%;
`;

export const OptionStyled = styled.div`
  padding: 12px 16px;
  ${fontCSS}
  color: ${defaultState.textColor};
  line-height: 24px;
  cursor: pointer;
  outline: none;

  &:hover, &:focus {
    border-radius: 4px;
    background: ${({ theme }) => theme.colors.neutral.lavenderWhite};
  }

  &:focus-visible {
    border-radius: 4px;
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.neutral.lavenderWhite};
  }
`;

export const SearchContainer = styled.div`
  position: relative;
`;
