import { useMutation } from '@apollo/client';
import React, { useState } from 'react';

import Modal from 'components/Modal/Modal';
import { Button } from 'components/v2/Buttons/Button';
import { PhoneFieldBasic, TextField } from 'components/v2/Form';
import { Text } from 'components/v2/Typography';
import { REQUEST_MFA_VALIDATION_CODE, VALIDATE_MFA_CODE } from 'kb-shared/graphql/mutations';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { formatPhoneNumber } from 'kb-shared/utilities/phones';
import { showErrorToast, showSuccessToast } from 'utilities/notificationUtils';

import { ButtonContainer, Container } from './EnableMfaModal.styled';
import {
  EnableMfaModalProps,
  RequestMfaValidationCodeResponse,
  RequestMfaValidationCodeVariables,
  ValidateMfaCodeResponse,
  ValidateMfaCodeVariables
} from './EnableMfaModal.types';

export const EnableMfaModal = (props: EnableMfaModalProps) => {
  const { open, onClose, phone, isUsPatient } = props;
  const [phoneToUse, setPhoneToUse] = useState(phone);
  const [codeToValidate, setCodeToValidate] = useState('');
  const [step, setStep] = useState<'first' | 'second'>('first');
  const [submitting, setSubmitting] = useState(false);
  const [requestMfaValidationCode] = useMutation<
    RequestMfaValidationCodeResponse,
    RequestMfaValidationCodeVariables
  >(REQUEST_MFA_VALIDATION_CODE);
  const [validateMfaCode] = useMutation<ValidateMfaCodeResponse, ValidateMfaCodeVariables>(
    VALIDATE_MFA_CODE
  );

  const enableActionButton = () => {
    if (submitting) return false;
    if (step === 'first') return Boolean(phoneToUse);
    else return Boolean(codeToValidate);
  };

  const onActionButtonClick = () => {
    setSubmitting(true);
    if (step === 'first') {
      onValidationCodeRequested();
    } else {
      onCodeValidation();
    }
  };

  const onValidationCodeRequested = () => {
    const variables: RequestMfaValidationCodeVariables = { phone: phoneToUse.trim() };
    requestMfaValidationCode({
      variables
    })
      .then(r => {
        const succeeded = r.data?.requestMfaValidationCode?.succeeded;
        const errorCode = r.data?.requestMfaValidationCode?.errorCode;
        if (succeeded) {
          setStep('second');
        } else if (errorCode === 'MfaBlocked') {
          showErrorToast(
            'You had too many attempts in a short period of time. Please try again in 1 hour.'
          );
        } else {
          BugTracker.notify(errorCode, 'RequestMfaValidationCode');
          showErrorToast('Failed to submit request for validation code. Please try again.');
        }
      })
      .catch(error => {
        BugTracker.notify(error, 'RequestMfaValidationCode');
        showErrorToast('Failed to submit request for validation code. Please try again.');
      })
      .finally(() => setSubmitting(false));
  };

  const onCodeValidation = () => {
    const variables: ValidateMfaCodeVariables = {
      phone: phoneToUse.trim(),
      code: codeToValidate.trim()
    };
    validateMfaCode({
      variables
    })
      .then(r => {
        const succeeded = r.data?.validateMfaCode?.succeeded;
        const errorCode = r.data?.validateMfaCode?.errorCode;
        if (succeeded) {
          showSuccessToast('2FA with SMS is turned ON!');
          setCodeToValidate('');
          setStep('first');
          onClose(phoneToUse);
        } else if (errorCode === 'WrongMfaCode') {
          showErrorToast('Entered code is invalid.');
        } else if (errorCode === 'MfaCodeExpired') {
          showErrorToast('Entered code is expired.');
        } else if (errorCode === 'MfaBlocked') {
          showErrorToast(
            'You had too many attempts in a short period of time. Please try again in 1 hour.'
          );
        } else {
          BugTracker.notify(errorCode, 'ValidateMfaCode');
          showErrorToast('Failed to validate the code. Please try again.');
        }
      })
      .catch(error => {
        BugTracker.notify(error, 'ValidateMfaCode');
        showErrorToast('Failed to validate the code. Please try again.');
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <Modal onRequestClose={() => onClose()} open={open}>
      <Container>
        <div>
          {step === 'first' && (
            <Text size="md" tag="p">
              Please enter mobile number you have access to. On that number we will send you SMS
              messages with one time codes you will need to enter to sign-in to Patient Portal.
            </Text>
          )}
          {step === 'second' && (
            <Text size="md" tag="p">
              {`Please check your mobile number ${formatPhoneNumber(
                phoneToUse
              )} and enter code we have sent you.`}
            </Text>
          )}
        </div>
        <div>
          {step === 'first' && (
            <PhoneFieldBasic
              id="mfaMobilePhoneInput"
              country={isUsPatient ? 'US' : 'other'}
              label="Mobile Number"
              placeholder={isUsPatient ? '(555) - 555-5555' : '(+XXXX)Phone Number'}
              value={phoneToUse}
              onChange={e => setPhoneToUse(e.currentTarget.value)}
            />
          )}
          {step === 'second' && (
            <TextField
              id="mfaCodeInput"
              label="SMS code"
              placeholder="Enter code"
              value={codeToValidate}
              onChange={e => setCodeToValidate(e.currentTarget.value)}
            />
          )}
        </div>
        <ButtonContainer>
          <Button label={'Cancel'} category="primary-dark" size="lg" onClick={() => onClose()} />
          <Button
            label={step === 'first' ? 'Validate number' : 'Turn ON 2FA'}
            category="primary"
            isDisabled={!enableActionButton()}
            size="lg"
            onClick={onActionButtonClick}
          />
        </ButtonContainer>
      </Container>
    </Modal>
  );
};
