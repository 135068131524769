import React, { lazy, Suspense, useState } from 'react';

import { PdfErrorContainer } from './LazyPdfViewer.styled';
import { Fallback } from './PdfConsentViewer.styled';

const LazyPdfViewer = lazy(() => import('./LazyPdfViewer'));

export const PdfConsentViewer = ({ pdf }: { pdf: string }) => {
  const [loadError, setLoadError] = useState(false);

  return (
    <>
      {!loadError ? (
        <Suspense fallback={<Fallback>Loading PDF viewer...</Fallback>}>
          <LazyPdfViewer pdf={pdf} onError={() => setLoadError(true)} />
        </Suspense>
      ) : (
        <PdfErrorContainer>
          <p>Unable to render the PDF.</p>
          <a href={pdf} target="_blank" rel="noopener noreferrer">
            Clink here to open in a new tab
          </a>
        </PdfErrorContainer>
      )}
    </>
  );
};
