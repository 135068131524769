import React from 'react';

import { Text } from 'components/v2/Typography';
import { isAppointmentType, utils, AppointmentType } from 'kb-shared';
import theme from 'kb-shared/theme';
import { isCoachingAppointment } from 'kb-shared/utilities/appointment.helper';

import { Header, Container, Price, SmallPrint } from './PaymentInfo.styled';

const { isGYNAppointment } = utils;

export type Props = {
  appointment: AppointmentType | null;
  discountPrice?: number;
  hasEmployerMembership?: boolean;
};

export const PaymentInfo = ({ appointment, discountPrice, hasEmployerMembership }: Props) => {
  if (!isAppointmentType(appointment)) return null;

  const appointmentCategoryId = parseInt(appointment.category.id);
  if (isGYNAppointment(appointmentCategoryId)) return null;

  const isAppointmentFree = appointment.rateCents === 0;
  if (discountPrice === 0 || isAppointmentFree) return <Price>Free of charge</Price>;

  const isCoaching = isCoachingAppointment(appointment);
  const shouldChargeAtBookingForHolisticHealth = appointment.shouldChargeAtBooking;

  // If "Coaching" = 0, "Holistic Health Covered" can be either 0 or 1.
  // If "Coaching" = 1 and "Requires Immediate Payment" = TRUE, then "Holistic Health Covered" = 0.
  // If "Coaching" = 1 and "Requires Immediate Payment" = FALSE, then "Holistic Health Covered" = 1.
  if (isCoaching && !shouldChargeAtBookingForHolisticHealth) return null;

  if (!isCoaching)
    return (
      <Container>
        <Header>Payment Due at Appointment*</Header>
        <SmallPrint>
          *Subject to insurance coverage. If not covered, you will be responsible for the total +
          lab work.
        </SmallPrint>
      </Container>
    );

  const price = (discountPrice || appointment.rateCents) / 100;
  const showCreditCardHSAOrFSAInfo = isCoaching && !hasEmployerMembership;

  return (
    <Container>
      <Price>{`$${price} due ` + (isCoaching ? 'today' : 'at appointment')}</Price>
      {showCreditCardHSAOrFSAInfo && (
        <Text color={theme.colors.white} size="sm">
          We accept credit card, HSA/FSA. Have an employer benefit with wellness coverage? Call us
          today to schedule.
        </Text>
      )}
    </Container>
  );
};
