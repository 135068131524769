import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { InsuranceList } from 'components/InsuranceList/InsuranceList';
import { Loader } from 'components/Loader/Loader';
import { Toggle } from 'components/v2/Toggle';
import { usePatient } from 'hooks';
import { useInterstitialStatus } from 'hooks/useInterstitialStatus';
import { usePatientInsuranceProviders } from 'hooks/usePatientInsuranceProviders';
import { bookingAnalytics } from 'kb-shared/booking/utils/bookingAnalytics';
import { UPDATE_DECLARED_INSURANCE } from 'kb-shared/graphql/mutations';
import { InfoBox } from 'screens/InterstitialModal/components/InfoBox';
import { INSURANCE_TAB_INFO_BOX_MESSAGE } from 'screens/InterstitialModal/InterstitialModal.constants';
import { STATUS } from 'screens/InterstitialModal/InterstitialModal.graphql';

import { ActionButton, ActionsContainer, Container } from './Insurance.styled';

export const InsuranceStep = ({ onNextStep }: { onNextStep?: () => void }) => {
  const history = useHistory();
  const { patient } = usePatient();
  const { status, loading: interstitialStatusLoading } = useInterstitialStatus();
  const { data, loading, refetch } = usePatientInsuranceProviders();
  const [updatePatient, { loading: updatingPatient }] = useMutation(UPDATE_DECLARED_INSURANCE);

  const [declaredNoInsurance, setDeclaredNoInsurance] = useState<boolean>(
    Boolean(status?.declaredNoInsurance)
  );
  const [formOpened, setFormOpened] = useState(false);

  useEffect(() => {
    setDeclaredNoInsurance(Boolean(status?.declaredNoInsurance));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status?.declaredNoInsurance]);

  const onToggleChange = (value: boolean) => {
    setDeclaredNoInsurance(!value);

    updatePatient({
      variables: {
        declaredInsurance: !value
      },
      refetchQueries: [{ query: STATUS }]
    });
  };

  if (!patient || interstitialStatusLoading || !data || loading) return <Loader container />;

  const continueDisabled = !Boolean(status?.insuranceDataCompleted || declaredNoInsurance);
  const hasInsurance = data.patient.insuranceProviders.length > 0;
  const showInfoBox = (declaredNoInsurance || !hasInsurance) && !formOpened;
  const showToggle = !hasInsurance && !formOpened;

  return (
    <Container>
      {showToggle && (
        <Toggle
          key={'insurance-toggle'}
          label={"I don't have insurance"}
          name={'insurance-toggle'}
          onChange={value => onToggleChange(!!value)}
          isChecked={declaredNoInsurance}
        />
      )}

      {updatingPatient && <Loader container />}

      {!updatingPatient && (
        <>
          {!declaredNoInsurance && (
            <InsuranceList
              formStyle="embedded"
              insuranceProviders={data.patient.insuranceProviders}
              onAdd={() => {
                bookingAnalytics.track.insuranceDetailsCompletedWithData();
                refetch();
              }}
              onDelete={refetch}
              onCloseForm={() => setFormOpened(false)}
              onOpenForm={() => setFormOpened(true)}
            />
          )}

          <ActionsContainer>
            <ActionButton category="secondary" fullWidth onClick={history.goBack} label="Back" />
            <ActionButton
              category="primary"
              fullWidth
              onClick={() => {
                if (declaredNoInsurance) {
                  bookingAnalytics.track.insuranceDetailsCompletedWithoutData();
                }

                onNextStep?.();
              }}
              label="Continue"
              isDisabled={continueDisabled}
            />
          </ActionsContainer>
        </>
      )}

      {showInfoBox && <InfoBox message={INSURANCE_TAB_INFO_BOX_MESSAGE} />}
    </Container>
  );
};
