import React, { useRef } from 'react';

import { InputWithAutocomplete } from 'components/v2/Inputs/InputWithAutocomplete/InputWithAutocomplete';

import { FormControl, getFormControlIds } from '../FormControl';
import { Props } from './AutocompleteField.types';

export const AutocompleteField = ({
  status = 'default',
  label,
  id,
  required,
  helperText,
  description,
  placeholder,
  labelBg,
  hideHelperText,
  onSelect,
  onChange,
  options,
  minValueLengthToDisplayOptions,
  maxNumberOfDisplayedOptions
}: Props) => {
  const autocompleteRef = useRef<HTMLDivElement>(null);
  const ids = getFormControlIds(id);
  const labelId = ids.label;
  const inputId = `${ids.input}-autocomplete`;

  return (
    <FormControl
      status={status}
      label={label}
      required={required}
      helperText={helperText}
      hideHelperText={hideHelperText}
      description={description}
      labelId={labelId}
      inputId={ids.input}
      helperTextId={ids.helperText}
      labelBg={labelBg}
    >
      <InputWithAutocomplete
        placeholder={placeholder}
        id={inputId}
        onSelect={onSelect}
        onChange={onChange}
        options={options}
        minValueLengthToDisplayOptions={minValueLengthToDisplayOptions}
        maxNumberOfDisplayedOptions={maxNumberOfDisplayedOptions}
        ref={autocompleteRef}
        status={status}
      />
    </FormControl>
  );
};
