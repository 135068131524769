import React from 'react';

import info from 'assets/icons/info.svg';
import { Text } from 'components/v2/Typography';

import { Container, InfoBoxIcon, InfoBoxTextContainer } from './InfoBox.styled';

type Props = {
  message: string;
};

export const InfoBox = ({ message }: Props) => {
  return (
    <Container>
      <InfoBoxIcon src={info} alt={'Show information sign'} />
      <InfoBoxTextContainer>
        <Text size="sm" fontStyle="regular">
          {message}
        </Text>
      </InfoBoxTextContainer>
    </Container>
  );
};
