import React from 'react';

import { PartnerClinicDetails } from 'kb-shared';

import {
  Address,
  AppointmentLocation,
  AppointmentName,
  CardContainer,
  CardTitle,
  ClinicName,
  Container,
  FutureActionsWrapper,
  LocationWrapper,
  SecureMessageLink,
  Tag
} from './PartnerLocationConfirmationCard.styled';

interface Props {
  partnerClinicDetails: PartnerClinicDetails;
  appointmentName?: string | null;
}

export const PartnerLocationConfirmationCard = (props: Props) => {
  const { partnerClinicDetails, appointmentName } = props;

  return (
    <CardContainer>
      <Container>
        <div>
          <CardTitle>Appointment Request:</CardTitle>
          <AppointmentName>{appointmentName}</AppointmentName>

          <LocationWrapper>
            <AppointmentLocation>
              <ClinicName>{partnerClinicDetails.name}</ClinicName>
              <Address>
                {partnerClinicDetails.address}
                <br />
                {partnerClinicDetails.city}, {partnerClinicDetails.state}
              </Address>
            </AppointmentLocation>
          </LocationWrapper>
          <Tag>Partner Clinic</Tag>
        </div>
        <FutureActionsWrapper>
          Once your appointment is confirmed, you will receive a confirmation email with the details
          of your visit. In the mean time, if you have any questions or want to contact us,{' '}
          <b>you can keep us posted on your request through</b>{' '}
          <SecureMessageLink href="/messages">secure message.</SecureMessageLink>
        </FutureActionsWrapper>
      </Container>
    </CardContainer>
  );
};
