import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from 'components/v2/Buttons/Button';
import { LinkButton } from 'components/v2/Buttons/LinkButton/LinkButton';
import { useFeatureFlags } from 'hooks';
import { Appointment, AppointmentType, Patient, utils } from 'kb-shared';
import { CATEGORY_SLUG } from 'screens/Messages/Messages.constants';
import { ModalCancelReschedule } from 'screens/ModalCancelResched/ModalCancelResched';
import getAppointmentChannel from 'utilities/getAppointmentChannel';
import { pageUrl } from 'utilities/pageUrl';
import { VIOS_CHICAGO_LAB_ID } from 'utilities/vios';

import { generateCalendarLink } from '../../utilities/appointmentProduct';
import {
  AddToCalendarButton,
  CardContainer,
  FutureActionsWrapper,
  RescheduledText
} from './AppointmentCard.styled';
import { AppointmentDetails } from './AppointmentDetails';
import { LocationDetails } from './LocationDetails';
import { HiddenPatientIdTag, HiddenAppointmentTags } from './MarketingTags';
import { ProviderDetails } from './ProviderDetails';

const { inFuture, shouldDisplayProvider } = utils;

type Props = {
  type: AppointmentType;
  appointment: Appointment;
  timeZone?: string;
  patient?: Patient;
  isConfirmation: boolean;
  onReschedule?: VoidFunction;
  onAppointmentCancelled?: VoidFunction;
};

export const AppointmentCard = ({
  type,
  appointment,
  patient,
  isConfirmation,
  onReschedule,
  onAppointmentCancelled
}: Props) => {
  const history = useHistory();
  const isFuture = inFuture(appointment.endTime);
  const { ppPreventSelfAppointmentChicagoPatient } = useFeatureFlags();
  const { isPhoneCall, isVideoCall, isInPerson } = getAppointmentChannel(type);
  const apptVideoLink = appointment.videoLink ?? type.videoLink;
  const [showModal, setShowModal] = useState<boolean>(false);
  const messagesUrl = pageUrl.messages({
    categoryName: CATEGORY_SLUG.ADMIN
  });
  const isChicagoPatient = patient?.lab?.id === VIOS_CHICAGO_LAB_ID.toString();

  const toggleCancelModal = () => {
    setShowModal(!showModal);
  };

  const goToVideoLink = () => {
    if (!apptVideoLink) return;
    window.location.href = apptVideoLink;
  };

  const addToCalendar = () =>
    window.open(
      generateCalendarLink(
        {
          appointmentType: type,
          startTime: appointment.startTime,
          endTime: appointment.endTime,
          location: appointment.location
        },
        apptVideoLink
      )
    );

  return (
    <CardContainer $isFuture={isFuture}>
      <HiddenPatientIdTag patient={patient} />

      {isConfirmation && <HiddenAppointmentTags appointment={appointment} appointmentType={type} />}

      <AppointmentDetails appointment={appointment} appointmentType={type} isFuture={isFuture} />

      {shouldDisplayProvider(appointment) && appointment.providerName && (
        <ProviderDetails providerName={appointment.providerName} />
      )}

      <LocationDetails
        appointment={appointment}
        isFuture={isFuture}
        isPhoneCall={isPhoneCall}
        isVideoCall={isVideoCall}
        isInPerson={isInPerson}
        goToVideoLink={goToVideoLink}
      />

      {isFuture && (
        <FutureActionsWrapper>
          <Button
            leftIcon={<AddToCalendarButton />}
            label="Add to Calendar"
            category="secondary"
            size="lg"
            fullWidth
            onClick={addToCalendar}
          />
          {ppPreventSelfAppointmentChicagoPatient && isChicagoPatient ? (
            <RescheduledText fontStyle="regularItalic">
              To reschedule or cancel, or if you have any questions, please send us a{' '}
              <LinkButton
                text="message"
                fontStyle="regularItalic"
                onClick={() => history.push(messagesUrl)}
              />{' '}
              through the portal or call us at 773-435-9036.
            </RescheduledText>
          ) : (
            <LinkButton
              text="Cancel or Reschedule"
              onClick={toggleCancelModal}
              size="lg"
              underline="none"
            />
          )}
        </FutureActionsWrapper>
      )}

      <ModalCancelReschedule
        visible={showModal}
        onClose={toggleCancelModal}
        onReturnToDashboard={toggleCancelModal}
        appointment={appointment}
        onAppointmentCancelled={onAppointmentCancelled}
        onReschedule={onReschedule}
      />
    </CardContainer>
  );
};
