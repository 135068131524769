import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { usePatient } from 'hooks';
import { isAppointmentType } from 'kb-shared';
import { LoadedEntities } from 'kb-shared/booking/types/types';
import { Appointment } from 'kb-shared/types';
import { Clinic, TimeSlot } from 'kb-shared/types';
import { AppointmentDetailBlock } from 'screens/Book/components/AppointmentDetailBlock';
import { RoutesPath } from 'utilities/pageUrl';
import { hasEmployer } from 'utilities/patient';

import { CoachingBenefitsDisclaimer } from './components/CoachingBenefitsDisclaimer';
import { CreateAppointmentForm } from './components/CreateAppointmentForm';
import { LicensedProviderDisclaimer } from './components/LicensedProviderDisclaimer';
import { PaymentDetails } from './components/PaymentDetails';
import { PaymentInfo } from './components/PaymentInfo';
import { Container, PaymentFormWrapper, Headline, SupportText } from './Confirm.styled';

export const ConfirmStep = ({
  selectedClinic,
  selectedProduct,
  selectedTimeSlot,
  onBookAppointment,
  providerId
}: {
  selectedClinic?: Clinic;
  selectedProduct?: LoadedEntities['selectedProduct'];
  selectedTimeSlot?: TimeSlot;
  onBookAppointment: (appointment: Appointment) => void;
  providerId?: number;
}) => {
  const history = useHistory();
  const { patient } = usePatient();

  // Redirect to the membership activation screen if membership parameters are present in the URL
  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    if (params.get('membership_id') || params.get('enterprise_membership')) {
      history.replace(RoutesPath.ENTER_MEMBERSHIP_ACCESS_CODE);
    }
  }, [history]);

  // wait until the product is loaded
  if (!selectedProduct) return null;

  const hasMembershipEmployer = hasEmployer(patient);
  const timeSlot = selectedTimeSlot ?? null;
  const clinic = selectedClinic ?? null;

  return (
    <Container>
      <Headline>
        Pause, Breathe -<br /> Your Journey Begins Now
      </Headline>
      <div>
        {isAppointmentType(selectedProduct.data) && (
          <>
            <AppointmentDetailBlock
              appointmentType={selectedProduct.data}
              timeSlot={timeSlot}
              clinic={clinic}
            />

            <SupportText>
              We are here to support you at every step. Your upcoming session is a key step toward
              understanding your options and determining the best path forward.
            </SupportText>
          </>
        )}
      </div>
      <LicensedProviderDisclaimer product={selectedProduct} />
      <CoachingBenefitsDisclaimer product={selectedProduct} hasEmployer={hasMembershipEmployer} />
      <div>
        <PaymentInfo hasEmployerMembership appointment={selectedProduct.data} />
        <PaymentDetails product={selectedProduct} />
        <PaymentFormWrapper>
          <CreateAppointmentForm
            providerId={providerId}
            product={selectedProduct}
            timeSlot={timeSlot}
            onBookAppointment={onBookAppointment}
          />
        </PaymentFormWrapper>
      </div>
    </Container>
  );
};
