import React, { useEffect } from 'react';

import { Loader } from 'components/Loader/Loader';
import { Button } from 'components/v2/Buttons/Button';
import usePatient from 'hooks/usePatient';
import { utils } from 'kb-shared';
import { filterAppointmentsByStartTime } from 'screens/Appointments/Appointments.utils';
import { ResponsiveContainer } from 'screens/styled_common';
import { analytics } from 'utilities/analytics';
import { hasMembershipDocument } from 'utilities/patient';
import { useBreakpoints } from 'utilities/useBreakpoints';
import { getUserDisplayName } from 'utilities/userUtil';

import { AppointmentsWidget } from './AppointmentsWidget/AppointmentsWidget';
import { CustomWidgets } from './CustomWidgets/CustomWidgets';
import { CycleVisit } from './CycleVisit/CycleVisit';
import { useMyJourney, usePatientAppointments } from './Dashboard.hooks';
import { ViewMemberBenefitButtonContainer, WidgetsContainer } from './Dashboard.styled';
import { useHiddenDashboardWidgets } from './DashboardSettings/DashboardSettings.hooks';
import { EmbryoIncWidget } from './EmployerWidgets/EmbryoIncWidget/EmbryoIncWidget';
import { SaicWidget } from './EmployerWidgets/SaicWidget/SaicWidget';
import { FertilityCalculator } from './FertilityCalculator/FertilityCalculator';
import { Header } from './Header/Header';
import { JourneyWidget } from './JourneyWidget/JourneyWidget';
import { MedicationsWidget } from './MedicationsWidget/MedicationsWidget';
import { ActivateBenefitsWidget } from './MyBenefitsWidgets/ActivateBenefitsWidget/ActivateBenefitsWidget';
import { BenefitsWidget } from './MyBenefitsWidgets/BenefitsWidget/BenefitsWidget';
import { PeriodTracker } from './PeriodTracker/PeriodTracker';
import { PregnancyCalculatorWidget } from './PregnancyCalculatorWidget/PregnancyCalculatorWidget';
import { ProfileWidget } from './ProfileWidget/ProfileWidget';
import { Results } from './Results/Results';
import { SupportGroupWidget } from './SupportGroupWidget/SupportGroupWidget';
import { ToDoWidget } from './ToDoWidget/ToDoWidget';
import { UnpaidInvoices } from './UnpaidInvoices/UnpaidInvoices';
import { UnreadMessages } from './UnreadMessages/UnreadMessages';

const { isAMH } = utils;

export function Dashboard() {
  const { patient, loading: loadingPatient } = usePatient();
  const { data: hiddenWidgets, loading: loadingHiddenWidgets } = useHiddenDashboardWidgets();
  const {
    data: appointmentsData,
    loading: appointmentsLoading,
    refetch: refetchAppointments
  } = usePatientAppointments();
  const { data: journeyData, loading: journeyLoading } = useMyJourney();
  const { isDesktop, isMobile } = useBreakpoints();

  useEffect(() => {
    if (document.body && document.documentElement) {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    analytics.page(analytics.PAGES.HOME);
  }, []);

  const loading =
    patient == null ||
    loadingPatient ||
    journeyLoading ||
    appointmentsLoading ||
    loadingHiddenWidgets;

  if (loading) return <Loader absoluteCentered />;

  const hasScheduledAMH = appointmentsData?.appointments.some(a => isAMH(a)) || false;
  const upcomingAppointments = filterAppointmentsByStartTime(appointmentsData);

  return (
    <ResponsiveContainer>
      {isDesktop && (
        <Header
          name={getUserDisplayName(patient)}
          hasMembershipDocument={hasMembershipDocument(patient)}
          documentUrl={patient?.patientMembership?.membership?.documentUrl}
        />
      )}

      {hasMembershipDocument(patient) && isMobile && (
        <ViewMemberBenefitButtonContainer>
          <Button
            category="secondary"
            label="View Member Benefit"
            onClick={() =>
              window.open(
                patient?.patientMembership?.membership?.documentUrl,
                '_blank',
                'noreferrer noopener'
              )
            }
            size="sm"
            fullWidth
          />
        </ViewMemberBenefitButtonContainer>
      )}

      <UnpaidInvoices />

      <UnreadMessages />

      <MedicationsWidget patient={patient} />

      <CycleVisit />

      <ToDoWidget patient={patient} />

      <WidgetsContainer>
        <SaicWidget patient={patient} hiddenWidgets={hiddenWidgets} />
        <EmbryoIncWidget patient={patient} hiddenWidgets={hiddenWidgets} />
        <AppointmentsWidget
          upcomingAppointments={upcomingAppointments}
          refetch={refetchAppointments}
          hiddenWidgets={hiddenWidgets}
        />
        <BenefitsWidget
          benefitsDescription={patient.patientMembership?.membership?.description}
          patient={patient}
          hiddenWidgets={hiddenWidgets}
        />
        <JourneyWidget journeyData={journeyData} hiddenWidgets={hiddenWidgets} />
        <Results hasScheduledAMH={hasScheduledAMH} hiddenWidgets={hiddenWidgets} />
        <FertilityCalculator patient={patient} hiddenWidgets={hiddenWidgets} />
        <PeriodTracker patient={patient} hiddenWidgets={hiddenWidgets} />
        <PregnancyCalculatorWidget patient={patient} hiddenWidgets={hiddenWidgets} />
        <ProfileWidget patient={patient} hiddenWidgets={hiddenWidgets} />
        <SupportGroupWidget hiddenWidgets={hiddenWidgets} />
        <ActivateBenefitsWidget patient={patient} hiddenWidgets={hiddenWidgets} />
        <CustomWidgets hiddenWidgets={hiddenWidgets} />
      </WidgetsContainer>
    </ResponsiveContainer>
  );
}
