import { gql } from '@apollo/client';

export const STATUS = gql`
  query interstitialStatus {
    interstitialStatus {
      active
      hasRequiredConsents
      hasPartnersPatientDeclared
      partnerDataCollected
      declaredNoInsurance
      insuranceDataCompleted
      skipPartnerDataCapture
      requiredConsents {
        id
        description
        name
        completed
        linkUrl
        versionUuid
      }
    }
  }
`;
