import React from 'react';
import Media from 'react-media';

import MobileBanner from 'components/MobileBanner';
import { themes } from 'kb-shared';
import { RoutesPath } from 'utilities/pageUrl';

import { EmployerBenefitText, Link } from './EmployerBenefitCTA.styled';
import { BenefitMessageProps, EmployerBenefitCTAProps } from './EmployerBenefitCTA.types';

const signUpUrl = () =>
  `/create-account?step=create_account&target=${RoutesPath.ENTER_MEMBERSHIP_ACCESS_CODE}`;

const BenefitMessage: React.FC<BenefitMessageProps> = ({ onClick }) => (
  <>
    Does your employer have the Kindbody benefit?{' '}
    <Link href={signUpUrl()} onClick={onClick}>
      Sign Up to Activate
    </Link>
  </>
);

export const EmployerBenefitCTA: React.FC<EmployerBenefitCTAProps> = ({ onClick }) => {
  return (
    <Media query={{ minWidth: themes.breakpoints.raw.tabletLandscape }}>
      {desktop => {
        if (desktop) {
          return (
            <EmployerBenefitText>
              <BenefitMessage onClick={onClick} />
            </EmployerBenefitText>
          );
        }
        return (
          <MobileBanner>
            <BenefitMessage onClick={onClick} />
          </MobileBanner>
        );
      }}
    </Media>
  );
};
