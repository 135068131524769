import React from 'react';

import { Text } from 'components/v2/Typography/Text';
import Invoice from 'screens/Invoices/types/invoice';

import { InvoiceCardList } from '../InvoiceCardList/InvoiceCardList';
import { NoInvoicesContainer } from './InvoicesDisplayed.styled';

export const InvoicesDisplayed = ({
  invoices,
  onInvoiceSelected,
  onViewInvoice
}: {
  invoices: Invoice[];
  displayAsTable: boolean;
  onInvoiceSelected: (invoice: Invoice) => void;
  onViewInvoice: (invoice: Invoice) => void;
}) => {
  if (invoices.length === 0)
    return (
      <NoInvoicesContainer>
        <Text tag="span" fontStyle="medium">
          There are currently no invoices on your account. <br />
          We will send you an email if there is any financial activity or please check back at a
          later time.
        </Text>
      </NoInvoicesContainer>
    );
  return (
    <InvoiceCardList
      invoices={invoices}
      onPayInvoice={invoice => onInvoiceSelected(invoice)}
      onViewInvoice={invoice => onViewInvoice(invoice)}
    />
  );
};
