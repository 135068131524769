import React from 'react';

import { Heading, Text } from 'components/v2/Typography';

import { EmbryosCryoedDetail } from '../../../EggRetrieval.types';
import {
  BotttomRowContainer,
  DayFrozen,
  DayFrozenTitle,
  EmbryosCryoedDetailsCardContainer,
  Grade,
  GradeTitle,
  SampleTitle,
  TopRowContainer
} from './EmbryosCryoedDetailsCard.styled';

interface Props {
  embryoDetail: EmbryosCryoedDetail;
}

export const EmbryosCryoedDetailsCard = ({ embryoDetail }: Props) => {
  return (
    <EmbryosCryoedDetailsCardContainer>
      <TopRowContainer>
        <SampleTitle>Sample</SampleTitle>
        <Heading tag="span" styledAs="h4">
          {embryoDetail.sample}
        </Heading>
      </TopRowContainer>
      <BotttomRowContainer>
        <DayFrozen>
          <DayFrozenTitle>Day Frozen</DayFrozenTitle>
          <Text size="md" fontStyle="semibold">
            {embryoDetail.dayFrozen}
          </Text>
        </DayFrozen>
        <Grade>
          <GradeTitle>Grade</GradeTitle>
          <Text size="md" fontStyle="semibold">
            {embryoDetail.grade}
          </Text>
        </Grade>
      </BotttomRowContainer>
    </EmbryosCryoedDetailsCardContainer>
  );
};
