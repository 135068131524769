import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router';

import { ArrowLeft, ChevronLeft } from 'assets/icons/Icons';
import { Loader } from 'components/Loader/Loader';
import { Heading } from 'components/v2/Typography';
import { InvoicePayModal } from 'screens/Invoices/components/InvoicePayModal/InvoicePayModal';

import useInvoiceDetails from './hooks/use-invoice-details';
import {
  Container,
  Header,
  StyledPayeeInfo,
  StyledPayerInfo,
  StyledDatesInfo,
  StyledLineItemsTable,
  MobileBackButton,
  DesktopHeaderButton,
  MobilePayNowButton
} from './InvoiceDetails.styled';

interface URLParams {
  id: string;
}

export const InvoiceDetails = () => {
  const history = useHistory();
  const { id } = useParams<URLParams>();
  const isV2Invoice = id.includes('v2');
  const { details, loading, refetch } = useInvoiceDetails(id);
  const [invoicePayModalOpened, setInvoicePayModalOpened] = useState<boolean>(false);

  const handlePay = () => {
    setInvoicePayModalOpened(false);
    refetch?.();
  };

  if (loading || !details) return <Loader absoluteCentered />;

  const isPayNowButtonVisible = details.balanceDueAmount > 0 && !details?.invoice?.paidViaPpApp;

  return (
    <Container>
      <Header>
        <DesktopHeaderButton
          category="secondary"
          label="Back"
          onClick={() => history.goBack()}
          size="md"
          fullWidth={false}
          leftIcon={<ArrowLeft type="solid" />}
        />
        <MobileBackButton onClick={() => history.goBack()}>
          <ChevronLeft type="solid" />
        </MobileBackButton>
        <Heading noMargin tag="h1">
          Invoice #{details.invoiceNumber}
        </Heading>
        {isPayNowButtonVisible && (
          <DesktopHeaderButton
            category="error"
            label="Pay Now"
            onClick={() => setInvoicePayModalOpened(true)}
            size="lg"
            fullWidth={false}
          />
        )}
      </Header>

      <StyledPayeeInfo
        name={details.organizationName}
        address={details.organizationAddress}
        taxId={details.organizationTaxId}
        clinicLocation={details.clinicLocation}
      />

      <StyledPayerInfo
        name={details.billTo.patientName}
        addressLine1={details.billTo.addressLine1}
        addressLine2={details.billTo.addressLine2}
        city={details.billTo.city}
        state={details.billTo.state}
        zipCode={details.billTo.zipCode}
      />

      <StyledDatesInfo
        invoiceDate={details.invoiceDate}
        terms={details.terms}
        datesOfService={details.datesOfService}
      />

      <StyledLineItemsTable
        isAzInvoice={details.isAzInvoice}
        isV2Invoice={isV2Invoice}
        lineItems={details.lineItems}
        lineItemsTotalAmount={details.lineItemsTotalAmount}
        invoiceAdjustmentsAmount={details.invoiceAdjustmentsAmount}
        balanceDueAmount={details.balanceDueAmount}
        kindbodyRateAmount={details.kindbodyRateAmount}
        convertedCreditsAmount={details.convertedCreditsAmount}
        insuranceAdjustmentsAmount={details.insuranceAdjustmentsAmount}
        payments={details.payments}
        discount={details.discount}
        appliedCredits={details.appliedCredits}
      />

      {isPayNowButtonVisible && (
        <MobilePayNowButton
          category="error"
          label="Pay Now"
          onClick={() => setInvoicePayModalOpened(true)}
          size="lg"
          fullWidth
        />
      )}

      {invoicePayModalOpened && (
        <InvoicePayModal
          invoice={details.invoice}
          open={true}
          onClose={() => setInvoicePayModalOpened(false)}
          onPaid={handlePay}
        />
      )}
    </Container>
  );
};
