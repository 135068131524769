import styled from 'styled-components';

export const PaymentFormContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: 24px;
  gap: 24px;
  width: 100%;
`;
