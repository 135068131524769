/* eslint-disable @typescript-eslint/indent */
import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';

import Button from 'components/Buttons/NewButton';
import { Loader } from 'components/Loader/Loader';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { showErrorToast } from 'utilities/notificationUtils';

import { ComprehensionQuestion } from '../../InformedConsent.types';
import ComprehensionQuestionBox from '../ComprehensionQuestionBox';
import { CREATE_COMPREHENSION_DECISION } from './Comprehension.graphql';
import {
  Container,
  AllSetContainer,
  OptionContainer,
  QuestionsContainer,
  SubtitleText,
  TitleText
} from './Comprehension.styled';
import { CreateComprehensionDecisionResponseData, Props } from './Comprehension.types';

const Comprehension = ({ questions, patientConsentId, onCompleted }: Props) => {
  const generateResponseMap = (questions: ComprehensionQuestion[]) => {
    return questions.reduce((map, question: ComprehensionQuestion) => {
      // @ts-ignore
      map[question.id] = null;
      return map;
    }, {} as { [key: number]: string | null });
  };

  const [responses, setResponses] = useState<{ [key: number]: string | null }>(
    generateResponseMap(questions)
  );
  const [displayAnswers, setDisplayAnswers] = useState(false);
  const [submitDecision] = useMutation<
    CreateComprehensionDecisionResponseData,
    {
      comprehensionQuestionId: number;
      patientConsentId: number;
      patientResponse: string;
    }
  >(CREATE_COMPREHENSION_DECISION, {
    onError: error => {
      showErrorToast('An error occurred: ' + error.message);
      BugTracker.notify(error, 'Failed to save consent comprehension answer');
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (questions.length === 0) submit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions]);

  useEffect(() => {
    setResponses(generateResponseMap(questions));
  }, [questions]);

  const submit = () => {
    onCompleted(displayAnswers);
    setDisplayAnswers(true);
    window.scrollTo(0, 0);
  };

  const hasAnsweredAllQuestions = () => {
    let hasAnswered = true;
    Object.keys(responses).forEach(questionKey => {
      if (responses[parseInt(questionKey)] === null) {
        hasAnswered = false;
        return;
      }
    });
    return hasAnswered;
  };

  const renderSubmit = () => {
    const enabled = hasAnsweredAllQuestions();
    const buttonText = !displayAnswers ? 'Show comprehension results' : 'Submit';

    return (
      <AllSetContainer>
        <Button text={buttonText} onClick={submit} disabled={!enabled} />
      </AllSetContainer>
    );
  };

  const renderQuestions = () => {
    return (
      <QuestionsContainer>
        {questions.map((question: ComprehensionQuestion) => (
          <OptionContainer key={question.id}>
            <ComprehensionQuestionBox
              question={question}
              showCorrectAnswer={displayAnswers}
              onSelect={(value: string) => {
                submitDecision({
                  variables: {
                    comprehensionQuestionId: parseInt(question.id),
                    patientConsentId: parseInt(patientConsentId),
                    patientResponse: value
                  }
                })
                  .then(() =>
                    setResponses(prevResponses => ({
                      ...prevResponses,
                      [parseInt(question.id)]: value
                    }))
                  )
                  .catch(error => {
                    showErrorToast('An error occurred: ' + error.message);
                    BugTracker.notify(error, 'Failed to save consent comprehension answer');
                  });
              }}
            />
          </OptionContainer>
        ))}
      </QuestionsContainer>
    );
  };

  const hasQuestions = questions.length !== 0;

  if (!hasQuestions) return <Loader container />;

  const subtitle = `Please answer the following ${questions.length} questions so we may verify that you've understood what you are consenting to, and we can move forward with any procedures`;

  return (
    <Container>
      <TitleText>Verifying your comprehension:</TitleText>
      {!displayAnswers && <SubtitleText>{subtitle}</SubtitleText>}
      {renderQuestions()}
      {renderSubmit()}
    </Container>
  );
};

export default Comprehension;
