import React from 'react';

import { EmployerBenefits } from 'assets/icons/Icons';
import { Heading, Text } from 'components/v2/Typography';
import { Patient, theme, themes } from 'kb-shared';
import { DashboardWidgetList } from 'screens/Dashboard/DashboardSettings/DashboardSettings.types';
import { isHiddenWidget } from 'screens/Dashboard/DashboardSettings/DashboardSettings.utils';
import {
  IconContainer,
  IllustratedBackgroundWidget,
  WidgetTitleContainer
} from 'screens/Dashboard/Widgets/Widgets.styled';
import { showBenefitsWidget } from 'screens/Dashboard/Widgets/Widgets.utils';

interface Props {
  benefitsDescription?: string;
  patient: Patient;
  hiddenWidgets?: DashboardWidgetList;
  hideSettings?: boolean;
}

export const BenefitsWidget = ({
  benefitsDescription,
  patient,
  hiddenWidgets,
  hideSettings
}: Props) => {
  if (!showBenefitsWidget(patient) || isHiddenWidget(hiddenWidgets, 'employerBenefits'))
    return null;

  return (
    <IllustratedBackgroundWidget
      variant={5}
      bgColor={theme.colors.neutral.primaryNavy}
      hideSettings={hideSettings}
    >
      <div>
        <IconContainer $iconWidth={36} $iconHeight={48} $color={themes.colors.semantic.pink}>
          <EmployerBenefits type="solid" />
        </IconContainer>
        <WidgetTitleContainer>
          <Heading tag="div" styledAs="h2" color={themes.colors.semantic.pink}>
            Your Coverage Includes
          </Heading>
        </WidgetTitleContainer>
        {benefitsDescription ? (
          <Text size="md" fontStyle="regular" color={themes.colors.neutral.lavenderWhite}>
            {benefitsDescription}
          </Text>
        ) : (
          <Text size="md" fontStyle="regular" color={themes.colors.neutral.lavenderWhite}>
            At the moment, we do not have information about the specifics of your coverage.
          </Text>
        )}
      </div>
    </IllustratedBackgroundWidget>
  );
};
