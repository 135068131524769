import { Heading, Text } from 'components/v2/Typography';
import { themes } from 'kb-shared';
import styled from 'styled-components';

const { colors } = themes;

export const Price = styled(Heading).attrs({ tag: 'h2', styledAs: 'h2', noMargin: true })`
  color: ${colors.neutral.primaryNavy};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

export const Header = styled(Heading).attrs({ tag: 'div', styledAs: 'h2', noMargin: true })`
  color: ${colors.semantic.darkRed};
`;

export const SmallPrint = styled(Text).attrs({ tag: 'p' })`
  margin-top: 8px;
  color: ${colors.neutral.primaryNavy};
`;
