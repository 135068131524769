import React from 'react';

import plus from 'assets/icons/plus.svg';

import { ButtonBackgroundAndBorder, PlusIcon, ButtonText } from './AddPartnerButton.styled';

export const AddPartnerButton = ({ onClick }: { onClick: () => void }) => (
  <ButtonBackgroundAndBorder onClick={onClick}>
    <PlusIcon src={plus} alt={'A plus sign to represent adding a partner'} />
    <ButtonText>Add Partner</ButtonText>
  </ButtonBackgroundAndBorder>
);
