import { Text } from 'components/v2/Typography';
import styled from 'styled-components';

export const AppointmentLocation = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Address = styled(Text).attrs({ size: 'md', tag: 'span' })``;

export const ClinicName = styled(Text).attrs({ size: 'md', tag: 'span', fontStyle: 'bold' })``;

export const ConsultInstructions = styled(Text).attrs({ size: 'md', tag: 'p' })`
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  text-align: center;
  margin-bottom: 8px;
`;

export const LocationWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const VideoConsult = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 16px 24px 24px 24px;
  background: ${({ theme }) => theme.colors.yellow.lighterYellow};
  border-radius: 8px;
`;

export const PhoneConsult = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 16px 24px 24px 24px;
  background: ${({ theme }) => theme.colors.yellow.lighterYellow};
  border-radius: 8px;
`;
