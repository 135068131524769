import React from 'react';

import { Heading, Text } from 'components/v2/Typography';
import { PaymentPlan } from 'screens/Invoices/types/invoice';
import { currencyNumberFormat } from 'utilities/number-format';

import { InvoicesItemContainer, InvoicesTitleContainer } from '../../Invoices.styled';
import { PaymentPlanPayments } from './PaymentPlanPayments/PaymentPlanPayments';
import {
  CardList,
  CardListItem,
  CardContent,
  CardContentRow,
  LabelsColumn,
  CardContentRowContainer,
  CardContentRowItem,
  LabelHolder,
  CardContentRowItemValue
} from './PaymentPlans.styled';
import { PaymentPlanRowItem } from './PaymentPlans.types';

export function PaymentPlans({ paymentPlans }: { paymentPlans: PaymentPlan[] }) {
  return (
    <InvoicesItemContainer>
      <InvoicesTitleContainer>
        <Heading tag="h2">Payment Plans</Heading>
      </InvoicesTitleContainer>
      <CardList>
        {paymentPlans.map((paymentPlan, index) => {
          const rowItems: Array<PaymentPlanRowItem> = [
            { label: 'appointment id', value: paymentPlan.appointmentId || '' },
            { label: 'start date', value: paymentPlan.startDate },
            {
              label: 'no. of installments',
              value: paymentPlan.totalAutomatedPaymentsCount,
              additionalValue: paymentPlan.billingFrequency
            },
            { label: 'total amount due', value: currencyNumberFormat(paymentPlan.totalAmount) },
            { label: 'collected', value: currencyNumberFormat(paymentPlan.collectedAmount) }
          ];

          return (
            <CardListItem key={`playment-plan-${index}`}>
              <CardContent>
                <CardContentRow>
                  <div>
                    <div>
                      <Text tag="span" fontStyle="medium" size="xs" transform="uppercase">
                        Payment Plan
                      </Text>
                    </div>
                    <Heading tag="h3" noMargin>
                      {paymentPlan.description || '-'}
                    </Heading>
                  </div>
                  <LabelsColumn>
                    <Text tag="span" fontStyle="medium" size="sm">
                      <LabelHolder $status={paymentPlan.status}>
                        {isPaymentPlanInProgress(paymentPlan.status)
                          ? 'Payment Plan in Progress'
                          : 'Completed'}
                      </LabelHolder>
                    </Text>
                  </LabelsColumn>
                </CardContentRow>

                <CardContentRowContainer>
                  {rowItems.map((item, index) => {
                    return (
                      <CardContentRowItem key={index}>
                        <div>
                          <Text tag="span" fontStyle="medium" size="xs" transform="uppercase">
                            {item.label}
                          </Text>
                        </div>
                        <CardContentRowItemValue>
                          <div>
                            <Text tag="span" fontStyle="bold" size="lg">
                              {item.value}
                            </Text>
                          </div>
                          {item.additionalValue && (
                            <div>
                              <Text tag="span" fontStyle="regular" size="xs">
                                ({item.additionalValue})
                              </Text>
                            </div>
                          )}
                        </CardContentRowItemValue>
                      </CardContentRowItem>
                    );
                  })}
                </CardContentRowContainer>
                {showUpcomingPayments(paymentPlan) && paymentPlan.payments && (
                  <PaymentPlanPayments
                    payments={paymentPlan.payments.filter(payment => !payment.processed)}
                  />
                )}
              </CardContent>
            </CardListItem>
          );
        })}
      </CardList>
    </InvoicesItemContainer>
  );
}

const isPaymentPlanInProgress = (paymentPlanStatus: string) => paymentPlanStatus === 'In Progress';
const showUpcomingPayments = (paymentPlan: PaymentPlan) =>
  isPaymentPlanInProgress(paymentPlan.status) && paymentPlan.payments?.length;
