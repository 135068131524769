import styled from 'styled-components';

export const Button = styled.button`
  color: ${({ theme }) => theme.colors.blue.darkBlue};
  text-decoration: underline;
`;

export const Fallback = styled.div`
  text-align: center;
`;
