import { YellowButton } from 'components/Buttons/Buttons';
import styled from 'styled-components';

export const Section = styled.section`
  @media ${({ theme }) => theme.queries.mobile} {
    padding: 0;
    border-left: none;
    border-right: none;
  }

  @media ${({ theme }) => theme.queries.minDesktop} {
    height: 100%;
  }

  h1 {
    margin-bottom: 0px;
  }
`;

export const ResultsSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  @media ${({ theme }) => theme.queries.mobile} {
    justify-content: space-between;
  }
  > div {
    width: 180px;
    margin-bottom: 49px;
    border-right: 1px solid ${({ theme }) => theme.colors.neutral.lavender};
    margin-right: 24px;
    @media ${({ theme }) => theme.queries.mobile} {
      width: 100px;
      border-right: none;
    }
    > div {
      max-width: 100px;
    }
  }
`;

export const Button = styled(YellowButton)`
  display: block;
  margin: 30px auto;
  width: max-content;
`;
