import { ApolloProvider } from '@apollo/client';
import { ApolloProvider as ApolloProviderHooks } from '@apollo/client';
import Honeybadger from '@honeybadger-io/js';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import store from 'store';

import 'normalize.css';
import { FeatureFlagsProvider } from 'hooks/useFeatureFlags/FeatureFlagsProvider';
import { configure, graphql, utils } from 'kb-shared';
import { theme } from 'kb-shared';
import { filterHoneybadgerNotices } from 'kb-shared/utilities/bugTracker.helper';
import { ErrorCatcher } from 'layouts/errorCatcher/ErrorCatcher';
import { ThemeProvider } from 'styled-components';

import App from './App';
import * as redux from './kb-redux';
import reduxStore from './kb-redux/store';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';

import 'react-toastify/dist/ReactToastify.css';
import './index.scss';
import './theme/typography.scss';
import 'react-image-crop/dist/ReactCrop.css';
import './cropStyles.scss';

// import './config/newrelic';

const { setClearPwLocalStorage } = utils;

const appLoaded = redux.patient.appLoaded;

// Turn on hot-reloading
// @ts-ignore
// module.hot && module.hot.accept();

// Configure kb-shared
const honeyBadgerConfig = {
  apiKey: process.env.REACT_APP_HONEYBADGER_KEY,
  environment: process.env.REACT_APP_HONEYBADGER_ENV,
  revision: process.env.REACT_APP_HONEYBADGER_REVISION
};

Honeybadger.configure(honeyBadgerConfig);
Honeybadger.beforeNotify(filterHoneybadgerNotices);
configure({ bugTracker: Honeybadger });

// Configure ApolloClient
const client = graphql.client;

// Platform specific config for web
setClearPwLocalStorage(() => store.remove('kindbody-auth-password'));

// Initial load of user into Redux
// @ts-ignore
reduxStore.dispatch(appLoaded());

ReactDOM.render(
  <ReduxProvider store={reduxStore}>
    <ApolloProvider client={client}>
      <ApolloProviderHooks client={client}>
        <FeatureFlagsProvider>
          <ThemeProvider theme={theme}>
            <ErrorCatcher id="app">
              <App />
            </ErrorCatcher>
          </ThemeProvider>
        </FeatureFlagsProvider>
      </ApolloProviderHooks>
    </ApolloProvider>
  </ReduxProvider>,
  document.getElementById('root')
);

// Turn off Service Worker
unregisterServiceWorker();
//registerServiceWorker();
