import styled from 'styled-components';

export const PaymentPlanPaymentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const PaymentPlanPaymentsTableContainer = styled.div`
  margin-top: 1.5em;
`;

export const PaymentPlanPaymentsToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 1em;
`;

export const PaymentPlanPaymentsToggle = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const PaymentPlanPaymentsToggleLabel = styled.span`
  margin-right: 0.5em;
`;
