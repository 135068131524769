import styled from 'styled-components';

export const WidgetBaseContainer = styled.div<{ $variant?: number; $bgColor?: string }>`
  position: relative;
  padding: 16px 16px 24px 16px;
  height: 100%;
  background-color: ${({ $bgColor, theme }) => $bgColor ?? theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.card.level0};

  @media ${({ theme }) => theme.queries.minTablet} {
    border-radius: 16px;
    padding: 16px 24px 24px 24px;
  }

  @media ${({ theme }) => theme.queries.minDesktop} {
    padding: 24px 32px 32px 32px;
  }
`;
