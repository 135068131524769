import { useMediaQuery } from 'react-responsive';

import queries from 'kb-shared/theme/queries';

export function useBreakpoints() {
  const isDesktop = useMediaQuery({
    query: queries.minDesktop
  });

  const isLargeDesktop = useMediaQuery({
    query: queries.minLargeDesktop
  });

  const isMobile = useMediaQuery({
    query: queries.mobile
  });

  const isTablet = useMediaQuery({
    query: queries.tablet
  });

  const isLandscape = useMediaQuery({
    query: '(orientation: landscape)'
  });

  const isMinTablet = useMediaQuery({ query: queries.minTablet });

  const isMaxTablet = useMediaQuery({ query: queries.maxTablet });

  return {
    isDesktop,
    isLargeDesktop,
    isMobile,
    isTablet,
    isLandscape,
    isMinTablet,
    isMaxTablet
  };
}
