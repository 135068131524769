import { useQuery } from '@apollo/client';

import { BugTracker } from 'kb-shared/utilities/bugTracker';

import { CUSTOM_DASHBOARD_TILES_QUERY } from './CustomWidgets.graphql';
import { CustomTileArray } from './CustomWidgets.types';

export const useCustomDashboardTiles = () => {
  const { data, loading } = useQuery<CustomTileArray>(CUSTOM_DASHBOARD_TILES_QUERY, {
    onError: error => BugTracker.notify(error, 'CustomDashboardTilesFetch')
  });

  return { data, loading };
};
