import React from 'react';

import Button from 'components/Buttons/NewButton';
import {
  Appointment,
  AppointmentProduct,
  AppointmentType,
  isAppointmentType,
  Membership
} from 'kb-shared';
import { isCoachingAppointment } from 'kb-shared/utilities/appointment.helper';

import { Container, Content } from './ConfirmDetails.styled';
import { TermsDetails } from './TermsDetails';

interface Props {
  appointment: Appointment | AppointmentType | Membership;
  product: AppointmentProduct<AppointmentType>;
  loading?: boolean;
  disabled?: boolean;
  paymentTermsChecked?: boolean;
  onPaymentTermsChecked: (checked: boolean) => void;
}

export const ConfirmDetails = (props: Props) => {
  const {
    product,
    loading = false,
    disabled = false,
    paymentTermsChecked = false,
    onPaymentTermsChecked
  } = props;

  if (!isAppointmentType(product.data)) return null;

  return (
    <Container>
      <Content>
        <TermsDetails
          selected={paymentTermsChecked}
          onCheckBoxSelected={() => onPaymentTermsChecked(!paymentTermsChecked)}
          showSessionDisclaimerCharge={
            isCoachingAppointment(product.data) &&
            !product.data?.shouldChargeAtBooking &&
            product.data.rateCents !== 0
          }
        />
        <Button
          loading={loading}
          disabled={disabled || !paymentTermsChecked || loading}
          type="submit"
          text="CONFIRM & BOOK"
        />
      </Content>
    </Container>
  );
};
