import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.blue.lightestBlue};
  border-radius: 8px;
  padding: 16px 40px 32px 32px;
  margin-top: 32px;
  box-shadow: 0px 0px 0px 6px rgba(125, 163, 239, 0.32), 0px 1px 3px 0px rgba(125, 163, 239, 0.24),
    0px 0px 0px 1px rgba(125, 163, 239, 0.32), 0px 1px 0.5px 0px rgba(125, 163, 239, 0.24);
`;

export const InfoBoxTextContainer = styled.div`
  padding-left: 40px;
`;

export const InfoBoxIcon = styled.img`
  position: absolute;
  left: 16px;
  top: 16px;
  width: 32px;
  height: 32px;
  margin: 8px;
`;
