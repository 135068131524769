import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { AppointmentCard } from 'components/AppointmentCard/AppointmentCard';
import { Loader } from 'components/Loader/Loader';
import { Button } from 'components/v2/Buttons/Button';
import { TODOS } from 'kb-shared/graphql/queries';
import { analytics } from 'utilities/analytics';
import { pageUrl } from 'utilities/pageUrl';

import { PartnerLocationConfirmationCard } from './PartnerLocationConfirmationCard';
import {
  Container,
  TodoContainer,
  TodoInfo,
  TodoHeader,
  TodoCardTitle,
  TodoAdditionalInfo,
  TodoItem
} from './PurchaseConfirmation.styled';
import { Props, TodoResult } from './PurchaseConfirmation.types';
import { hasIntakeTodo } from './PurchaseConfirmation.utils';

export const PurchaseConfirmation = ({
  purchase,
  partnerClinicDetails,
  appointmentName
}: Props) => {
  const history = useHistory();
  const { data, loading } = useQuery(TODOS) as TodoResult;
  const todos = data && data.patientTodos;
  const goToIntakeForm = () => {
    window.location.href = pageUrl.intakeForm();
  };

  useEffect(() => {
    analytics.page(analytics.PAGES.CONFIRMATION);
  }, []);

  if (partnerClinicDetails) {
    return (
      <Container id="google-tag-manager-confirmation">
        {partnerClinicDetails && (
          <PartnerLocationConfirmationCard
            partnerClinicDetails={partnerClinicDetails}
            appointmentName={appointmentName}
          />
        )}
      </Container>
    );
  }

  if (purchase?.type === 'appointment') {
    // @ts-ignore
    const appointment = purchase.data.id ? purchase.data : purchase.data.appointment;
    const showIntakeTodo = todos && hasIntakeTodo(todos) && appointment.intakeFormRequired;

    if (loading) {
      return (
        <Container>
          <Loader container />
        </Container>
      );
    }

    return (
      <Container id="google-tag-manager-confirmation">
        {!partnerClinicDetails && (
          <AppointmentCard
            isConfirmation
            appointment={appointment}
            type={appointment.appointmentType}
            onReschedule={() => window.location.reload()}
            onAppointmentCancelled={() => {
              history.push('/');
            }}
          />
        )}
        {!!showIntakeTodo && (
          <TodoContainer>
            <TodoHeader>Next steps:</TodoHeader>

            <TodoItem>
              <TodoInfo>
                <TodoCardTitle>Complete your intake form</TodoCardTitle>
                <TodoAdditionalInfo>
                  Your intake form should be completed 48 hours prior to your first visit.
                </TodoAdditionalInfo>
              </TodoInfo>
              <Button
                type="submit"
                label="Fill out intake form"
                onClick={goToIntakeForm}
                size="sm"
              />
            </TodoItem>
          </TodoContainer>
        )}
      </Container>
    );
  }

  return null;
};
