import React from 'react';

import { getTimeZone } from 'hooks/getTimeZone';
import { AppointmentType, Clinic, TimeSlot } from 'kb-shared';

import {
  AdditionalInfo,
  AppointmentBlockContainer,
  DateTimeLine,
  Title,
  Content,
  AdditionalInfoItem,
  AdditionalInfoItemTitle,
  AdditionalInfoItemValue
} from './AppointmentDetailBlock.styled';
import { formatDuration } from './AppointmentDetailBlock.utils';
import { AppointmentLocation } from './AppointmentLocation';

type AppointmentDetailProps = {
  appointmentType: AppointmentType;
  clinic: Clinic | null;
  timeSlot: TimeSlot | null;
};

function getTimeComponents(timeSlot: TimeSlot, appointmentType: AppointmentType) {
  const { formatTime } = getTimeZone(timeSlot.timeZone, appointmentType);

  const dayOfWeek = formatTime(timeSlot.startTime, 'dddd');
  const date = formatTime(timeSlot.startTime, 'MMMM D, YYYY');
  const time = formatTime(timeSlot.startTime, 'h:mm a z');

  return { dayOfWeek, date, time };
}

export const AppointmentDetailBlock = (props: AppointmentDetailProps) => {
  const { appointmentType, timeSlot, clinic } = props;

  if (!timeSlot) return null;

  const showLicensedProviderName =
    appointmentType.validateProviderLicense && timeSlot?.licensedProviderName;

  const { dayOfWeek, date, time } = getTimeComponents(timeSlot, appointmentType);

  return (
    <Content>
      <AppointmentBlockContainer>
        <Title>{appointmentType.name}</Title>

        <DateTimeLine>{dayOfWeek},</DateTimeLine>
        <DateTimeLine>{date}</DateTimeLine>
        <DateTimeLine>at {time}</DateTimeLine>

        <AppointmentLocation
          timeSlot={timeSlot}
          appointmentType={appointmentType}
          clinic={clinic}
        />

        <AdditionalInfo>
          <AdditionalInfoItem>
            <AdditionalInfoItemTitle>Session Duration</AdditionalInfoItemTitle>
            <AdditionalInfoItemValue>{formatDuration(appointmentType)}</AdditionalInfoItemValue>
          </AdditionalInfoItem>
          {showLicensedProviderName && (
            <AdditionalInfoItem>
              <AdditionalInfoItemTitle>Provider</AdditionalInfoItemTitle>
              <AdditionalInfoItemValue>{timeSlot.licensedProviderName}</AdditionalInfoItemValue>
            </AdditionalInfoItem>
          )}
          <AdditionalInfoItem>
            <AdditionalInfoItemTitle>Language</AdditionalInfoItemTitle>
            <AdditionalInfoItemValue>English</AdditionalInfoItemValue>
          </AdditionalInfoItem>
        </AdditionalInfo>
      </AppointmentBlockContainer>
    </Content>
  );
};
