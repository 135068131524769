import moment, { Moment as TMoment } from 'moment-timezone';

// mapping Rails timezones
export const MOMENT_TIME_ZONES = {
  Hawaii: 'Pacific/Honolulu',
  Alaska: 'America/Juneau',
  'Pacific Time (US & Canada)': 'America/Los_Angeles',
  'Mountain Time (US & Canada)': 'America/Denver',
  Arizona: 'America/Phoenix',
  'Central Time (US & Canada)': 'America/Chicago',
  'Eastern Time (US & Canada)': 'America/New_York',
  UTC: 'Etc/UTC',
  'Atlantic Standard (Puerto Rico)': 'America/Puerto_Rico'
};

export function momentFormatted(date: string, format: string) {
  return moment(date).format(format);
}

export function mappedTimeZone(timeZone: keyof typeof MOMENT_TIME_ZONES) {
  return MOMENT_TIME_ZONES[timeZone];
}

export function inFuture(time: TMoment | string) {
  return moment(time) > moment();
}

export function inFutureWithDelay(time: TMoment | string, delay: number) {
  return moment(time).add(delay, 'minutes') > moment();
}

export function inPast(time: TMoment | string) {
  return moment(time) < moment();
}

export function isToday(time: TMoment | string) {
  return moment(time).isSame(moment(), 'day');
}

export function momentInTimeZone(time: string, timeZone: string): moment.Moment {
  return moment(time).tz(MOMENT_TIME_ZONES[timeZone as keyof typeof MOMENT_TIME_ZONES]);
}

export function momentInTimeZoneAndFormat(time: string, timeZone: string, format: string): string {
  // In case that time is in format "YYYY-MM-DD ..." date parsing will fail in Safari.
  // If time is in format "YYYY/MM/DD...." date parsing will also work in Safari.
  return momentInTimeZone(time.replace(/-/g, '/'), timeZone).format(format);
}

export function stripTimeAndFormat(date: string, format: string): string {
  return moment(date, 'YYYY-MM-DD').format(format);
}

export function daysSince(date: string) {
  const dateOfTodoCreation = moment.utc(date);
  return moment().diff(dateOfTodoCreation, 'days');
}

export const convertDateForm = (date: string, format = 'M/D/YY'): string => {
  return moment(date)
    .parseZone()
    .format(format);
};

export function formatToWeekday(moment: moment.Moment | null): string | undefined {
  return moment?.format('dddd');
}

export function formatToShortDate(moment: moment.Moment | null): string | undefined {
  return moment?.format('MMM DD');
}

export function formatToWeekdayMonthDay(moment: moment.Moment | null): string | undefined {
  return moment?.format('ddd, MMM DD');
}

export function formatToLongWeekdayMonthDay(moment: moment.Moment | null): string | undefined {
  return moment?.format('dddd, MMM DD');
}

export function momentWithParsedTimeZone(time: string): moment.Moment {
  return moment.parseZone(time);
}

export function getMoment(time: string): moment.Moment {
  return moment(time);
}

export function getMomentAndStripTime(dateAndTime: string): moment.Moment {
  return moment(dateAndTime, 'YYYY-MM-DD');
}

export function getGuessedTimeZone(): string {
  return moment.tz.guess(true);
}

export function getNow(format: string): string {
  return moment().format(format);
}

export function diffYear(date?: string): number {
  if (!date) return 0;
  return moment().diff(date, 'years');
}
