import { LinkButton } from 'components/v2/Buttons/LinkButton/LinkButton';
import { Heading } from 'components/v2/Typography';
import theme from 'kb-shared/theme';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
`;

export const Title = styled(Heading).attrs({ tag: 'h2', styledAs: 'h5', noMargin: true })`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
  font-size: 14px;
  background: white;
  gap: 24px;
`;

export const CancellationPolicyLink = styled(LinkButton).attrs({
  size: 'md',
  color: theme.colors.semantic.darkBlue
})``;
