import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { BugTracker } from 'kb-shared/utilities/bugTracker';
import KBContacts from 'kb-shared/utilities/kindbody_contacts';
import { UNREAD_MESSAGES_COUNT } from 'layouts/MainLayout/Navigation/Navigation/Navigation.graphql';
import { ATTACHMENT_PURPOSES_QUERY, CONVERSATIONS_QUERY } from 'screens/Messages/Messages.graphql';
import {
  AttachmentPurposes,
  Conversation as ConversationType,
  MessageParams
} from 'screens/Messages/Messages.type';
import { getGraphQLErrorsMessages } from 'utilities/errors';
import { showErrorToast } from 'utilities/notificationUtils';
import { parseNumber } from 'utilities/parseNumber';

import { EmptyConversationList } from '../EmptyConversationList/EmptyConversationList';
import { Header } from '../Header/Header';
import { Inputs } from '../Inputs/Inputs';
import { MessageAttachment } from '../Inputs/Inputs.types';
import { MessageList } from '../MessageList/MessageList';
import { MARK_CONVERSATION_READ } from './Conversation.graphql';
import { useMessage } from './Conversation.hook';
import { Container, EmptyHeader, EmptyContent, ErrorMessage } from './Conversation.styled';
import { CreateConversation, CreateMessage } from './Conversation.types';
import { getID, showInputs } from './Conversation.utils';

export const Conversation = () => {
  const { categoryName, conversationId: conversationIdParam } = useParams<MessageParams>();
  const [markAsReadMutation] = useMutation<ConversationType>(MARK_CONVERSATION_READ);
  const conversationId = parseNumber(conversationIdParam);
  const { conversation, loading, sendingMessage, send, upload, error } = useMessage(conversationId);
  const { data: purposes } = useQuery<AttachmentPurposes>(ATTACHMENT_PURPOSES_QUERY, {
    onError: error => BugTracker.notify(error, 'Failed to fetch file purposes')
  });

  useEffect(() => {
    if (error) BugTracker.notify(error, 'Failed to fetch messages in conversation');
  }, [error]);

  useEffect(() => {
    if (!conversation?.unreadCount) return;

    try {
      markAsReadMutation({
        variables: {
          id: conversationId
        },
        refetchQueries: [{ query: UNREAD_MESSAGES_COUNT }, { query: CONVERSATIONS_QUERY }]
      });
    } catch (e) {
      BugTracker.notify('MarkAsRead Mutation', e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation]);

  const onSend = async (
    argument: CreateConversation | CreateMessage,
    attachments?: MessageAttachment[]
  ) => {
    try {
      const response = await send(argument);

      if (attachments) {
        const id = getID(response);
        if (!id) return;

        await upload(attachments, id);
      }

      return response;
    } catch (error) {
      const errorMessages = getGraphQLErrorsMessages(error);
      if (
        errorMessages.includes(
          "You are trying to send a message in a closed conversation. Please click on 'Compose' at the top right to send a new message."
        )
      ) {
        showErrorToast(
          'You are trying to send a message in a closed conversation. Please create a new conversation to send the message.'
        );
      } else if (error instanceof Error && error.message.includes('Received status code 413')) {
        showErrorToast('Your message is too long. Please split it into smaller parts. Thank you!');
      } else {
        BugTracker.notify(error, 'MessageInputSend');
        showErrorToast("Message wasn't sent. Please try again.");
      }
    }
  };

  if (!categoryName && !conversationId) {
    return (
      <Container isEmpty>
        <EmptyHeader />
        <EmptyContent>
          <EmptyConversationList description="Need assistance? Start a new chat by asking us a question or go to an existing conversation." />
        </EmptyContent>
      </Container>
    );
  }

  return (
    <Container>
      <Header conversation={conversation} categoryName={categoryName} />
      <MessageList loading={loading} conversation={conversation} />

      {error && (
        <ErrorMessage>
          {`At the moment messages in conversation can't be displayed. Please try again or contact us at ${KBContacts.navigatorEmail}`}
        </ErrorMessage>
      )}

      {showInputs(conversation?.status) && (
        <Inputs
          categoryName={categoryName}
          conversationId={conversationId}
          purposes={purposes}
          send={onSend}
          loading={sendingMessage}
        />
      )}
    </Container>
  );
};
