import { themes } from 'kb-shared';
import { AdditionalInfoPanel } from 'screens/Book/components/AdditionalInfoPanel';
import styled from 'styled-components';

export const StyledAdditionalInfoPanel = styled(AdditionalInfoPanel)`
  margin-top: 5em;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;
  width: 100%;
  max-width: 100%;
  @media screen and (max-width: ${themes.breakpoints.largePhonePortrait}) {
    min-width: 100%;
  }
`;

export const LabSelectorContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 20px;
  @media screen and (max-width: ${themes.breakpoints.largePhonePortrait}) {
    flex-direction: column;
    min-width: 100%;
    padding-top: 0px;
  }
`;

export const UnableToFindContainer = styled.div`
  margin: 40px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  div {
    text-align: center;
  }

  > * {
    padding: 20px 0px;
  }
`;

export const Title = styled.div`
  font-size: 24px;
  color: ${themes.colors.neutral.primaryNavy};
`;

export const ClinicsLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
`;
