import { gql } from '@apollo/client';

export const RESULTS_QUERY = gql`
  query results {
    results {
      afc: follicleCounts {
        name
        date
        units
        value
      }
      labs {
        type
        name
        date
        units
        value
        normalRange
        category
        categoryDisplayName
        report {
          pdfResults
          htmlResults
        }
      }
      spermAnalyses {
        type
        name
        date
        units
        value
        normalRange
        category
        categoryDisplayName
      }
      homeTestingResults {
        id
        customCode
        createdAt
        pdfLink
        labOrderResultValues {
          shortName
          fullName
          description
          date
          units
          value
          low
          high
          recommendation
        }
      }
      oocyteInventory {
        id
        specimenType
        cryoDate
        cryoDay
        grade
        labLocation
      }
    }
    dailyResults {
      date
      follicles {
        date
        name
        type
        units
        value
      }
      labs {
        category
        categoryDisplayName
        date
        name
        normalRange
        type
        units
        value
      }
    }
    cultureResult {
      retrieved
      embryosThawed
      eggsThawed
      eggsIcsid
      eggsInseminated
      biopsied
      eggsCryopreserved
      embryosCryopreserved
      eggsDiscarded
      embryosDiscarded
      eggsDonated
      embryosTransferred
      fertilized
      attemptType
      embryosCryoedDetails {
        sample
        grade
        dayFrozen
      }
    }
  }
`;
