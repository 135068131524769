import { useQuery } from '@apollo/client';
import React from 'react';

import { Loader } from 'components/Loader/Loader';
import { Button } from 'components/v2/Buttons/Button';
import { Modal } from 'components/v2/Modal/Modal';
import theme from 'kb-shared/theme';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { CATEGORY_SLUG } from 'screens/Messages/Messages.constants';
import { pageUrl } from 'utilities/pageUrl';

import { CUSTOM_TILES_QUERY } from './EmployerBenefitReimbursementModal.graphql';
import { ButtonContainer } from './EmployerBenefitReimbursementModal.styled';
import { CustomTileResponse } from './EmployerBenefitReimbursementModal.types';

const EMPLOYER_BENEFIT_REIMBURSEMENT_TILE_ID = 46;

const PDF_URL =
  'https://kindbody-portal-static-files.s3.us-east-2.amazonaws.com/Universal+Reimbursement+Form+12102024.pdf';

export const EmployerBenefitReimbursementModal = () => {
  const { data, loading } = useQuery<CustomTileResponse>(CUSTOM_TILES_QUERY, {
    variables: {
      customTitleId: EMPLOYER_BENEFIT_REIMBURSEMENT_TILE_ID
    },
    onError: error => BugTracker.notify(error, 'EmployerBenefitReimbursementModal')
  });

  return (
    <Modal
      title={data?.customTile.header || ''}
      open
      onCancel={() => {}}
      closable={false}
      maxWidth={600}
    >
      {!data || loading ? (
        <Loader type="spin" color={theme.colors.yellow.primary} height={50} width={50} />
      ) : (
        <>
          <div>
            <b>{data?.customTile.boldText}</b>
          </div>

          {data?.customTile.regularText}

          <ButtonContainer>
            <Button
              label="PDF"
              category="primary-dark"
              size="lg"
              fullWidth
              onClick={() => window.open(PDF_URL, '_blank')}
            />

            <Button
              label="Submit"
              category="primary-dark"
              size="lg"
              fullWidth
              onClick={() =>
                (window.location.href = pageUrl.messages({
                  categoryName: CATEGORY_SLUG.EMPLOYER_BENEFIT_REIMBURSEMENT,
                  subject: 'Reimbursement Request for Covered Services',
                  message:
                    'Hello! I am requesting a reimbursement for covered services. Please see attached'
                }))
              }
            />
          </ButtonContainer>
        </>
      )}
    </Modal>
  );
};
