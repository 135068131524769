import { ApolloError } from '@apollo/client';

import { utils } from 'kb-shared';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import KBContacts from 'kb-shared/utilities/kindbody_contacts';

export const graphQLErrorMessage = (error: ApolloError) =>
  error.graphQLErrors.length > 0 && error.graphQLErrors[0].message;

export const isPatientUnauthorizedError = (error: any): boolean => {
  /*
  Patient Unauthorized looks like this:
  {
    "graphQLErrors":[
        {
          "message":"Unauthorized",
          "path":[
              "patient"
          ],
          "extensions":{...}
        }
    ],
    "networkError":null,
    "message":"GraphQL error: Unauthorized"
  }
  */
  if (!hasGraphQLErrors(error)) return false;
  return hasUnauthorizedPatientError(error);
};

const hasGraphQLErrors = (error: any): error is ApolloError => {
  if (!error) return false;
  const graphQLErrors = error['graphQLErrors'];
  if (!graphQLErrors || !graphQLErrors.length) return false;

  return true;
};

export const getGraphQLErrorsMessages = (error: any): string[] => {
  if (!hasGraphQLErrors(error)) return [];

  return error['graphQLErrors'].map(graphQLError => graphQLError.message);
};

export const isGraphQLUnauthorizedError = (error: any) => error['message'] === 'Unauthorized';

export const isFirstGraphQLErrorUnauthorized = (error: any): boolean => {
  if (!hasGraphQLErrors(error)) return false;

  const firstError = error['graphQLErrors'][0];

  return isGraphQLUnauthorizedError(firstError);
};

const hasUnauthorizedPatientError = (error: ApolloError) => {
  const isUnauthorized = isFirstGraphQLErrorUnauthorized(error);

  if (!isUnauthorized) return false;

  const firstError = error['graphQLErrors'][0];

  return firstError['path']?.[0] === 'patient';
};

const isAmountMismatchMessage = (message: string): boolean => {
  const pattern = /amount received \d+ \(cents\) is not matching the requested amount \d+ \(cents\)/;
  return pattern.test(message.toLowerCase());
};

export const getPatientFacingErrorMessageForPaymentError = (
  message: string
): string | undefined => {
  if (!message) {
    return undefined;
  } else if (message.includes('location')) {
    return `Looks like you're trying to book an appointment in a new city! Please reach out to ${utils.KBContacts.navigatorEmail} so we can activate your new account.`;
  } else if (message.includes('an error occurred while processing your card')) {
    return 'An error occurred while processing your card. Please try again in a little bit.';
  } else if (message.includes('expired')) {
    return 'Your card has expired.';
  } else if (message.includes('security code')) {
    return "Your card's security code is incorrect.";
  } else if (message.includes('card was declined') || message.includes('card has been declined')) {
    return 'Your card was declined.';
  } else if (message.includes('insufficient funds')) {
    return 'Your card has insufficient funds.';
  } else if (message.includes('time slot has already been taken')) {
    return 'This appointment time has already been taken.';
  } else if (message.includes('card number is incorrect')) {
    return 'Your card number is incorrect';
  } else if (message.includes('card number is incomplete.')) {
    return 'Your card number is incomplete.';
  } else if (message.includes('Kartennummer ist unvollständig.')) {
    return 'Your card number is incomplete.';
  } else if (isAmountMismatchMessage(message)) {
    // covers case when patient tried to use 3ds card, for more info see https://github.com/Kindbody/all_rails/pull/7248
    return `Your card seems to be using a way of payment we currently don't support. Please try to use another card or contact us at ${utils.KBContacts.navigatorEmail}`;
  } else {
    return undefined;
  }
};

export const handlePaymentError = (error: unknown, onError: (errorMessage: string) => void) => {
  if (error instanceof ApolloError) {
    const patientFacingErrorMessage = getPatientFacingErrorMessageForPaymentError(error.message);
    if (patientFacingErrorMessage) onError(patientFacingErrorMessage);
    else {
      BugTracker.notify(error, 'Payment Processing Error');
      // Error message returned from BE contains technical details.
      // TODO: analyse HB for other errors that should have patient facing error message without technical details.
      onError(`Payment failed. Please try again or contact us at ${KBContacts.navigatorEmail}`);
    }
  } else {
    BugTracker.notify(error, 'Payment Processing Error');
  }
};

export const isOverlappingAppointmentError = (error: ApolloError) => {
  return Boolean(
    error.graphQLErrors?.length === 1 &&
      error.graphQLErrors[0].extensions &&
      error.graphQLErrors[0].extensions['has_overlapping']
  );
};
