import { Text } from 'components/v2/Typography';
import styled from 'styled-components';

export const ProviderInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: flex-start;
`;

export const ProviderLabel = styled(Text).attrs({ tag: 'span', size: 'sm', fontStyle: 'medium' })`
  color: ${({ theme }) => theme.colors.neutral.navy};
`;

export const Provider = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;
`;

export const ProviderName = styled(Text).attrs({ tag: 'span', size: 'md', fontStyle: 'semibold' })`
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
`;
