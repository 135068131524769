import React from 'react';
import { ReactSVG } from 'react-svg';

import defaultIcon from 'assets/icons/custom-widget-icon.svg';
import { Heading, Text } from 'components/v2/Typography';

import { CustomTile } from '../CustomWidgets/CustomWidgets.types';
import {
  ButtonsContainer,
  IconContainer,
  IllustratedBackgroundWidget,
  TextRow,
  WidgetTitleContainer
} from '../Widgets/Widgets.styled';
import { CustomButton } from './Components/CustomButton';
import { CustomList } from './Components/CustomList';
import { CustomTileButton } from './CustomWidget.type';

export const CustomWidget = ({
  customTile,
  backgroundVariant
}: {
  customTile: CustomTile;
  backgroundVariant: number;
}) => {
  const iconFallback = () => <img alt="widget_icon" src={defaultIcon} />;

  return (
    <IllustratedBackgroundWidget variant={backgroundVariant}>
      <IconContainer>
        <ReactSVG
          beforeInjection={svg => {
            svg.setAttribute('width', '48px');
            svg.setAttribute('height', '48px');
          }}
          src={customTile.svgIconUrl}
          fallback={iconFallback}
        />
      </IconContainer>
      <WidgetTitleContainer>
        <Heading tag="div" styledAs="h2">
          {customTile.header}
        </Heading>
      </WidgetTitleContainer>
      <TextRow>
        <Text fontStyle="semibold">{customTile.boldText}</Text>
      </TextRow>
      <TextRow>
        <Text fontStyle="regular">{customTile.regularText}</Text>
      </TextRow>
      {customTile.listItems?.length ? (
        <CustomList items={customTile.listItems} type={customTile.listStyle} />
      ) : null}

      <ButtonsContainer>
        {getCustomTileButtons(customTile).map((button, index) => {
          return (
            <React.Fragment key={index}>
              <CustomButton
                style={button.buttonStyle}
                text={button.buttonText}
                url={button.linkUrl}
              />
            </React.Fragment>
          );
        })}
      </ButtonsContainer>
    </IllustratedBackgroundWidget>
  );
};

const getCustomTileButtons = (customTile: CustomTile): CustomTileButton[] => {
  const { buttonText, linkUrl } = customTile;
  const texts = buttonText.split('\n');
  const links = linkUrl.split('\n');
  if (texts.length !== links.length) return [customTile];

  return texts.map((text, i) => ({
    buttonText: text,
    linkUrl: links[i],
    buttonStyle: customTile.buttonStyle
  }));
};
