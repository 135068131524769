import React from 'react';

import kindbody from 'assets/logos/kindbody.svg';
import Button from 'components/Buttons/NewButton';
import { Loader } from 'components/Loader/Loader';
import Modal from 'components/Modal/Modal';
import { utils } from 'kb-shared';

import {
  ErrorContent,
  ErrorTextContainer,
  FullPageLoaderContainer,
  FullPageLoaderContent
} from './SignUpFormModal.styled';
import { SignUpFormModalProps } from './SignUpFormModal.types';

const { KBContacts } = utils;

export const SignUpFormModal = (props: SignUpFormModalProps) => {
  const { referralSourcesLoadStatus, timeZonesLoadStatus, locationsLoadStatus } = props;
  const loadStatuses = [referralSourcesLoadStatus, timeZonesLoadStatus, locationsLoadStatus];
  const modalVisible = loadStatuses.some(s => !s);
  const loaderVisible = loadStatuses.some(s => s == null);

  if (!modalVisible) return null;

  return (
    <Modal fullScreen open={modalVisible}>
      <FullPageLoaderContainer>
        <img src={kindbody} alt="Kindbody logo" />
        <FullPageLoaderContent>
          {loaderVisible ? (
            <Loader />
          ) : (
            <ErrorContent>
              <ErrorTextContainer>
                <span>
                  We are sorry, we were unable to load this form.
                  <br />
                  Please reload the page, or contact us at{' '}
                  <a className="link" href={`mailto:${KBContacts.navigatorEmail}`}>
                    {KBContacts.navigatorEmail}
                  </a>
                  .
                </span>
              </ErrorTextContainer>
              <Button text="Reload" onClick={() => window.location.reload()} />
            </ErrorContent>
          )}
        </FullPageLoaderContent>
      </FullPageLoaderContainer>
    </Modal>
  );
};
