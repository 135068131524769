import { gql, useLazyQuery } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as MapIcon } from 'assets/icons/map-icon.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import Button from 'components/Buttons/NewButton';
import { Loader } from 'components/Loader/Loader';
import Pagination from 'components/Pagination';
import { ZipField, SelectField } from 'components/v2/Form';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { analytics } from 'utilities/analytics';
import { showErrorToast } from 'utilities/notificationUtils';
import { pageUrl } from 'utilities/pageUrl';

import PartnerClinicCard from './PartnerClinicCard';
import {
  Title,
  Text,
  Container,
  ZipCodeContainer,
  KindbodyNetworkContainer,
  UnableToFindContainer,
  SearchIconWrapper,
  PartnerClinicsContainer
} from './PartnerLocationSearch.styles';
import {
  DistanceOption,
  PartnerLocationSearchProps,
  ZipcodeDistanceResponseData
} from './PartnerLocationSearch.types';
const ZIP_CODE = gql`
  query zipcodeDistances($zipcodeStr: String!, $distance: Int!, $units: String!) {
    zipClinicDistance(zipcodeStr: $zipcodeStr, distance: $distance, units: $units) {
      name
      address
      distance
      tier
      contactName
      contactEmail
      state
      city
    }
  }
`;

const DISTANCE_OPTIONS = [
  { label: '10 Miles', value: '10' },
  { label: '30 Miles', value: '30' },
  { label: '60 Miles', value: '60' }
];

const ITEMS_PER_PAGE = 9;

export const PartnerLocationSearch = (props: PartnerLocationSearchProps) => {
  const [zipCode, setZipCode] = useState('');
  const [currentPage, setPage] = useState(0);
  const [distance, setDistance] = useState<DistanceOption>(DISTANCE_OPTIONS[2]);
  const [getZipcodeDistances, { data, loading }] = useLazyQuery<ZipcodeDistanceResponseData>(
    ZIP_CODE,
    {
      onError: error => {
        showErrorToast('Unable to fetch partner clinics. Please try again.');
        BugTracker.notify(error, 'PartnerLocationSearch');
      }
    }
  );
  const history = useHistory();

  useEffect(() => {
    if (Array.isArray(data?.zipClinicDistance)) {
      if (
        // @ts-ignore Object is possibly 'undefined'.
        data.zipClinicDistance.length > 0
      ) {
        analytics.track(analytics.EVENTS.PARTNER_CLINIC_SEARCH_SUCCESSFUL, {
          zip_code: zipCode,
          radius: parseInt(distance.value)
        });
      } else {
        analytics.track(analytics.EVENTS.PARTNER_CLINIC_SEARCH_ERROR, {
          zip_code: zipCode,
          radius: parseInt(distance.value)
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Container>
      <ZipCodeContainer>
        <ZipField
          id="zipcodeInput"
          label="Enter your ZIP code"
          value={zipCode}
          zipType="US"
          placeholder="ZIP"
          autoComplete="postal-code"
          onChange={event => {
            setZipCode(event.currentTarget.value);
          }}
        />

        <SelectField
          id="stateInput"
          label="Distance"
          placeholder="Select distance"
          selected={distance}
          options={DISTANCE_OPTIONS}
          onChange={item => setDistance(item)}
        />

        <SearchIconWrapper
          onClick={() => {
            const filteredZipCode = zipCode.replace(/_/g, '');
            if (filteredZipCode.length !== 5) {
              showErrorToast('Zip code must contain 5 digits.');
              return;
            }

            const radius = parseInt(distance.value);
            analytics.track(analytics.EVENTS.PARTNER_CLINIC_SEARCHED, {
              zip_code: zipCode,
              radius
            });
            getZipcodeDistances({
              variables: {
                zipcodeStr: zipCode,
                distance: radius,
                units: 'miles'
              }
            });
          }}
        >
          <SearchIcon width={40} height={40} fill="black" />
        </SearchIconWrapper>
      </ZipCodeContainer>

      {loading && <Loader container />}

      {!loading && !data && (
        <KindbodyNetworkContainer>
          <div>
            <Title>The Kindbody Network</Title>
            <Text>
              Kindbody's signature clinics offer the full spectrum of health and fertility care
              under one roof. We offer a variety of services including fertility, fertility
              preservation, gynecology, pre and post partum care, surrogacy and adoption services,
              along with comprehension wellness and behavioral health in modern, tech-enabled
              clinics.
            </Text>
            <Text>
              In addition to our locations, we partner with over 200 clinics across the country to
              ensure our members get the access and coverage they need.
            </Text>
            <Text>
              <b>Search our network to get started!</b>
            </Text>
          </div>
          <div>
            <MapIcon />
          </div>
        </KindbodyNetworkContainer>
      )}

      {!loading && data?.zipClinicDistance.length === 0 && (
        <UnableToFindContainer>
          <Title>
            We're sorry, we were unable to find any results for your search.
            <br />
            Please try again or message us directly so we can coordinate your care!
          </Title>
          <Button
            text="Contact Us"
            onClick={() => {
              analytics.track(analytics.EVENTS.SECURE_MESSAGE_PAGE);

              history.push(
                pageUrl.messages({ categoryName: 'admin', subject: 'Partner Clinic Request' })
              );
            }}
          />
        </UnableToFindContainer>
      )}
      <PartnerClinicsContainer
        centerContent={data ? data.zipClinicDistance.length % ITEMS_PER_PAGE <= 2 : false}
      >
        {data?.zipClinicDistance &&
          data.zipClinicDistance
            .slice(currentPage * ITEMS_PER_PAGE, (currentPage + 1) * ITEMS_PER_PAGE)
            .map((partnerClinicDetails, idx) => (
              <PartnerClinicCard
                key={idx}
                onClick={() => {
                  analytics.track(analytics.EVENTS.PARTNER_CLINIC_SELECTED, {
                    name: partnerClinicDetails.name,
                    address: partnerClinicDetails.address,
                    city: partnerClinicDetails.city,
                    state: partnerClinicDetails.state,
                    page_number: currentPage + 1
                  });
                  props.onSelected(partnerClinicDetails);
                }}
                partnerClinicDetails={partnerClinicDetails}
              />
            ))}
      </PartnerClinicsContainer>

      {data?.zipClinicDistance && data?.zipClinicDistance.length > ITEMS_PER_PAGE && (
        <Pagination
          currentPage={currentPage}
          numberOfPages={Math.ceil(data?.zipClinicDistance.length / ITEMS_PER_PAGE)}
          setPage={setPage}
        />
      )}
    </Container>
  );
};
