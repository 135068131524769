import React from 'react';

import kindbody from 'assets/logos/kindbody.svg';
import Modal from 'components/Modal/Modal';
import { Button } from 'components/v2/Buttons/Button';
import { utils } from 'kb-shared';

import {
  ErrorContent,
  ErrorTextContainer,
  FullPageLoaderContainer,
  FullPageLoaderContent
} from './InsuranceFormNotLoadedModal.styled';
import { InsuranceFormNotLoadedModalProps } from './InsuranceFormNotLoadedModal.types';

const { KBContacts } = utils;

export const InsuranceFormNotLoadedModal = (props: InsuranceFormNotLoadedModalProps) => {
  const { modalVisible, onLoadDataAgain } = props;

  if (!modalVisible) return null;

  return (
    <Modal fullScreen open={modalVisible}>
      <FullPageLoaderContainer>
        <img src={kindbody} alt="Kindbody logo" />
        <FullPageLoaderContent>
          <ErrorContent>
            <ErrorTextContainer>
              <span>
                We are sorry, we were unable to load the data needed to fill this form.
                <br />
                Please try to load the data again, or contact us at{' '}
                <a className="link" href={`mailto:${KBContacts.navigatorEmail}`}>
                  {KBContacts.navigatorEmail}
                </a>
                .
              </span>
            </ErrorTextContainer>
            <Button label="Load again" onClick={() => onLoadDataAgain()} />
          </ErrorContent>
        </FullPageLoaderContent>
      </FullPageLoaderContainer>
    </Modal>
  );
};
