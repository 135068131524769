import styled, { css } from 'styled-components';

export const TableHead = styled.th<{ $alignRight?: boolean }>`
  padding: 24px 16px;
  background-color: ${({ theme }) => theme.colors.yellow.primary};
  border: 0.8px solid ${({ theme }) => theme.colors.yellow.darkYellow};
  ${({ $alignRight }) => ($alignRight ? 'text-align: right;' : 'text-align: start;')}
`;

export const TableComponent = styled.table`
  width: 100%;
  margin-top: 1em;
  border-spacing: 0px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tabletLandscape}) {
    th {
      white-space: nowrap;
    }
  }

  td {
    white-space: nowrap;
    width: 50%;
  }
`;

export const TableBody = styled.tbody`
  width: 100%;
  font-size: 16px;
  line-height: 20px;

  background: ${({ theme }) => theme.colors.white};
`;

export const TableCell = styled.td<{ $alignRight?: boolean }>`
  padding: 1.5em 1em;
  border: 0.8px solid ${({ theme }) => theme.colors.neutral.lavender};

  ${({ $alignRight }) =>
    $alignRight &&
    css`
      text-align: right;
    `};
`;
