import React from 'react';

import { Heading } from 'components/v2/Typography';
import theme from 'kb-shared/theme';
import { formatDate } from 'utilities/formatDate';

import { PartnerInvite } from '../../InterstitialModal.types';
import {
  ListItemContainer,
  Name,
  BirthDate,
  Email,
  Status,
  PartnerInfoContainer
} from './PartnerListItem.styled';

export const PartnerListItem = (invite: PartnerInvite) => (
  <ListItemContainer>
    <PartnerInfoContainer>
      <Name>
        <Heading tag="h4" noMargin multilineEllipses={2}>
          {invite.firstName + ' ' + invite.lastName}
        </Heading>
      </Name>
      <BirthDate>
        <Heading tag="h5" color={theme.colors.neutral.navy} noMargin>
          ({formatDate(invite.birthDate)})
        </Heading>
      </BirthDate>
      <Email>
        <Heading tag="h5" color={theme.colors.neutral.lightNavy} noMargin multilineEllipses={2}>
          {invite.email}
        </Heading>
      </Email>
    </PartnerInfoContainer>
    <Status>
      <Heading tag="h5" noMargin>
        {formatWordsToPascalCase(invite.status)}
      </Heading>
    </Status>
  </ListItemContainer>
);

const formatWordsToPascalCase = (string: string) =>
  string.replace(/\w\S*/g, (w: string) => w.replace(/^\w/, c => c.toUpperCase()));
