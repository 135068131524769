import React from 'react';

import { locationDark, location as locationIcon } from 'assets/icons';
import { Button } from 'components/v2/Buttons/Button';
import { Appointment } from 'kb-shared';
import { VIRTUAL_APPOINTMENT_LICENSING_DISCLAIMER } from 'kb-shared/utilities/appointment.helper';

import {
  Address,
  AppointmentLocation,
  ClinicName,
  ConsultInstructions,
  LocationWrapper,
  PhoneConsult,
  VideoConsult
} from './LocationDetails.styled';

interface Props {
  isFuture: boolean;
  isVideoCall: boolean;
  isPhoneCall: boolean;
  isInPerson: boolean;
  goToVideoLink: () => void;
  appointment: Appointment;
}

export const LocationDetails = ({
  isFuture,
  isVideoCall,
  isPhoneCall,
  isInPerson,
  appointment,
  goToVideoLink
}: Props) => {
  return (
    <LocationWrapper>
      {isVideoCall && isFuture && (
        <VideoConsult>
          <ConsultInstructions>{VIRTUAL_APPOINTMENT_LICENSING_DISCLAIMER}</ConsultInstructions>
          <Button
            label="Join video consult"
            category="primary"
            size="lg"
            fullWidth
            type="submit"
            onClick={goToVideoLink}
          />
        </VideoConsult>
      )}

      {isPhoneCall && isFuture && (
        <PhoneConsult>
          <ConsultInstructions>
            Your provider will call you. By joining this phone call appointment, you confirm that
            you are physically present in one of the state(s) where this provider is licensed.
          </ConsultInstructions>
        </PhoneConsult>
      )}

      {isInPerson && (
        <>
          <img
            src={isFuture ? locationIcon : locationDark}
            alt="A yellow map pin"
            style={{ marginRight: 16 }}
          />
          <AppointmentLocation>
            <ClinicName>{appointment.location.name}</ClinicName>
            <Address>{appointment.location.address}</Address>
          </AppointmentLocation>
        </>
      )}
    </LocationWrapper>
  );
};
