import { gql, useQuery } from '@apollo/client';

import { BugTracker } from 'kb-shared/utilities/bugTracker';

export const TOTAL_BALANCE_QUERY = gql`
  query invoicesTotalBalance {
    totalBalanceOfInvoices
  }
`;

const useTotalBalance = (): UseTotalBalanceResponse => {
  const { loading, data } = useQuery<TotalBalanceResponse>(TOTAL_BALANCE_QUERY, {
    onError: error => BugTracker.notify(error, 'TotalBalanceFetch')
  });

  const totalBalance = parseFloat(data?.totalBalanceOfInvoices ?? '0');

  return { totalBalance, loading };
};

export default useTotalBalance;

interface UseTotalBalanceResponse {
  totalBalance: number;
  loading: boolean;
}

export interface TotalBalanceResponse {
  totalBalanceOfInvoices: string | null;
}
