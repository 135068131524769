import styled from 'styled-components';

export const PlusIcon = styled.img`
  width: 16px;
  height: 16px;
  margin: 4px 14px 4px 4px;
`;

export const ButtonText = styled.span`
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.6px;
  ${({ theme }) => theme.colors.neutral.primaryNavy};
`;

export const ButtonBackgroundAndBorder = styled.button`
  padding: 24px 32px;
  width: 100%;
  height: 80px;
  background: ${({ theme }) => theme.colors.neutral.lavenderLight};
  border: 2px dashed ${({ theme }) => theme.colors.neutral.lighterNavy};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 26px;
`;
