import { Text } from 'components/v2/Typography';
import theme from 'kb-shared/theme';
import styled from 'styled-components';

export const CancellingInfo = styled(Text).attrs({ size: 'md' })``;

export const CancellingInfoTitle = styled(Text).attrs({
  size: 'md',
  fontStyle: 'bold',
  tag: 'p',
  color: theme.colors.neutral.primaryNavy
})``;
