import { ApolloError } from '@apollo/client';

import { BugTracker } from 'kb-shared/utilities/bugTracker';
import {
  getPatientFacingErrorMessageForPaymentError,
  isOverlappingAppointmentError
} from 'utilities/errors';

export const errorIsOverlappingAppointmentError = (err: ApolloError) =>
  isOverlappingAppointmentError(err);

export function generateErrorString(err: ApolloError | null): string {
  if (!err?.message)
    return 'Please verify that you have correctly entered your credit card number, expiration date and security code.';

  const message = err.message.toLowerCase();
  const patientFacingMessage = getPatientFacingErrorMessageForPaymentError(message);

  if (patientFacingMessage) {
    return patientFacingMessage;
  } else if (message === WRONG_MARKET_ERROR_MESSAGE) {
    return 'You are trying to book the appointment at a location that is different from your home market. Please choose the correct location to book this appointment.';
  } else {
    BugTracker.notify(err, 'Payment Processing Error');
    return "We're sorry, something went wrong. Please try again.";
  }
}

const WRONG_MARKET_ERROR_MESSAGE =
  "could not create appointment: this appointment can only be booked in the same market as the patient's home market ";
