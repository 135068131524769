import { Patient } from 'kb-shared';
import { hasMembership, hasMembershipDescription } from 'utilities/patient';

import { JourneyResponse } from '../Dashboard.types';

export const isPatientFemale = (patient: Patient): boolean => {
  return patient.gender === 'female';
};

// widget display logic
export const showSaicWidget = (patient: Patient): boolean =>
  patient.patientMembership?.membership?.employerId === 119;

export const showEmbryoIncWidget = (patient: Patient): boolean =>
  patient.patientMembership?.membership?.employerId === 86;

export const showBenefitsWidget = (patient: Patient): boolean => hasMembershipDescription(patient);

export const showActivateBenefitsWidget = (patient: Patient): boolean => {
  const isHiddenSaved = localStorage.getItem('BenefitsWidgetIsHidden') === 'true';

  return !hasMembership(patient) && !isHiddenSaved;
};

export const showJourneyWidget = (journeyData: JourneyResponse | undefined): boolean => {
  if (!journeyData?.journeyType) return false;
  if (journeyData.journeyType === 'VIRTUAL FIRST WORKUP') return false;

  return true;
};

export const showFertilityCalculator = (patient: Patient): boolean => isPatientFemale(patient);
export const showPeriodTracker = (patient: Patient): boolean => isPatientFemale(patient);
export const showPregnancyCalculatorWidget = (patient: Patient): boolean =>
  isPatientFemale(patient);
