import React from 'react';

import { Loader } from 'components/Loader/Loader';
import { Patient } from 'kb-shared';
import { analytics } from 'utilities/analytics';

import { DashboardWidgetList } from '../DashboardSettings/DashboardSettings.types';
import { isHiddenWidget } from '../DashboardSettings/DashboardSettings.utils';
import { isPatientFemale, showPregnancyCalculatorWidget } from '../Widgets/Widgets.utils';
import { LMPEntered } from './components/LMPEntered';
import { NoConfirmedPregnancy } from './components/NoConfirmedPregnancy';
import { NoLMPEntered } from './components/NoLMPEntered';
import { usePregnancyCalculator } from './hooks/use-pregnancy-calculator';

interface Props {
  patient: Patient;
  hiddenWidgets?: DashboardWidgetList;
}

export const PregnancyCalculatorWidget = ({ patient, hiddenWidgets }: Props) => {
  const isFemale = isPatientFemale(patient);
  const {
    state,
    gestationalAgeInDays,
    estimatedDueDate,
    isCancelEnabled,
    updatePregnancyAcknowledgment
  } = usePregnancyCalculator(isFemale);

  if (
    !showPregnancyCalculatorWidget(patient) ||
    isHiddenWidget(hiddenWidgets, 'pregnancyCalculator')
  )
    return null;

  const onCancelClick = isCancelEnabled ? () => updatePregnancyAcknowledgment(false) : undefined;

  if (state === 'no-confirmed-pregnancy') {
    const onPregnantClick = () => {
      analytics.track(analytics.EVENTS.PREGNANCY_INDICATED);
      updatePregnancyAcknowledgment(true);
    };
    return <NoConfirmedPregnancy onPregnantClick={onPregnantClick} />;
  } else if (state === 'lmp-entered' && gestationalAgeInDays && estimatedDueDate) {
    return (
      <LMPEntered
        onCancelClick={onCancelClick}
        gestationalAgeInDays={gestationalAgeInDays}
        estimatedDueDate={estimatedDueDate}
      />
    );
  } else if (state === 'no-lmp-entered') {
    return <NoLMPEntered onCancelClick={onCancelClick} />;
  }

  return <Loader />;
};
