import React, { useState } from 'react';

import { Text } from 'components/v2/Typography';

import { PARTNER_TAB_INFO_BOX_MESSAGE } from '../../InterstitialModal.constants';
import { PartnerListProps } from '../../InterstitialModal.types';
import { InfoBox } from '../InfoBox';
import { AddPartnerButton } from './AddPartnerButton';
import {
  PartnerListContainer,
  PartnerListItemsContainer,
  TitleContainer
} from './PartnerList.styled';
import { PartnerListItem } from './PartnerListItem';

export const PartnerList = ({ onAddPartner, invites }: PartnerListProps) => {
  const [isAddPartnerButtonEnabled, setIsAddPartnerButtonEnabled] = useState<boolean>(true);

  const invitesExist = invites && invites.length > 0;

  return (
    <>
      {invitesExist && (
        <PartnerListContainer>
          <TitleContainer>
            <Text fontStyle="bold" size="lg">
              Partner Info
            </Text>
          </TitleContainer>
          <PartnerListItemsContainer>
            {invites.map(i => (
              <PartnerListItem key={i.id} {...i} />
            ))}
          </PartnerListItemsContainer>
        </PartnerListContainer>
      )}
      {isAddPartnerButtonEnabled && (
        <AddPartnerButton
          onClick={() => {
            onAddPartner();
            setIsAddPartnerButtonEnabled(!isAddPartnerButtonEnabled);
          }}
        />
      )}
      {!invitesExist && isAddPartnerButtonEnabled && (
        <InfoBox message={PARTNER_TAB_INFO_BOX_MESSAGE} />
      )}
    </>
  );
};
