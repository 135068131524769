import React, { useState } from 'react';

import ContactModal from 'components/Footer/ContactModal';
import { useContactInfo } from 'hooks';
import theme from 'kb-shared/theme';
import { useBreakpoints } from 'utilities/useBreakpoints';

import { INTRODUCTION } from '../../InterstitialModal.constants';
import { OpenModalLink } from '../Shared.styled';
import { ContactsParagraph, StyledIntroduction } from './Contacts.styled';

export const Contacts = () => {
  const { isMinTablet } = useBreakpoints();

  const [visible, setVisible] = useState(false);
  const { numberLabel, number } = useContactInfo();

  return (
    <>
      <ContactsParagraph>
        <StyledIntroduction>{INTRODUCTION}</StyledIntroduction>
        <strong>
          If you need help or have any questions, please call{' '}
          <a href={`tel:${number}`} style={{ color: `${theme.colors.semantic.darkBlue}` }}>
            {numberLabel}.{' '}
          </a>
        </strong>
        {isMinTablet && <br />}
        <span>
          <strong> If this is a medical emergency, please </strong>
        </span>
        <OpenModalLink
          onClick={() => setVisible(true)}
          text="click here."
          fontStyle="bold"
          underline="none"
          color={theme.colors.semantic.darkBlue}
        />
        {isMinTablet && (
          <>
            <br />
            <strong>Thank you for your cooperation!</strong>
          </>
        )}
      </ContactsParagraph>
      <ContactModal visible={visible} onDismiss={() => setVisible(false)} />
    </>
  );
};

export default Contacts;
