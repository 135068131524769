/** Styled components shared between TermsDetails and EnterpriseMembershipTermsDetails **/

import styled from 'styled-components';

export const TermsBlock = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.neutral.navy};
  padding: 20px;
  margin-bottom: 28px;
  align-items: center;
`;

export const TermsInnerContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TermsTextContainer = styled.div`
  margin-bottom: 24px;
  margin-left: 15px;

  :last-of-type {
    margin-bottom: 0;
  }
`;

export const EnterpriseTermsTextContainer = styled.div`
  margin-bottom: 24px;

  :last-of-type {
    margin-bottom: 0;
  }
`;

export const TermsText = styled.span`
  font-size: 14px;
  color: white;
`;

export const TermsLink = styled.a`
  font-size: 14px;
  color: white;
  font-weight: bold;
  text-decoration: underline;
`;

export const Separator = styled.div`
  box-sizing: border-box;
  height: 1px;
  border: 1px solid #979797;
  background-color: #d8d8d8;
  margin: 16px 0px 16px 0px;
`;

export const CheckboxContainer = styled.div`
  margin-top: 4px;
  margin-right: 14px;
`;

export const EmployeeAttestation = styled.div`
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;

  p {
    margin-top: 16px;
  }
`;
