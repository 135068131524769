import { CircleInfo } from 'assets/icons/Icons';
import theme from 'kb-shared/theme';
import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  border-spacing: 0px;
  line-height: 24px;

  td,
  th {
    padding: 16px;

    text-align: start;
    vertical-align: middle;
  }

  thead {
    padding: 24px 16px;

    background-color: ${({ theme }) => theme.colors.yellow.primary};

    th {
      border-left: 1px solid ${({ theme }) => theme.colors.yellow.darkYellow};
      border-right: 1px solid ${({ theme }) => theme.colors.yellow.darkYellow};
      border-bottom: 1px solid ${({ theme }) => theme.colors.yellow.darkYellow};
      padding: 24px 16px;
    }
  }

  tbody td {
    border: 1px solid ${({ theme }) => theme.colors.neutral.lavender};
    background-color: white;
  }
`;

export const IconInfo = styled(CircleInfo).attrs({ type: 'solid' })`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  color: ${theme.colors.semantic.red};
`;
