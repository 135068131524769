import theme from 'kb-shared/theme';
import styled from 'styled-components';

export const EmbryosCryoedDetailsCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 176px;
  padding: 16px 24px;
  border-radius: 8px;
  gap: 16px;
  background-color: ${theme.colors.white};
  color: ${theme.colors.neutral.primaryNavy};
`;

const RowContainer = styled.div`
  height: 64px;
  display: flex;
  justify-content: space-between;
`;

export const TopRowContainer = styled(RowContainer)`
  flex-direction: column;
`;

export const BotttomRowContainer = styled(RowContainer)``;

export const Title = styled.div`
  color: ${theme.colors.neutral.lightNavy};
`;

const detailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const DayFrozen = styled(detailContainer)``;

export const Grade = styled(detailContainer)``;

export const SampleTitle = styled(Title)``;
export const DayFrozenTitle = styled(Title)``;
export const GradeTitle = styled(Title)``;
