import {
  faApple,
  faCcAmex,
  faCcDinersClub,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcVisa,
  faWhatsapp
} from '@fortawesome/free-brands-svg-icons';
import {
  IconDefinition,
  faArrowRightFromBracket,
  faAward,
  faCalendarCheck as faCalendarCheckSolid,
  faCalendarPlus,
  faCaretDown,
  faCaretUp,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleDot,
  faClipboardUser,
  faDroplet,
  faEye as faEyeSolid,
  faEyeSlash as faEyeSlashSolid,
  faFilm,
  faHeadset,
  faHeartPulse,
  faMagnifyingGlass,
  faPen,
  faPersonBreastfeeding,
  faPhone,
  faRoute,
  faSeedling,
  faSort,
  faTrash,
  faXmark,
  faArrowLeft,
  faArrowRight
} from '@fortawesome/free-solid-svg-icons';
import {
  faComments,
  faEye,
  faEyeSlash,
  faMoneyCheckDollar,
  faStaffSnake,
  faUserGear
} from '@fortawesome/pro-light-svg-icons';
import {
  faBookUser as faBookUserRegular,
  faCakeCandles,
  faCalendar,
  faCalendarCheck as faCalendarCheckRegular,
  faChevronDown,
  faClipboardMedical as faClipboardMedicalRegular,
  faClock,
  faCreditCard,
  faEllipsis,
  faEnvelope as faEnvelopeRegular,
  faFileArrowUp as faFileArrowUpRegular,
  faFileHeart as faFileHeartRegular,
  faFileInvoiceDollar as faFileInvoiceDollarRegular,
  faFileLines,
  faFilePdf,
  faGrid,
  faHandHoldingHeart,
  faHandsHoldingHeart,
  faHouse,
  faLocationDot,
  faMessageLines as faMessageLinesRegular,
  faMessagePlus,
  faPaperPlane,
  faPaperclip,
  faPen as faPenRegular,
  faPeopleArrows,
  faPhone as faPhoneRegular,
  faPills,
  faPlus,
  faPrescription,
  faShieldHeart,
  faSquarePollVertical as faSquarePollVerticalRegular,
  faBold,
  faItalic,
  faUnderline,
  faHashtag,
  faPeriod,
  faArrowUpRightFromSquare,
  faListCheck,
  faLock,
  faLink
} from '@fortawesome/pro-regular-svg-icons';
import {
  faCalendarHeart,
  faCheckCircle,
  faCircleExclamation,
  faCircleInfo,
  faCircleQuestion,
  faClipboardMedical as faClipboardMedicalSolid,
  faEnvelope,
  faFilePdf as faFilePdfSolid,
  faMessageSms,
  faPhoneOffice,
  faWarning
} from '@fortawesome/pro-solid-svg-icons';
import {
  faBookUser,
  faCalendarCheck,
  faClipboardMedical,
  faFileArrowUp,
  faFileHeart,
  faFileInvoiceDollar,
  faGrid as faGridThin,
  faMessageLines,
  faSquarePollVertical
} from '@fortawesome/pro-thin-svg-icons';

interface Icon {
  title: string;
  solid?: IconDefinition;
  regular?: IconDefinition;
  light?: IconDefinition;
  thin?: IconDefinition;
}

export const DEFAULT_ICON_VERSION = 'regular';

export const availableIcons: { [key: string]: Icon } = {
  chevronLeft: {
    solid: faChevronLeft,
    title: 'Go back'
  },
  arrowUpRightFromSquare: {
    regular: faArrowUpRightFromSquare,
    title: 'Document Link'
  },
  chevronRight: {
    solid: faChevronRight,
    title: 'Go forward'
  },
  chevronDown: {
    regular: faChevronDown,
    title: 'Go down'
  },
  chevronUp: {
    solid: faChevronUp,
    title: 'Go up'
  },
  check: {
    solid: faCheck,
    title: 'Checked'
  },
  xMark: {
    solid: faXmark,
    title: 'Error'
  },
  pen: {
    solid: faPen,
    regular: faPenRegular,
    title: 'Edit'
  },
  magnifyingGlass: {
    solid: faMagnifyingGlass,
    title: 'Search'
  },
  headset: {
    solid: faHeadset,
    title: 'Headset'
  },
  userGear: {
    light: faUserGear,
    title: 'User gear'
  },
  handsHoldingHeart: {
    regular: faHandsHoldingHeart,
    title: 'Hands holding heart'
  },
  moneyCheckDollar: {
    light: faMoneyCheckDollar,
    title: 'Money check dollar'
  },
  staffSnake: {
    light: faStaffSnake,
    title: 'Staff snake'
  },
  comments: {
    light: faComments,
    title: 'Comments'
  },
  eye: {
    regular: faEye,
    solid: faEyeSolid,
    title: 'Show'
  },
  eyeSlash: {
    regular: faEyeSlash,
    solid: faEyeSlashSolid,
    title: 'Hide'
  },
  grid: {
    regular: faGrid,
    thin: faGridThin,
    title: 'Grid'
  },
  calendarCheck: {
    regular: faCalendarCheckRegular,
    solid: faCalendarCheckSolid,
    thin: faCalendarCheck,
    title: 'Calendar'
  },
  messageLines: {
    regular: faMessageLinesRegular,
    thin: faMessageLines,
    title: 'Message Lines'
  },
  squarePollVertical: {
    regular: faSquarePollVerticalRegular,
    thin: faSquarePollVertical,
    title: 'Charts'
  },
  bookUser: {
    regular: faBookUserRegular,
    thin: faBookUser,
    title: 'Book User'
  },
  fileArrowUp: {
    regular: faFileArrowUpRegular,
    thin: faFileArrowUp,
    title: 'Upload'
  },
  fileInvoiceDollar: {
    regular: faFileInvoiceDollarRegular,
    thin: faFileInvoiceDollar,
    title: 'Invoice'
  },
  fileHeart: {
    regular: faFileHeartRegular,
    thin: faFileHeart,
    title: 'File Heart'
  },
  clipBoardMedical: {
    regular: faClipboardMedicalRegular,
    thin: faClipboardMedical,
    solid: faClipboardMedicalSolid,
    title: 'Medical Clipboard'
  },
  plus: {
    regular: faPlus,
    title: 'Plus sign'
  },
  ellipsis: {
    regular: faEllipsis,
    title: 'Options icon'
  },
  ccAmex: {
    regular: faCcAmex,
    title: 'Amex credit card icon'
  },
  creditCard: {
    regular: faCreditCard,
    title: 'Credit card icon'
  },
  ccDinersClub: {
    regular: faCcDinersClub,
    title: 'DinersClub credit card icon'
  },
  ccDiscover: {
    regular: faCcDiscover,
    title: 'Discover credit card icon'
  },
  ccJcb: {
    regular: faCcJcb,
    title: 'Jcb credit card icon'
  },
  ccMastercard: {
    regular: faCcMastercard,
    title: 'MasterCard credit card icon'
  },
  ccVisa: {
    regular: faCcVisa,
    title: 'Visa credit card icon'
  },
  apple: {
    regular: faApple,
    title: 'Apple'
  },
  paperclip: {
    regular: faPaperclip,
    title: 'Paperclip'
  },
  fileLines: {
    regular: faFileLines,
    title: 'Document'
  },
  filePdf: {
    regular: faFilePdf,
    solid: faFilePdfSolid,
    title: 'PDF'
  },
  calendar: {
    regular: faCalendar,
    title: 'Calendar'
  },
  envelope: {
    solid: faEnvelope,
    regular: faEnvelopeRegular,
    title: 'Email'
  },
  shieldHeart: {
    regular: faShieldHeart,
    title: 'Shield Heart'
  },
  paperPlane: {
    regular: faPaperPlane,
    title: 'Paper Plane'
  },
  handHoldingHeart: {
    regular: faHandHoldingHeart,
    title: 'Hand Holding Heart'
  },
  peopleArrows: {
    regular: faPeopleArrows,
    title: 'People Arrows'
  },
  circleExclamation: {
    solid: faCircleExclamation,
    title: 'Circle Exclamation'
  },
  checkCircle: {
    solid: faCheckCircle,
    title: 'Check mark Circle'
  },
  circleInfo: {
    solid: faCircleInfo,
    title: 'Circle Info'
  },
  warning: {
    solid: faWarning,
    title: 'Warning'
  },
  arrowRightFromBracket: {
    solid: faArrowRightFromBracket,
    title: 'Logout'
  },
  messagePlus: {
    regular: faMessagePlus,
    title: 'Add message'
  },
  caretDown: {
    solid: faCaretDown,
    title: 'Arrow Down'
  },
  caretUp: {
    solid: faCaretUp,
    title: 'Arrow Up'
  },
  arrowLeft: {
    solid: faArrowLeft,
    title: 'Arrow Left'
  },
  arrowRight: {
    solid: faArrowRight,
    title: 'Arrow Right'
  },
  trash: {
    solid: faTrash,
    title: 'Trash'
  },
  calendarPlus: {
    solid: faCalendarPlus,
    title: 'Calendar'
  },
  film: {
    solid: faFilm,
    title: 'Video'
  },
  cakeCandles: {
    regular: faCakeCandles,
    title: 'Cake Candles'
  },
  prescription: {
    regular: faPrescription,
    title: 'Prescription'
  },
  phone: {
    regular: faPhoneRegular,
    solid: faPhone,
    title: 'Phone'
  },
  house: {
    regular: faHouse,
    title: 'House'
  },
  pills: {
    regular: faPills,
    title: 'Pills'
  },
  clipboardUser: {
    solid: faClipboardUser,
    title: 'Clipboard User'
  },
  heartPulse: {
    solid: faHeartPulse,
    title: 'Heart Pulse'
  },
  award: {
    solid: faAward,
    title: 'Award'
  },
  sort: {
    solid: faSort,
    title: 'Sort'
  },
  calendarHeart: {
    solid: faCalendarHeart,
    title: 'Calendar Heart'
  },
  seedling: {
    solid: faSeedling,
    title: 'Seedling'
  },
  route: {
    solid: faRoute,
    title: 'Route'
  },
  circleDot: {
    solid: faCircleDot,
    title: 'Circle Dot'
  },
  personBreastfeeding: {
    solid: faPersonBreastfeeding,
    title: 'Person Breastfeeding'
  },
  whatsapp: {
    solid: faWhatsapp,
    title: 'Whatsapp'
  },
  messageSms: {
    solid: faMessageSms,
    title: 'SMS Message'
  },
  phoneOffice: {
    solid: faPhoneOffice,
    title: 'Office Phone'
  },
  droplet: {
    solid: faDroplet,
    title: 'Droplet'
  },
  clock: {
    regular: faClock,
    title: 'Clock'
  },
  pin: {
    regular: faLocationDot,
    title: 'Pin'
  },
  circleQuestion: {
    solid: faCircleQuestion,
    title: 'Question Mark'
  },
  bold: {
    regular: faBold,
    title: 'Bold'
  },
  italic: {
    regular: faItalic,
    title: 'Italic'
  },
  underline: {
    regular: faUnderline,
    title: 'Underline'
  },
  hashtag: {
    regular: faHashtag,
    title: 'Hashtag'
  },
  period: {
    regular: faPeriod,
    title: 'Period'
  },
  listCheck: {
    regular: faListCheck,
    title: 'List Check'
  },
  lock: {
    regular: faLock,
    title: 'Lock'
  },
  link: {
    regular: faLink,
    title: 'Link'
  }
} as const;
