import styled from 'styled-components';

export const CyclePaymentButtonContainer = styled.div`
  padding: 16px 0;
`;

export const UnderlinedLink = styled.a`
  text-decoration: underline;
  white-space: nowrap;
`;

export const NoWrapLink = styled.a`
  white-space: nowrap;
`;
