import styled from 'styled-components';

import { Button } from '../ProceedToDashboardButton.styled';
import { Paragraph } from '../Shared.styled';

export const StyledIntroduction = styled.div`
  margin-bottom: 24px;

  @media ${({ theme }) => theme.queries.minDesktop} {
    margin-bottom: 8px;
  }
`;

export const ContactsParagraph = styled(Paragraph)`
  margin-top: 24px;

  @media ${({ theme }) => theme.queries.minDesktop} {
    margin-top: 32px;
  }
`;

export const ReviewAndAcceptButton = styled(Button)`
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.6px;
`;
