import { useQuery } from '@apollo/client';
import moment from 'moment';
import React from 'react';

import { Loader } from 'components/Loader/Loader';
import { usePatient } from 'hooks';
import { utils } from 'kb-shared';
import { QUERY_MEMBERSHIP } from 'kb-shared/graphql/queries';
import { QueryMembershipResponse } from 'kb-shared/graphql/queries_types';
import { appointmentSupportedInPartnerClinics } from 'kb-shared/utilities/appointment.helper';
import { pageUrl } from 'utilities/pageUrl';

import HelpText from '../../../components/HelpText';
import HelpTextButton from '../../../components/HelpTextButton';
import InPersonLabSelectorInput from './InPersonLabSelectorInput';
import { LocationCards } from './LocationCards';
import { CLINICS_QUERY, LABS_QUERY } from './SelectLocationVirtual.graphql';
import {
  Container,
  LabSelectorContainer,
  StyledAdditionalInfoPanel,
  Title,
  UnableToFindContainer,
  ClinicsLoaderContainer
} from './SelectLocationVirtual.styled';
import { ClinicsQuery, LabsQuery, Props } from './SelectLocationVirtual.types';

const ADMIN_MESSAGE = {
  category: 'admin',
  subject: 'New Appointment Question',
  message: ''
};

const { PRINCETON: PRINCETON_LAB_ID } = utils.labs();

export const SelectLocationVirtual = (props: Props) => {
  const { isLoggedIn, patient } = usePatient();
  const {
    selectedLab,
    onSelectLab,
    selectedClinic,
    onSelectClinic,
    onSelectVirtual,
    onContactUsClick,
    directToPartnerClinicSearch,
    appointmentType,
    showVirtual
  } = props;
  const { data: labsData, loading: loadingLabs } = useQuery<LabsQuery>(LABS_QUERY);
  const patientMembership = patient?.patientMembership;

  const { data: clinicsData, loading: loadingClinics } = useQuery<ClinicsQuery>(CLINICS_QUERY, {
    skip: !selectedLab || !appointmentType,
    variables: {
      appointmentTypeId: appointmentType ? parseInt(appointmentType.id) : undefined,
      labId: selectedLab ? parseInt(selectedLab.id) : undefined,
      startDateTime: moment.utc().startOf('month')
    }
  });

  const { data: membershipData } = useQuery<QueryMembershipResponse>(QUERY_MEMBERSHIP, {
    variables: { membershipId: patientMembership?.membershipId },
    skip: !patientMembership?.membershipId
  });

  const clinics = clinicsData?.clinics;
  const clinicsHasAppointments = clinicsData?.hasTimeSlots;

  if (loadingLabs) {
    return <Loader />;
  }

  const showPartnerClinicList =
    directToPartnerClinicSearch &&
    patientMembership &&
    patientMembership.membershipId &&
    patientMembership.employer &&
    !membershipData?.membership.hidePartnerClinics &&
    appointmentType &&
    appointmentSupportedInPartnerClinics(appointmentType.id);

  const labs = labsData?.labs ?? [];
  const shouldShowLabSelector = !selectedLab || labs.length > 1;
  const hasAppointmentsAvailable = clinicsHasAppointments || showVirtual;
  const shouldShowLocationCards = selectedLab && clinics && hasAppointmentsAvailable;
  const showNoTimeslotsMessage =
    selectedLab && (!hasAppointmentsAvailable || !clinics?.length) && !loadingClinics;
  const shouldShowSignatureClinicPromo = selectedLab?.id === PRINCETON_LAB_ID;

  const createMessagesUrl = (category: string, subject: string, messageBody: string) =>
    pageUrl.messages({ categoryName: category, subject: subject, message: messageBody });

  return (
    <Container>
      {shouldShowLabSelector && (
        <LabSelectorContainer>
          <InPersonLabSelectorInput
            labs={labs.filter(lab => !lab.vios)}
            onSelect={onSelectLab}
            selectedLab={selectedLab}
          />
        </LabSelectorContainer>
      )}
      {showNoTimeslotsMessage && (
        <UnableToFindContainer>
          <Title>
            We’re sorry, there are currently no time slots available for this appointment.
            <br />
            Please book a virtual appointment or contact us for more information.
          </Title>
        </UnableToFindContainer>
      )}
      <>
        {loadingClinics && (
          <ClinicsLoaderContainer>
            <Loader />
          </ClinicsLoaderContainer>
        )}
        {shouldShowLocationCards && (
          <LocationCards
            clinics={clinics}
            clinicsHasAppointments={!!clinicsHasAppointments}
            showVirtual={showVirtual}
            selectedClinic={selectedClinic}
            onSelectClinic={onSelectClinic}
            onSelectVirtual={onSelectVirtual}
          />
        )}
      </>
      <>
        {shouldShowSignatureClinicPromo && (
          <StyledAdditionalInfoPanel>
            Kindbody’s signature clinic offers a full suite of services in-house. Patients who seek
            fertility treatment through IVF or egg freezing will visit our Bryant Park location in
            New York City the day of their egg retrieval or embryo transfer.
          </StyledAdditionalInfoPanel>
        )}
      </>
      {isLoggedIn && showPartnerClinicList && (
        <HelpTextButton
          linkText="Find a Partner Clinic"
          text={`Looking for another clinic?`}
          onClick={onContactUsClick}
        />
      )}
      {isLoggedIn && !showPartnerClinicList && (
        <HelpText
          linkText="Contact us"
          text={`Don't see your ${selectedLab ? 'clinic' : 'city'}?`}
          linkUrl={createMessagesUrl(
            ADMIN_MESSAGE.category,
            ADMIN_MESSAGE.subject,
            ADMIN_MESSAGE.message
          )}
        />
      )}
    </Container>
  );
};
