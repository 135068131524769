import React from 'react';

import { Heading, Text } from 'components/v2/Typography';
import { EmbryosCryoedDetail } from 'screens/Results/EggRetrieval/EggRetrieval.types';

import { EmbryosCryoedDetailsCard } from '../EmbryosCryoedDetailsCard/EmbryosCryoedDetailsCard';
import { IconInfo, Table } from './EmbryosCryopreservedDetails.styled';

interface Props {
  embryosCryoedDetails: (EmbryosCryoedDetail | null)[];
  isMobile: boolean;
}

export const EmbryosCryopreservedDetails: React.FC<Props> = ({
  embryosCryoedDetails,
  isMobile
}) => {
  return (
    <>
      <Heading tag="span" styledAs="h3">
        <IconInfo />
        Embryos Cryopreserved
      </Heading>
      {isMobile ? (
        embryosCryoedDetails?.map(
          (embryoDetail, index) =>
            embryoDetail && <EmbryosCryoedDetailsCard key={index} embryoDetail={embryoDetail} />
        )
      ) : (
        <Table>
          <thead>
            <tr>
              <th>
                <Text>Sample</Text>
              </th>
              <th>
                <Text>Day Frozen</Text>
              </th>
              <th>
                <Text>Grade</Text>
              </th>
            </tr>
          </thead>
          <tbody>
            {embryosCryoedDetails?.map(
              (embryoDetail, index) =>
                embryoDetail && (
                  <tr key={index}>
                    <td>{embryoDetail.sample}</td>
                    <td>{embryoDetail.dayFrozen}</td>
                    <td>{embryoDetail.grade}</td>
                  </tr>
                )
            )}
          </tbody>
        </Table>
      )}
    </>
  );
};
