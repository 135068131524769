import { QueryResult } from '@apollo/client';

import { CultureResult } from './EggRetrieval/EggRetrieval.types';

export type ResultCategoryType =
  | 'other'
  | 'diseases_and_infections'
  | 'hormones_and_vitamins'
  | 'blood_profile';

export type ReportType = {
  pdfResults: Array<string>;
  htmlResults: Array<string>;
};

export type ResultType = {
  type: 'afc' | 'amh' | 'progesterone' | string;
  name: string;
  shortName: string;
  fullName: string;
  description: string;
  units: string;
  value: string;
  normalRange: string;
  category: ResultCategoryType;
  categoryDisplayName: string;
  date: string;
  report: ReportType;
  low?: number;
  high?: number;
  recommendation: string;
};

export interface HomeTestingResultType {
  id: string;
  customCode: string;
  labOrderResultValues: Array<ResultType>;
  createdAt: string;
  pdfLink: string;
}

export type PatientOocyte = {
  id: number;
  specimenType: string;
  cryoDate: string;
  cryoDay: number;
  grade: string;
  labLocation: string;
};

export type LoadedResults = {
  labs: Array<ResultType>;
  spermAnalyses: Array<ResultType>;
  afc: Array<ResultType>;
  homeTestingResults: Array<HomeTestingResultType>;
  oocyteInventory: Array<PatientOocyte>;
};

export type Follicle = {
  date: string;
  name: string;
  type: string;
  units: string;
  value: string;
};

export type Lab = {
  category: string;
  categoryDisplayName: string;
  date: string;
  name: string;
  normalRange: string;
  type: string;
  units: string;
  value: string;
};

export type DailyResult = {
  date: string;
  follicles: Array<Follicle>;
  labs: Array<Lab>;
};

export type GenderType = 'male' | 'female';

export type ResponseData = {
  results: LoadedResults;
  dailyResults: Array<DailyResult>;
  cultureResult: CultureResult;
};

export type ResultsQueryProps = QueryResult<ResponseData>;

export type Tab = {
  type: TabTypes;
  title: string;
};

export enum TabTypes {
  FERTILITY = 'fertility',
  STIMULATION = 'stimulation',
  EGG_RETIEVAL = 'eggretrieval',
  AT_HOME = 'at-home',
  EGG_EMBRYO_INVENTORY = 'egg-embryo-inventory'
}
