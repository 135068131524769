import styled from 'styled-components';

export const CCInfoContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  @media ${({ theme }) => theme.queries.minTablet} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const CreditCardDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  gap: 8px;
  flex-wrap: wrap;
  width: 100%;
  background: ${({ theme }) => theme.colors.neutral.lavenderLight};
  padding: 24px 32px;
  border-radius: 16px;

  > button {
    flex: 1;
  }

  & > div:first-child {
    margin-bottom: 4px;
  }

  @media ${({ theme }) => theme.queries.minTablet} {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;

    > button {
      flex: 0 0 auto;
      margin-top: 20px;
    }

    ${CCInfoContainer} + button {
      margin-top: 0;
    }
  }
`;

export const BaseCardContainer = styled.label`
  width: 100%;
`;

export const CardNumberContainer = styled(BaseCardContainer)`
  flex: 3;

  @media ${({ theme }) => theme.queries.maxTablet} {
    flex-basis: 100%;
  }
`;

export const CardInputWrapper = styled.div`
  margin-top: 4px;
  outline: none;
  line-height: 24px;
  background-color: white;
  padding: 20px 24px;
  border: 0;
  width: 100%;
  border-radius: 8px;
  border-style: hidden;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.neutral.lighterNavy};
  box-shadow: ${({ theme }) => theme.shadows.form.level0};
`;

export const ExpirationAndCvcContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;

  @media ${({ theme }) => theme.queries.maxTablet} {
    flex-direction: row;
    gap: 8px;
  }
`;

export const CardExpirationContainer = styled(BaseCardContainer)`
  flex: 1;
`;

export const CardCvcContainer = styled(BaseCardContainer)`
  flex: 1;
`;
