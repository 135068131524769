import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { loadGoogleAuth } from 'kb-redux/user.redux';
import { themes } from 'kb-shared';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { isOnline } from 'utilities/browser';

import { analytics } from '../utilities/analytics';
import { Text } from './v2/Typography';

type Props = {
  context?: 'signin' | 'signup';
};

export const GoogleAuthButton = (props: Props): JSX.Element => {
  const { context = 'signin' } = props;
  const dispatch = useDispatch();
  const [isInitialised, setIsInitialised] = useState<boolean | undefined>(undefined);

  const handleGoogleSignIn = (response: CredentialResponse) => {
    if (!response.clientId || !response.credential) return;

    analytics.track(analytics.EVENTS.GOOGLE_SIGN_IN_SUCCEEDED);
    dispatch(loadGoogleAuth(response.credential));
  };

  const handleGoogleSignInError = () => {
    BugTracker.notify('Google Sign In Failed', 'GoogleAuthButton');
    analytics.track(analytics.EVENTS.GOOGLE_SIGN_IN_FAILED);
  };

  const onButtonClicked = () =>
    context === 'signin'
      ? analytics.track(analytics.EVENTS.GOOGLE_SIGN_IN_STARTED)
      : analytics.track(analytics.EVENTS.SIGN_UP_STARTED_GOOGLE);

  const handleGoogleScriptError = () => {
    if (!isOnline()) return;

    fetch('https://accounts.google.com/gsi/client')
      .then(() => {
        setIsInitialised(false);
        BugTracker.notify('Google SSO Load Error', 'Google SSO script load error');
      })
      .catch(error => {
        setIsInitialised(false);
        BugTracker.notify({
          name: 'Google SSO script fetch error',
          message: error.message,
          stack: error.stack,
          context: {
            endpoint: 'https://accounts.google.com/gsi/client',
            details: error?.details,
            userAgent: navigator.userAgent,
            online: navigator.onLine
          },
          tags: ['fetch', 'api'],
          component: 'GoogleAuthButton.tsx',
          action: 'handleGoogleScriptError'
        });
      });
  };

  const uinitialisedMessage = () => {
    if (isInitialised === undefined) return '';

    return 'Google signin failed to load. Please refresh your browser to try loading it again.';
  };

  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}
      onScriptLoadError={handleGoogleScriptError}
      onScriptLoadSuccess={() => setIsInitialised(true)}
    >
      {isInitialised ? (
        <GoogleLogin
          onSuccess={response => handleGoogleSignIn(response)}
          onError={handleGoogleSignInError}
          theme="outline"
          shape="rectangular"
          type="standard"
          size="large"
          text="continue_with"
          logo_alignment="center"
          locale="en-US"
          width="320"
          context={context}
          click_listener={onButtonClicked}
        />
      ) : (
        <div>
          <Text color={themes.colors.semantic.darkRed}>{uinitialisedMessage()}</Text>
        </div>
      )}
    </GoogleOAuthProvider>
  );
};
