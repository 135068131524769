import React from 'react';

import { Alert } from 'components/v2/Alert/Alert';
import { Text } from 'components/v2/Typography';
import { AppointmentProduct, AppointmentType } from 'kb-shared';
import { isRequiredToBeInTheSameState } from 'kb-shared/utilities/appointment.helper';

type Props = {
  product: AppointmentProduct<AppointmentType> | null;
};

export const LicensedProviderDisclaimer = ({ product }: Props) => {
  const appointmentIsRequiredToBeInTheSameState =
    product?.type === 'appointment' && isRequiredToBeInTheSameState(product.data);

  if (!appointmentIsRequiredToBeInTheSameState) return null;

  return (
    <Alert
      type="info"
      message={
        <>
          <Text fontStyle="semibold" size="md">
            During your virtual visit, you will need to be physically in the state that you selected
            when you signed up for a Kindbody account.
          </Text>{' '}
          <Text size="md">
            Kindbody providers are licensed to practice in certain states, and your appointment may
            need to be rescheduled if you are in a different location than originally specified at
            the time of your visit. Please make sure you are in a quiet place with enough privacy to
            discuss personal details about your health.
          </Text>
        </>
      }
    />
  );
};
