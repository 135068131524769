import React from 'react';

import { CancellingInfo, CancellingInfoTitle } from './CancellationInfo.styled';

export const CancellationInfo = () => (
  <CancellingInfo>
    <CancellingInfoTitle>Need to cancel?</CancellingInfoTitle>

    <p>
      Please alert Kindbody to cancel or reschedule your appointment at least 24 hours prior to the
      scheduled time.
    </p>
  </CancellingInfo>
);
