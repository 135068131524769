import { themes } from 'kb-shared';
import styled from 'styled-components';
const { colors } = themes;

export const AloeButton = styled.button`
  text-transform: uppercase;
  font-size: 14px;
  padding: 15px 24px;
  background-color: ${colors.green.lightGreen};
  border: 1px solid ${colors.green.lightGreen};
  border-radius: 24px;
  cursor: pointer;

  :hover,
  :focus {
    outline: 0;
    opacity: 0.8;

    /* When using the button as a 'a' element */
    font-weight: normal;
    text-decoration: none;
  }
`;

export const DarkButton = styled.button`
  background-color: ${colors.neutral.primaryNavy};
  border-radius: 24px;
  color: ${colors.white};
  cursor: pointer;
  font-size: 14px;
  padding: 15px 24px;
  text-transform: uppercase;
  border: 2px solid transparent;

  :hover,
  :focus {
    background-color: transparent;
    color: ${colors.neutral.primaryNavy};
    border: 2px solid ${colors.neutral.primaryNavy};
    outline: none;
  }
`;

interface OutlineButtonProps {
  small?: boolean;
}

export const OutlineButton = styled.button`
  background: transparent;
  border-radius: 48px;
  border: 2px solid ${themes.colors.neutral.primaryNavy};
  color: ${colors.neutral.primaryNavy};
  cursor: pointer;
  font-size: 14px;
  padding: ${({ small }: OutlineButtonProps) => (small ? '9px 24px;' : '15px 24px')};
  text-transform: uppercase;

  :hover,
  :focus,
  :hover a,
  :focus a {
    background-color: ${({ theme }) => theme.colors.neutral.primaryNavy};
    color: white;
    outline: none;

    path {
      fill: white;
    }
  }
`;

export const YellowButton = styled.button`
  text-transform: uppercase;
  font-size: 14px;
  padding: 15px 24px;
  background-color: ${colors.yellow.primary};
  border: 1px solid ${colors.yellow.primary};
  border-radius: 24px;
  cursor: pointer;

  :hover,
  :focus {
    outline: 0;
    opacity: 0.8;

    /* When using the button as a 'a' element */
    font-weight: normal;
    text-decoration: none;
  }
`;
