import { Text } from 'components/v2/Typography';
import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  margin-bottom: 24px;
`;

export const Th = styled.th`
  background-color: ${({ theme }) => theme.colors.yellow.darkYellow};
  padding: 8px 4px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.navy};
  text-align: left;

  @media ${({ theme }) => theme.queries.minTablet} {
    padding: 24px 16px;
  }
`;

export const Td = styled.td`
  padding: 8px 4px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.navy};
  vertical-align: top;

  @media ${({ theme }) => theme.queries.minTablet} {
    padding: 24px 16px;
  }
`;

export const TableRow = styled.tr`
  &:hover {
    background: ${({ theme }) => theme.colors.neutral.lavenderLight};
  }
`;

export const ThText = styled(Text).attrs({ fontStyle: 'semibold' })`
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
`;
export const TdText = styled(Text)`
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  word-break: break-word;
`;
