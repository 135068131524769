import { Heading } from 'components/v2/Typography';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${({ theme }) => theme.queries.minTablet} {
    padding: 24px;
  }
`;

export const TabHeading = styled(Heading).attrs({ styledAs: 'h2', tag: 'div' })`
  color: ${({ theme }) => theme.colors.neutral.lavenderWhite};
`;
