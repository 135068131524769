import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as Checkmark } from 'assets/icons/checkmark-green.svg';
import { Loader } from 'components/Loader/Loader';
import { Button as DocumentLinkButton } from 'components/v2/Buttons/Button';
import { Button } from 'components/v2/Buttons/Button';
import { Checkbox } from 'components/v2/Inputs';
import { Text } from 'components/v2/Typography';
import theme from 'kb-shared/theme';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { PATIENT_CONSENT_SUBMIT } from 'screens/InformedConsent/Components/ConsentAcceptAction/ConsentAcceptAction.graphql';
import { COMPLETE_FORM } from 'screens/InformedConsent/InformedConsent.graphql';
import { CompleteFormResponseData } from 'screens/InformedConsent/InformedConsent.types';

import { STATUS } from '../../InterstitialModal.graphql';
import { Consent as ConsentData, StatusProp } from '../../InterstitialModal.types';
import { Paragraph, Title, Description } from '../Shared.styled';
import { ConsentsCompleted } from './ConsentsCompleted';
import {
  Consent,
  ConsentsSection,
  ContentSection,
  CustomAbsoluteCentered,
  DocumentLinkContainer,
  DocumentLinkIcon,
  DocumentLinkSection,
  RedExclamationIcon
} from './ConsentsTab.styled';

interface CreatePatientConsentResponseData {
  createPatientConsent: {
    id: string;
    patientConsent: {
      id: string;
    };
  };
}

export const ConsentsTab = ({ status }: StatusProp) => {
  const history = useHistory();
  const { hasRequiredConsents, requiredConsents } = status;

  const [checkedConsents, setCheckedConsents] = useState<{ [key: string]: boolean }>({});
  const [isButtonClicked, setIsButtonClicked] = useState<boolean>(false);

  const [completeForm, { loading }] = useMutation<
    CompleteFormResponseData,
    { patientConsentId: number; versionUuid: string | null }
  >(COMPLETE_FORM, {
    refetchQueries: [{ query: STATUS }],
    onError: error => BugTracker.notify(error, 'InterstitialCompleteConsentFormResponseData')
  });

  const [createPatientConsent, { loading: loadingCreateConsent }] = useMutation<
    CreatePatientConsentResponseData,
    { consentId: number }
  >(PATIENT_CONSENT_SUBMIT, {
    onError: error => BugTracker.notify(error, 'InterstitialCreatePatientConsentResponseData')
  });

  const finishCheckedConsents = async (consentsToFinish: { [key: string]: boolean }) => {
    const consentsCheckedIdList = Object.keys(consentsToFinish).filter(id => consentsToFinish[id]);

    try {
      for (const consentToFinishId of consentsCheckedIdList) {
        const selectedConsent = requiredConsents.find(
          c => c.id === consentToFinishId && !c.completed
        );

        if (!selectedConsent?.linkUrl) return;

        const consentId = parseInt(selectedConsent.linkUrl.split('/').pop() || '');
        const versionUuid = selectedConsent.versionUuid;

        await createPatientConsent({
          variables: { consentId }
        });

        await completeForm({
          variables: {
            patientConsentId: Number(consentToFinishId),
            versionUuid: versionUuid
          }
        });
      }
    } catch (error) {
      BugTracker.notify(error, 'InterstitialCompleteConsents');
    } finally {
      setIsButtonClicked(false);
    }
  };

  if (hasRequiredConsents === true) return <ConsentsCompleted />;

  const consents = [...requiredConsents].sort((a, b) => b.id.localeCompare(a.id));

  const updateConsentsChecked = (id: string) => {
    setCheckedConsents(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  const selectedConsentsCount = requiredConsents.filter(
    rc => checkedConsents[rc.id] && !rc.completed
  ).length;

  const buttonText = `I Have Reviewed And I Accept Selected Consents ${
    selectedConsentsCount ? `(${selectedConsentsCount})` : ''
  }`;

  return (
    <ConsentsSection>
      {(loading || loadingCreateConsent || isButtonClicked) && (
        <CustomAbsoluteCentered>
          <Loader />
        </CustomAbsoluteCentered>
      )}
      <ContentSection>
        <Text tag="span" size="lg" fontStyle="semibold">
          I am at least 18 years of age and I have read and accept:
        </Text>
      </ContentSection>
      <ContentSection>
        {consents.map((consent: ConsentData) => (
          <Consent key={consent.linkUrl}>
            <Checkbox
              id={consent.linkUrl}
              disabled={consent.completed}
              isChecked={consent.completed || checkedConsents[consent?.id]}
              onChange={() => updateConsentsChecked(consent?.id)}
              isCheckedAndReviewed={consent.completed}
            />
            <Description>
              <Title>{consent.name}</Title>
              <Paragraph>{consent.description}</Paragraph>
            </Description>
            {consent.completed ? (
              <Checkmark className="checkmark" />
            ) : (
              <DocumentLinkSection>
                <DocumentLinkContainer>
                  <DocumentLinkButton
                    category="secondary"
                    onClick={() => {
                      history.push(consent.linkUrl);
                    }}
                    leftIcon={<DocumentLinkIcon />}
                  />
                </DocumentLinkContainer>
              </DocumentLinkSection>
            )}
          </Consent>
        ))}
      </ContentSection>
      {hasRequiredConsents ? (
        <ContentSection>
          <Checkmark className="checkmark" />
          <Text tag="span" size="sm" fontStyle="medium" color={theme.colors.green.darkestGreen}>
            All consents reviewed and accepted!
          </Text>
        </ContentSection>
      ) : (
        <ContentSection>
          <RedExclamationIcon />
          <Text tag="span" size="sm" fontStyle="medium" color={theme.colors.semantic.darkRed}>
            {' '}
            All consents must be reviewed and accepted!
          </Text>
        </ContentSection>
      )}
      <Button
        label={buttonText}
        category="primary-dark"
        isDisabled={selectedConsentsCount === 0 || isButtonClicked || hasRequiredConsents}
        onClick={() => {
          setIsButtonClicked(true);
          finishCheckedConsents(checkedConsents);
        }}
        fullWidth
      />
    </ConsentsSection>
  );
};
