import React from 'react';

import { Heading } from 'components/v2/Typography';
import { getTimeZone } from 'hooks/getTimeZone';
import { Appointment, AppointmentType } from 'kb-shared';

import { HeaderCalendarIcon, AppointmentTime, AppointmentName } from './AppointmentDetails.styled';

interface Props {
  appointment: Appointment;
  appointmentType: AppointmentType;
  isFuture: boolean;
}

export const AppointmentDetails = ({ appointment, appointmentType, isFuture }: Props) => {
  const { formatTime } = getTimeZone(appointment.timeZone, appointmentType);

  return (
    <>
      <HeaderCalendarIcon $isFuture={isFuture} />
      <AppointmentTime>
        <Heading styledAs="h1" tag="div" noMargin>
          {formatTime(appointment.startTime, 'dddd, ')}
        </Heading>
        <Heading styledAs="h1" tag="div" noMargin>
          {formatTime(appointment.startTime, 'MMMM Do')}
        </Heading>
        <Heading styledAs="h1" tag="div" noMargin>
          {formatTime(appointment.startTime, 'h:mm A z')}
        </Heading>
      </AppointmentTime>
      <AppointmentName>{appointmentType.name}</AppointmentName>
    </>
  );
};
