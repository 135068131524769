import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { BugTracker } from 'kb-shared/utilities/bugTracker';

import { DEFAULT_ICON_VERSION, availableIcons } from './Icons.constants';

type IconName = keyof typeof availableIcons;
export type IconProps = {
  className?: string;
  type?: IconType;
  iconTitle?: string;
  width?: number | string;
  height?: number | string;
};
export type IconType = 'solid' | 'regular' | 'light' | 'thin';

/**
 * Helper function to create a KB icon component
 * Takes care of className prop or any other future props that need to be passed to all icon components
 * @param {string} type the name of the icon(see Icons.constants.ts for available icons)
 * @returns {JSX.Element}
 */
export const createIcon = (name: IconName) => {
  // function is required  because of displayName
  return function Icon({
    className,
    type = DEFAULT_ICON_VERSION,
    iconTitle,
    width,
    height
  }: IconProps = {}) {
    const icon = availableIcons[name][type];

    if (!icon) {
      BugTracker.notify('Icon not found!');
      return <></>;
    }

    return (
      <FontAwesomeIcon
        icon={icon}
        title={iconTitle ? iconTitle : availableIcons[name].title}
        aria-label={iconTitle ? iconTitle : availableIcons[name].title}
        className={className}
        width={width}
        height={height}
      />
    );
  };
};

export type IconComponent = ReturnType<typeof createIcon>;
