import * as React from 'react';

import { Button } from 'components/Buttons/Button';
import { themes } from 'kb-shared';
import styled from 'styled-components';

import { ReactComponent as BloodworkIcon } from '../../assets/icons/assessment-bloodwork.svg';
import { ReactComponent as ExamIcon } from '../../assets/icons/assessment-exam.svg';
import { ReactComponent as HistoryIcon } from '../../assets/icons/assessment-history.svg';
import { ReactComponent as UltrasoundIcon } from '../../assets/icons/assessment-ultrasound.svg';
import { NextStepItem } from './NextStepItem';
import { SectionTitle } from './styled';

const NextStepText = styled.div`
  font-size: 16px;
  color: white;
  margin-top: 14px;
  margin-bottom: 28px;
`;

const NextStepDetailsContainer = styled.div`
  background-color: white;
  padding-top: 12px;
  padding-bottom: 12px;
`;

const NextStepItemDivider = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${themes.colors.neutral.primaryNavy};
  margin-top: 12px;
  margin-bottom: 12px;
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
`;

const NextStepSection = (props: { onScheduleAssessment: () => void }) => {
  return (
    <React.Fragment>
      <SectionTitle>Your next step</SectionTitle>
      <NextStepText>
        Schedule an in-clinic full fertility assessment, which includes an ultrasound of your
        ovaries, a fertility hormone test, a medical history review, and a personalized plan:
      </NextStepText>
      <NextStepDetailsContainer>
        <NextStepItem
          icon={UltrasoundIcon}
          title={'Ultrasound to view follicles on your ovaries'}
          description={
            'Follicles are counted to determine the number of eggs available in a given month'
          }
        />
        <NextStepItemDivider />
        <NextStepItem
          icon={BloodworkIcon}
          title={'Blood test for fertility-related hormones'}
          description={
            'Hormones involved in the ovulation process are measured to estimate your current ovarian reserve'
          }
        />
        <NextStepItemDivider />
        <NextStepItem
          icon={ExamIcon}
          title={'Physical exam & medical history review'}
          description={
            'Factors including age, lifestyle, medical history, and genetic background are used to create your personalized plan'
          }
        />
        <NextStepItemDivider />
        <NextStepItem
          icon={HistoryIcon}
          title={'Review results + customized plan'}
          description={
            'Meet with a fertility specialist to discuss your results and your family planning goals. You’ll work together to come up with a personalized plan that works for you.'
          }
        />
      </NextStepDetailsContainer>
      <ButtonWrapper>
        <Button label={'Schedule an assessment'} onClick={props.onScheduleAssessment} />
      </ButtonWrapper>
    </React.Fragment>
  );
};

export default NextStepSection;
