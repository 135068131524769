import { Heading, Text } from 'components/v2/Typography';
import styled from 'styled-components';

export const UpcomingHeading = styled(Heading)`
  margin-bottom: 0.5em;
`;

export const Header = styled.div`
  display: flex;
  margin-bottom: 0.5em;
`;

export const Sliders = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap: 0.5em;
  align-items: flex-start;
  margin-right: 40px;
`;

export const AppointmentName = styled(Text)`
  text-align: center;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.neutral.lavender};
  box-shadow: ${({ theme }) => theme.shadows.card.level0};
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  width: 100%;
  padding: 0.5em 1em;
  display: flex;
  justify-content: center;
`;

export const ProviderInfo = styled.div`
  margin-top: 0.5em;
`;

export const YellowBox = styled.div`
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.yellow.lighterYellow};
  display: flex;
  padding: 16px 24px 24px 24px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  box-shadow: ${({ theme }) => theme.shadows.card.level0};
  text-align: center;
  margin-bottom: 12px;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
`;

export const PhoneConsult = styled.div`
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  gap: 8px;
  margin-bottom: 12px;

  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.yellow.lighterYellow};
  box-shadow: ${({ theme }) => theme.shadows.card.level0};
`;

export const AppointmentButtons = styled.div`
  margin-top: 24px;

  @media ${({ theme }) => theme.queries.minDesktop} {
    margin-top: 32px;
  }
`;
