import { themes } from 'kb-shared';
import styled from 'styled-components';

export const Text = styled.div`
  font-weight: bolder;
  font-size: 24px;
`;

export const EmbryosCryopreservedDetailsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media screen and (min-width: ${themes.breakpoints.largePhonePortrait}) {
    gap: 16px;
  }
`;
