import React from 'react';

import kindbody from 'assets/logos/kindbody.svg';
import Modal from 'components/Modal/Modal';

import { AppleModalContainer } from './AppleDisclaimerModal.styled';
import { AppleModalProps } from './AppleDisclaimerModal.types';

const AppleDisclaimerModal = (props: AppleModalProps) => {
  return (
    <Modal
      open={props.showModal}
      onRequestClose={props.handleClose}
      label="Apple sign-in disclaimer"
      addPadding={true}
    >
      <AppleModalContainer className="modal__wrapper">
        <img className="modal__logo" src={kindbody} alt="KindBody Logo" />
        <h2 className="modal__title h2">Disclaimer</h2>
        <p>
          If you choose to anonymize your email for sign up via Apple SSO, you will not be able to
          use another method to sign in later on.
        </p>
        <button className="modal__button button type__button" onClick={props.handleClose}>
          Sign Up
        </button>
      </AppleModalContainer>
    </Modal>
  );
};

export default AppleDisclaimerModal;
