import { PaymentPlan } from 'screens/Invoices/types/invoice';
import styled from 'styled-components';

export const CardList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
`;

export const CardListItem = styled.li`
  width: 100%;
  padding: 16px 24px;
  margin: 12px 0;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardContentRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CardContentRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 1em;
`;

export const CardContentRowItem = styled.div`
  min-width: 146px;

  & > div:nth-child(2) {
    margin-top: 0.5em;
  }
`;

export const CardContentRowItemValue = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > div:nth-child(2) {
    margin-left: 0.5em;
  }
`;

export const LabelsColumn = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 140px;

  > span {
    margin-bottom: 2px;
  }
`;

export const LabelHolder = styled.div<{ $status?: PaymentPlan['status'] }>`
  text-align: center;
  background: ${({ theme, $status }) => {
    if ($status === 'In Progress') return theme.colors.green.lightestGreen;
    if ($status === 'Completed') return theme.colors.semantic.lightPink;
    return theme.colors.neutral.lavenderLight;
  }};
  color: ${({ theme, $status }) => {
    if ($status === 'In Progress') return theme.colors.green.darkestGreen;
    if ($status === 'Completed') return theme.colors.semantic.darkRed;
    return theme.colors.neutral.primaryNavy;
  }};
  padding: 6px 16px;
  border-radius: 4px;
  width: 100%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
    width: 100%;
  }
`;
