import React from 'react';

import { Appointment, AppointmentType, Patient } from 'kb-shared';

export const HiddenPatientIdTag = ({ patient }: { patient?: Patient }): JSX.Element => (
  <div hidden id="hidden-user-id">
    {patient?.id}
  </div>
);

export const HiddenAppointmentTags = ({
  appointment,
  appointmentType
}: {
  appointment: Appointment;
  appointmentType: AppointmentType;
}): JSX.Element => (
  <>
    <div hidden className="appt-confirm-page">
      true
    </div>
    <div hidden className="hidden-appt-id">
      {appointment.id}
    </div>
    <div hidden className="hidden-price">
      {appointmentType.rateCents / 100}
    </div>
    <div hidden className="hidden-appt-type-id">
      {appointmentType.id}
    </div>
  </>
);
