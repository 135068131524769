import { Heading, Text } from 'components/v2/Typography';
import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 32px;
`;

export const Title = styled(Heading).attrs({ tag: 'h2', styledAs: 'h4' })`
  justify-content: center;
  width: 100%;
`;

export const DateTimeLine = styled(Heading).attrs({ tag: 'div', styledAs: 'h4', noMargin: true })`
  justify-content: center;
`;

export const AppointmentBlockContainer = styled.div`
  flex: 1;
`;

export const AdditionalInfo = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media ${({ theme }) => theme.queries.minTablet} {
    flex-direction: row;
  }
`;

export const AdditionalInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  margin-bottom: 16px;

  @media ${({ theme }) => theme.queries.minTablet} {
    margin-bottom: 0;

    &:not(:first-child) {
      margin-left: 16px;
      border-left: 1px solid ${({ theme }) => theme.colors.neutral.lavender};
      padding-left: 16px;
    }
  }
`;

export const AdditionalInfoItemTitle = styled(Heading).attrs({
  tag: 'div',
  styledAs: 'h6',
  noMargin: true
})`
  text-transform: uppercase;
`;
export const AdditionalInfoItemValue = styled(Text).attrs({ fontStyle: 'bold' })``;
