import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Loader } from 'components/Loader/Loader';
import { usePageAnalytics } from 'hooks/usePageAnalytics';
import { useQueryParams } from 'hooks/useQueryParams';
import { analytics } from 'utilities/analytics';
import { showErrorToast } from 'utilities/notificationUtils';
import { useBreakpoints } from 'utilities/useBreakpoints';

import { Conversation } from './components/Conversation/Conversation';
import { ConversationList } from './components/ConversationList/ConversationList';
import { EmployerBenefitReimbursementModal } from './components/EmployerBenefitReimbursementModal/EmployerBenefitReimbursementModal';
import { EmptyConversationList } from './components/EmptyConversationList/EmptyConversationList';
import { CATEGORY_SLUG } from './Messages.constants';
import { useConversations } from './Messages.hook';
import { Container, Header } from './Messages.styled';
import { MessageParams } from './Messages.type';

export const Messages = () => {
  const { conversations, loading, error } = useConversations();
  const { categoryName, conversationId } = useParams<MessageParams>();
  const queryString = useQueryParams();
  const { isDesktop } = useBreakpoints();

  useEffect(() => {
    if (!error) return;
    showErrorToast("We're sorry something went wrong. Please try again.");
  }, [error]);

  usePageAnalytics(analytics.PAGES.MESSAGES);

  if (error) return <></>;

  if (loading) return <Loader absoluteCentered />;

  const shouldOpenEmployerBenefitReimbursementModal =
    categoryName === CATEGORY_SLUG.EMPLOYER_BENEFIT_REIMBURSEMENT &&
    !conversationId &&
    !queryString.get('subject');

  if (isDesktop) {
    return (
      <Container>
        <Header>Messages</Header>
        <ConversationList conversations={conversations} />
        {shouldOpenEmployerBenefitReimbursementModal && <EmployerBenefitReimbursementModal />}
        <Conversation />
      </Container>
    );
  }

  if (!conversations.length && !categoryName) {
    return (
      <EmptyConversationList
        description="Your chat list is currently empty. Ask us a question, and we'll promptly connect you with
    the right contact."
      />
    );
  }

  return (
    <Container>
      <Header>Messages</Header>
      {!categoryName && <ConversationList conversations={conversations} />}
      {shouldOpenEmployerBenefitReimbursementModal && <EmployerBenefitReimbursementModal />}
      <Conversation />
    </Container>
  );
};
