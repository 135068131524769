import { AppointmentProduct, AppointmentType } from 'kb-shared';

export const getAppointmentName = (
  product: AppointmentProduct<AppointmentType> | undefined,
  selectedAppointmentTypes: Array<AppointmentType>,
  appointmentType?: AppointmentType
) => {
  if (product?.type === 'appointment') {
    return product.data.name;
  }
  if (!appointmentType && selectedAppointmentTypes) {
    return selectedAppointmentTypes[0]?.name;
  }

  return '';
};
