import theme from 'kb-shared/theme';
import styled from 'styled-components';

export const OutlineIndent = styled.div`
  background-color: ${theme.colors.neutral.lavenderWhite};
  padding: 16px 12px;

  @media ${({ theme }) => theme.queries.minTablet} {
    // 16 in the end adds 0.16 opacity to the color provided
    box-shadow: 0px 12px 64px -32px ${({ theme }) => theme.colors.neutral.primaryNavy}16;
    border-radius: 24px;
    padding: 32px;
  }
`;

export const ButtonContainer = styled.div``;

export const TooltipTrigger = styled.div``;

export const InvitePartnerButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding-top: 4px;
  padding-bottom: 4px;

  > div {
    width: calc(50% - 8px);
  }
`;
