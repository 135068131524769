import { ApolloError } from '@apollo/client';
import { DocumentNode } from 'graphql';

import { AppointmentProduct, ValidPromo } from '../types';
import { Maybe } from '../types';
import client from './client';
import { VALIDATE_APPOINTMENT_DISCOUNT } from './queries';

/**
 * Helper function for querying for promo/discount codes
 */

interface CheckPromoVariables {
  discountCode: string;
  appointmentTypeId?: Maybe<number>;
  membershipId?: Maybe<number>;
}

export function checkPromoCode(code: string, product: AppointmentProduct): Promise<ValidPromo> {
  let query: DocumentNode;
  let variables: CheckPromoVariables = {
    appointmentTypeId: null,
    discountCode: code
  };

  if (product.type === 'appointment') {
    variables = {
      ...variables,
      appointmentTypeId: parseInt(product.data.id)
    };
    query = VALIDATE_APPOINTMENT_DISCOUNT as DocumentNode;
  }

  return new Promise((resolve, reject) => {
    client
      .query({
        query,
        variables
      })
      .then((result: { data: { validateDiscount: Record<string, unknown> } }) => {
        const promo = result.data.validateDiscount;
        const validPromo = {
          ...promo,
          code: code
        };
        // @ts-ignore
        resolve(validPromo);
      })
      .catch((err: ApolloError) => {
        const errMessage = err.graphQLErrors.length && err.graphQLErrors[0].message;
        reject(errMessage || 'Sorry, this code does not work');
      });
  });
}
