import { ApolloError, gql, useQuery } from '@apollo/client';

import { BugTracker } from 'kb-shared/utilities/bugTracker';

import { PaymentPlan } from '../types/invoice';

export const PAYMENT_PLANS_QUERY = gql`
  query paymentPlans {
    paymentPlans {
      status
      appointmentId
      totalAmount
      collectedAmount
      description
      startDate
      billingFrequency
      totalAutomatedPaymentsCount
      payments {
        id
        date
        amountCents
        processed
      }
    }
  }
`;

export const usePaymentPlans = (): UsePaymentPlansResponse => {
  const { loading, data, error, refetch } = useQuery<PaymentPlansResponse>(PAYMENT_PLANS_QUERY, {
    onError: error => BugTracker.notify(error, 'Payment Plans Fetch Failed')
  });

  const paymentPlans = data?.paymentPlans || [];

  return {
    loading,
    error,
    paymentPlans,
    refetch
  };
};

interface UsePaymentPlansResponse {
  loading: boolean;
  error: ApolloError | undefined;
  paymentPlans: PaymentPlan[];
  refetch: () => {};
}

export interface PaymentPlansResponse {
  paymentPlans: PaymentPlan[];
}
