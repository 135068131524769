import React, { useState } from 'react';

import { ChevronRight, ChevronUp } from 'assets/icons/Icons';
import { Heading } from 'components/v2/Typography';
import { Payment } from 'screens/Invoices/types/payment';

import {
  PaymentPlanPaymentsContainer,
  PaymentPlanPaymentsTableContainer,
  PaymentPlanPaymentsToggle,
  PaymentPlanPaymentsToggleContainer,
  PaymentPlanPaymentsToggleLabel
} from './PaymentPlanPayments.styled';
import { PaymentPlanPaymentsTable } from './PaymentPlanPaymentsTable/PaymentPlanPaymentsTable';

export function PaymentPlanPayments({ payments }: { payments: Payment[] }) {
  const [detailsDisplayed, setDetailsDisplayed] = useState(false);

  if (!payments?.length) return null;

  return (
    <PaymentPlanPaymentsContainer>
      {detailsDisplayed && (
        <PaymentPlanPaymentsTableContainer>
          <div>
            <Heading noMargin tag="h4">
              Upcoming Payments
            </Heading>
          </div>
          <div>
            <PaymentPlanPaymentsTable payments={payments} />
          </div>
        </PaymentPlanPaymentsTableContainer>
      )}
      <PaymentPlanPaymentsToggleContainer>
        <PaymentPlanPaymentsToggle onClick={() => setDetailsDisplayed(!detailsDisplayed)}>
          <PaymentPlanPaymentsToggleLabel>
            {detailsToggleText(detailsDisplayed)}
          </PaymentPlanPaymentsToggleLabel>
          {detailsDisplayed ? <ChevronUp type="solid" /> : <ChevronRight type="solid" />}
        </PaymentPlanPaymentsToggle>
      </PaymentPlanPaymentsToggleContainer>
    </PaymentPlanPaymentsContainer>
  );
}

const detailsToggleText = (detailsDisplayed: boolean) =>
  detailsDisplayed ? 'Hide Details' : 'Show Details';
