import React from 'react';

import { Container } from './Initials.styles';
import { Props } from './Initials.types';

const computeInitials = (name: string) => {
  const [firstName, lastName] = name.split(' ');

  if (!lastName) return firstName[0];

  return firstName[0] + lastName[0];
};

export const Initials = ({ name, size = 'sm' }: Props) => (
  <Container $size={size}>{computeInitials(name)}</Container>
);
