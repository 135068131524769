import React from 'react';

import CheckboxListItem from 'components/CheckBoxList/CheckBoxListItem';
import { Loader } from 'components/Loader/Loader';
import { useDropDownOptions } from 'hooks/useDropDownOptions/useDropDownOptions';

type CancellationReasonOptionsProps = {
  onSelect: (selectedValue: string) => void;
  isSelected: (selectedValue: string) => boolean;
};

export const CancellationReasonOptions: React.FC<CancellationReasonOptionsProps> = ({
  onSelect,
  isSelected
}) => {
  const { options, loading } = useDropDownOptions({
    key: 'appointment.cancellation_reason.pp'
  });

  if (loading) return <Loader container />;

  return (
    <>
      {options?.map(option => (
        <CheckboxListItem
          key={option.value}
          onSelect={onSelect}
          isSelected={isSelected}
          value={option.value}
        >
          {option.label}
        </CheckboxListItem>
      ))}
    </>
  );
};
