import React from 'react';

import { Initials } from 'components/Initials/Initials';

import { Provider, ProviderInfo, ProviderLabel, ProviderName } from './ProviderInfo.styled';

interface Props {
  providerName?: string;
}

export const ProviderDetails = ({ providerName }: Props) => {
  if (!providerName) return null;

  return (
    <ProviderInfo>
      <ProviderLabel>Provider:</ProviderLabel>
      <Provider>
        <Initials name={providerName} size="sm-md" />
        <ProviderName>{providerName}</ProviderName>
      </Provider>
    </ProviderInfo>
  );
};
