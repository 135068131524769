import React from 'react';

import {
  Container,
  Description,
  HelperText,
  HelperTextContainer,
  Label,
  RequiredIndicator
} from './FormControl.styled';
import { Props } from './FormControl.types';

export const FormControl = ({
  className,
  label,
  required = false,
  status = 'default',
  labelId,
  inputId,
  helperTextId,
  helperText,
  description,
  children,
  labelBg,
  hideDescription,
  hideHelperText
}: Props) => {
  return (
    <Container status={status} className={className}>
      <Label id={labelId} htmlFor={inputId} status={status} bg={labelBg}>
        <div>
          {label}
          {required && <RequiredIndicator>*</RequiredIndicator>}
        </div>
        {!hideDescription && description && (
          <div>
            <Description>{description}</Description>
          </div>
        )}
      </Label>

      {children}

      {!hideHelperText && (
        <HelperTextContainer>
          {helperText && <HelperText id={helperTextId}>{helperText}</HelperText>}
        </HelperTextContainer>
      )}
    </Container>
  );
};
