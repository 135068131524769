import { gql } from '@apollo/client';

export const QUERY_LABS = gql`
  query labs {
    labs {
      id
      name
    }
  }
`;

export const QUERY_REFERRING_PROVIDERS = gql`
  query referringProviders {
    referringProviders {
      id
      name
      marketId
    }
  }
`;
