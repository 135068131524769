import React from 'react';

import { TextInput } from '../../Inputs';
import { FormControl, getFormControlIds } from '../FormControl';
import { Props } from './TextField.types';

export const TextField = ({
  className,
  status = 'default',
  type = 'text',
  label,
  id,
  required,
  helperText,
  description,
  placeholder,
  spellCheck,
  autoComplete,
  value,
  onChange,
  onBlur,
  onKeyPress,
  onFocus,
  labelBg,
  rightIcon,
  onRightIconClick,
  postfix,
  hideDescription,
  hideHelperText,
  hideArrowsForNumberInput,
  max,
  step,
  fullWidth
}: Props) => {
  const ids = getFormControlIds(id);

  return (
    <FormControl
      className={className}
      status={status}
      label={label}
      required={required}
      helperText={helperText}
      description={description}
      labelId={ids.label}
      inputId={ids.input}
      helperTextId={ids.helperText}
      labelBg={labelBg}
      hideDescription={hideDescription}
      hideHelperText={hideHelperText}
    >
      <TextInput
        type={type}
        id={ids.input}
        helperTextId={ids.helperText}
        labelId={ids.label}
        status={status}
        placeholder={placeholder}
        spellCheck={spellCheck}
        autoComplete={autoComplete}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        onFocus={onFocus}
        rightIcon={rightIcon}
        onRightIconClick={onRightIconClick}
        postfix={postfix}
        hideArrowsForNumberInput={hideArrowsForNumberInput}
        max={max}
        step={step}
        fullWidth={fullWidth}
      />
    </FormControl>
  );
};
