export const EVENTS = {
  EGG_COUNT_CALCULATOR_USED: 'Egg Count Calculator Used',
  PATIENT_PORTAL_BOOKING_APPOINTMENT_PAYMENT_LOADED:
    'Patient Portal - Booking Appointment - Payment Loaded',
  EMAIL_SIGN_UP_STARTED: 'Email Sign Up Started',
  GOOGLE_SIGN_UP_STARTED: 'Google Sign Up Started',
  SIGN_UP_STARTED_EMAIL: 'Sign Up Started - Email',
  SIGN_UP_STARTED_GOOGLE: 'Sign Up Started - Google',
  APPLE_SIGN_UP_STARTED: 'Apple Sign Up Started',
  APPLE_SIGN_IN_STARTED: 'Apple Sign In Started',
  MEDTRONIC_SIGN_UP_STARTED: 'Medtronic Sign Up Started',
  DISNEY_SIGN_UP_STARTED: 'Disney Sign Up Started',
  MFA_CODE_SUBMITTED: 'MFA Code Submitted',
  EMAIL_SIGN_IN_STARTED: 'Email Sign In Started',
  MFA_CODE_REQUESTED: 'MFA Code Requested',
  FORGOT_PASSWORD_CLICKED: 'Forgot Password Clicked',
  PATIENT_PORTAL_PAY_CYCLE_MODAL_PAYMENT_LOADED:
    'Patient Portal - Pay Cycle Modal - Payment Loaded',
  PATIENT_PORTAL_PAY_CYCLE_MODAL_PAYMENT_SUCCESSFUL:
    'Patient Portal - Pay Cycle Modal - Payment Successful',
  PATIENT_PORTAL_INVOICE_PAYMENT_SUCCESSFUL: 'Patient Portal - Invoice - Payment Successful',
  PATIENT_PORTAL_INVOICE_PAYMENT_MODAL_LOADED: 'Patient Portal - Invoice - Payment Modal Loaded',
  PATIENT_CONSENT_SIGN_ATTEMPTED: 'Patient Consent sign attempted',
  PATIENT_CONSENT_SIGNED: 'Patient Consent signed',
  PATIENT_CONSENT_VIEWED: 'Patient Consent viewed',
  SIGNED_CONSENT_VIEWED: 'Signed Consent Viewed',
  SIGNED_TREATMENT_PLAN_VIEWED: 'Signed Treatment Plan Viewed',
  DOCUMENT_TAB_USED: 'Document Tab Used',
  APPOINTMENT_QUIZ_RESPONDED: 'Appointment Quiz Responded',
  APPOINTMENT_SELECTED: 'Appointment Selected',
  APPLE_SIGN_IN_SUCCEEDED: 'Apple Sign In Succeeded',
  GOOGLE_SIGN_IN_SUCCEEDED: 'Google Sign In Succeeded',
  GOOGLE_SIGN_IN_FAILED: 'Google Sign In Failed',
  GOOGLE_SIGN_IN_STARTED: 'Google Sign In Started',
  LIVE_BIRTH_CALCULATOR_USED: 'Live Birth Calculator Used',
  ID_FILE_TOO_BIG: 'ID File Too Big',
  INSURANCE_FILE_TOO_BIG: 'Insurance File Too Big',
  PROFILE_IMAGE_FILE_TOO_BIG: 'Profile Image File Too Big',
  PROFILE_IMAGE_UPLOAD_FAILED: 'Profile Image Upload Failed',
  PROFILE_IMAGE_UPLOAD_SUCCEEDED: 'Profile Image Upload Succeeded',
  ACCOUNT_VERIFICATION_FAILED: 'Account Verification Failed',
  ACCOUNT_VERIFICATION_CODE_SUBMITTED: 'Account Verification Code Submitted',
  ACCOUNT_VERIFICATION_CODE_RESUBMITTED: 'Account Verification Code Resubmitted',
  ACCOUNT_VERIFICATION_SUCCEEDED: 'Account Verification Succeeded',
  ACCOUNT_ACTIVATION_EMAIL_SUBMITTED: 'Account Activation Email Submitted',
  ACCOUNT_ACTIVATION_EMAIL_SUBMIT_SUCCEEDED: 'Account Activation Email Submit Succeeded',
  ACCOUNT_ACTIVATION_EMAIL_SUBMIT_FAILED: 'Account Activation Email Submit Failed',
  PASSWORD_RESET_VERIFICATION_CODE_REQUESTED: 'Password Reset Verification Code Requested',
  PASSWORD_RESET_VERIFICATION_CODE_REQUEST_SUCCEEDED:
    'Password Reset Verification Code Request Succeeded',
  PASSWORD_RESET_VERIFICATION_CODE_REQUEST_FAILED:
    'Password Reset Verification Code Request Failed',
  ACCOUNT_ACTIVATION_NEW_PASSWORD_SUBMITTED: 'Account Activation New Password Submitted',
  NEW_PASSWORD_SUBMITTED: 'New Password Submitted',
  ACCOUNT_ACTIVATION_NEW_PASSWORD_SUBMIT_SUCCEEDED:
    'Account Activation New Password Submit Succeeded',
  NEW_PASSWORD_SUBMIT_SUCCEEDED: 'New Password Submit Succeeded',
  ACCOUNT_ACTIVATION_NEW_PASSWORD_SUBMIT_FAILED: 'Account Activation New Password Submit Failed',
  NEW_PASSWORD_SUBMIT_FAILED: 'New Password Submit Failed',
  PARTNER_CLINIC_SEARCH_STARTED: 'Partner Clinic Search Started',
  MEMBERSHIP_SELECTED: 'Membership Selected',
  LAB_SELECTED: 'Lab Selected',
  CLINIC_SELECTED: 'Clinic Selected',
  TIME_SLOT_SELECTED: 'Time Slot Selected',
  APPOINTMENT_BOOKING_SUCCEEDED: 'Appointment Booking Succeeded',
  SKIP_TO_SIGN_UP: 'Skip to Sign Up',
  PARTNER_CLINIC_SEARCH_SUCCESSFUL: 'Partner Clinic Search Successful',
  PARTNER_CLINIC_SEARCH_ERROR: 'Partner Clinic Search Error',
  PARTNER_CLINIC_SEARCHED: 'Partner Clinic Searched',
  SECURE_MESSAGE_PAGE: 'Secure Message Page',
  PARTNER_CLINIC_SELECTED: 'Partner Clinic Selected',
  APPOINTMENT_BOOKING_FAILED: 'Appointment Booking Failed',
  OATH_SIGN_UP_STARTED: 'Oath Sign Up Started',
  PATIENT_HAS_CONSENTS_TO_SIGN: 'Patient has Consents to sign',
  PATIENT_CONSENT_SELECTED: 'Patient Consent selected',
  ACCOUNT_ACTIVATION_STARTED: 'Account Activation Started',
  WALMART_REGISTRATION_SUCCEEDED: 'Walmart Registration Succeeded',
  PROMO_CODE_FAILED: 'Promo Code Failed',
  PROMO_CODE_SUCCEEDED: 'Promo Code Succeeded',
  PATIENT_LOGGED_IN: 'Patient Logged In',
  EMAIL_SIGN_IN_SUCCEEDED: 'Email Sign In Succeeded',
  PARTNER_INVITE_SENT: 'Partner Invite Sent',
  PATIENT_DECLARED_PARTNER_STATUS: 'Patient Declared Partner Status',
  PATIENT_AUTOMATICALLY_LOGGED_OUT: 'Patient Automatically Logged Out',
  APPOINTMENT_CTA_CLICKED: 'Appointment CTA Clicked',
  AHT_RESULTS_VIEWED: 'AHT Results Viewed - TRACK',
  AHT_LABS_VIEWED: 'AHT Labs Viewed',
  EMU_ACCOUNT_ACTIVATED: 'emu account activated',
  BOOKING_NAVIGATION: 'booking-navigation',
  APPOINTMENT_BOOKING_STARTED: 'Appointment Booking Started',
  MY_JOURNEY_VIEW_MORE: 'My Journey View More Details',
  MEDICATIONS_MORE_QUESTIONS: 'Medications More Questions Required',
  MEDICATIONS_GOT_IT: 'Medications Confirmed',
  RESULTS_LEARN_MORE: 'Learn More about Your Results',
  ACCOUNT_BASIC_INFO_ENTERED: 'Email Sign Up Details Added',
  ACCOUNT_SIGN_UP_COMPLETED: 'Account Sign Up Completed',
  SIGN_UP_TASKS_COMPLETED: 'Sign Up Tasks Completed',
  TODO_SELECTED: 'Todo Selected',
  EGG_COUNT_TAB_SELECTED: 'Egg Count Selected',
  AMH_LEVEL_SUBMITTED: 'AMH Level Submitted',
  LIVE_BIRTH_TAB_SELECTED: 'Live Birth Selected',
  NUMBER_OF_EGGS_ENTERED: 'Number of Eggs Entered',
  LAST_PERIOD_STARTED: 'Last Period Started',
  PERIOD_CYCLE_LENGTH_ENTERED: 'Cycle Length',
  NEXT_PERIOD_FORECASTED: 'Next Period Forecasted',
  PERIOD_TRACKER_CANCELED: 'Period Tracker Canceled',
  PROFILE_DETAILS_UPDATE_STARTED: 'My Profile Details Update Started',
  PREGNANCY_INDICATED: 'Pregnancy Indicated',
  ACTIVATION_BENEFITS_STARTED: 'Activation Of Benefits Started',
  EMPLOYER_NOT_OFFERING_BENEFITS: 'Employer Not Offering Benefits',
  NPV_APPOINTMENT_BOOKING_SUCCEEDED: 'NPV Appointment Booking Succeeded',
  QUIZ_SIGN_UP_TO_ACTIVATE: 'Quiz - Sign Up To Activate',
  CREATE_ACCOUNT_SIGN_UP_TO_ACTIVATE: 'Create Account - Sign Up To Activate',
  INSURANCE_DETAILS_COMPLETED: 'Insurance Details Completed'
} as const;

export const PAGES = {
  MESSAGES: 'Messages',
  APPOINTMENTS: 'Appointments',
  CONFIRMATION: 'Confirmation',
  EMAIL_SIGN_UP: 'Email Sign Up',
  GOOGLE_SIGN_UP: 'Google Sign Up',
  APPLE_SIGN_UP: 'Apple Sign Up',
  MEDTRONIC_SIGN_UP: 'Medtronic Sign Up',
  DISNEY_SIGN_UP: 'Disney Sign Up',
  RESOURCES: 'Resources',
  KIND360: 'Kind360',
  JOURNEY: 'Journey',
  LOG_IN_PAGE: 'Log In Page',
  INVOICES: 'Invoices',
  DOCUMENTS: 'Documents',
  APPOINTMENT_RECOMMENDATION: 'Appointment Recommendation',
  APPOINTMENT_BOOKING_QUIZ: 'Appointment Booking Quiz',
  VERIFICATION: 'Verification',
  ENTER_INSURANCE_INFORMATION: 'Enter insurance information',
  CONFIRM_PURCHASE: 'Confirm Purchase',
  RESULTS: 'Results',
  HOME: 'Home',
  PATIENT_INTAKE: '/patient-intake'
} as const;

export type EventName = typeof EVENTS[keyof typeof EVENTS];
type PageName = typeof PAGES[keyof typeof PAGES];

export type AnalyticsUtil = {
  identify: (userId: string, traits?: { [key: string]: any }) => void;
  track: (event: EventName, properties?: { [key: string]: any }) => void;
  page: (name: PageName, properties?: { [key: string]: any }) => void;
};

export const analytics: AnalyticsUtil & { EVENTS: typeof EVENTS; PAGES: typeof PAGES } = {
  EVENTS,
  PAGES,
  identify: (userId, traits = {}) => {
    window.analytics?.identify(userId, traits);
  },
  track: (event, properties = {}) => {
    window.analytics?.track(event, properties);
  },
  page: (name, properties = {}) => {
    window.analytics?.page(name, properties);
  }
};
