import React from 'react';

import Footer from 'components/Footer/Footer';
import styled from 'styled-components';

import QuoteImage, { WIDTH as QUOTE_IMAGE_WIDTH } from '../components/QuoteImage';

// TODO: Remove this wrapper when all layouts will have the same structure
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  main,
  footer {
    @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
      margin-left: ${QUOTE_IMAGE_WIDTH};
    }
  }

  main {
    margin-bottom: 95px;
  }
`;

const Content = styled.main`
  margin-top: 2em;
`;

const QuoteImageWrapper = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    display: block;
  }
`;

const SSOLoginLayout: React.FC = ({ children }) => (
  <Wrapper>
    <QuoteImageWrapper>
      <QuoteImage />
    </QuoteImageWrapper>

    <Content>{children}</Content>
    <Footer />
  </Wrapper>
);

export default SSOLoginLayout;
