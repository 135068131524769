import styled, { css } from 'styled-components';

export const Button = styled.button<{ $width?: string; $upperCase?: boolean }>`
  width: ${({ $width }) => ($width === 'sm' ? '264px' : '100%')};
  background: ${({ theme }) => theme.colors.yellow.primary};
  height: 64px;
  border-radius: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 1px;
  display: block;
  align-items: center;
  text-align: center;
  text-transform: ${({ $upperCase }) => ($upperCase ? 'uppercase' : 'none')};
  justify-content: center;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};

  ${props =>
    props.disabled &&
    css`
      background: ${({ theme }) => theme.colors.neutral.lighterNavy};
      color: ${({ theme }) => theme.colors.neutral.navy};
      cursor: not-allowed;
    `}
`;
