import React from 'react';

import { CalendarPlus, CircleInfo } from 'assets/icons/Icons';
import { Initials } from 'components/Initials/Initials';
import { Button } from 'components/v2/Buttons/Button';
import { Modal } from 'components/v2/Modal/Modal';
import { Heading, Text } from 'components/v2/Typography';
import { getTimeZone } from 'hooks';
import { Appointment, theme } from 'kb-shared';
import {
  PHONE_CALL_DISCLAIMER,
  shouldDisplayProvider,
  VIRTUAL_APPOINTMENT_LICENSING_DISCLAIMER
} from 'kb-shared/utilities/appointment.helper';
import { getLabInfo } from 'kb-shared/utilities/kindbody_contacts';
import { inFuture } from 'kb-shared/utilities/momentHelpers';
import { IconContainer } from 'screens/Dashboard/Widgets/Widgets.styled';
import { generateCalendarLink } from 'utilities/appointmentProduct';
import getAppointmentChannel from 'utilities/getAppointmentChannel';
import { useBreakpoints } from 'utilities/useBreakpoints';

import {
  AppointmentDetails,
  AppointmentDetailsButton,
  AppointmentDetailsContainer,
  AppointmentDetailsDisclaimerContainer,
  AppointmentDetailsDisclaimerIcon,
  AppointmentDetailsDisclaimerText,
  AppointmentDetailsLocation,
  AppointmentDetailsLocationActionButtons,
  AppointmentDetailsLocationContainer,
  AppointmentDetailsResponsiveText,
  AppointmentDetailsWhen,
  AppointmentDetailsWhenWhoContainer,
  AppointmentDetailsWho
} from './AppointmentDetailModal.styled';
import { formatAddress } from './AppointmentDetailModal.utils';

export const AppointmentDetailModal: React.FC<{
  open: boolean;
  onClose: () => void;
  appointment: Appointment;
  onRescheduledClick?: (appointment: Appointment) => void;
}> = ({ open, onClose, appointment, onRescheduledClick }) => {
  if (!appointment) onClose();

  const { isMobile } = useBreakpoints();
  const labInfo = getLabInfo(appointment.location.lab.id);
  const { formatTime } = getTimeZone(appointment.timeZone, appointment.appointmentType);
  const formattedAddress = formatAddress(appointment.location.address);

  const addToCalendar = () =>
    window.open(
      generateCalendarLink(
        {
          appointmentType: appointment.appointmentType,
          startTime: appointment.startTime,
          endTime: appointment.endTime,
          location: appointment.location
        },
        appointment.videoLink ?? appointment.appointmentType.videoLink
      )
    );

  const goToVideoLink = () => {
    const videoLink = appointment.videoLink ?? appointment.appointmentType.videoLink;

    if (!videoLink) return;

    window.open(videoLink, '_blank');
  };

  const isFuture = inFuture(appointment.endTime);
  const { isPhoneCall, isVideoCall } = getAppointmentChannel(appointment.appointmentType);
  const showDisclaimerText = isFuture && (isPhoneCall || isVideoCall);

  return (
    <Modal title="Your appointment is booked!" open={open} onCancel={onClose} maxWidth={720}>
      <AppointmentDetailsContainer>
        <AppointmentDetailsWhenWhoContainer>
          <AppointmentDetailsWhen>
            {!isMobile && (
              <Heading tag="div" styledAs="h3" noMargin>
                See you on
              </Heading>
            )}
            <Heading tag="div" styledAs="h2" noMargin color={theme.colors.neutral.navy}>
              {formatTime(appointment.startTime, 'dddd, MMM Do [at] hh:mma z')}
            </Heading>
          </AppointmentDetailsWhen>
          {shouldDisplayProvider(appointment) && appointment.providerName && (
            <AppointmentDetailsWho>
              <Initials name={appointment.providerName} />
              <Heading tag="div" styledAs="h4" noMargin>
                {appointment.providerName}
              </Heading>
            </AppointmentDetailsWho>
          )}
        </AppointmentDetailsWhenWhoContainer>
        <AppointmentDetails>
          <Heading tag="div" styledAs="h4" noMargin>
            {appointment.appointmentType.name}
          </Heading>
        </AppointmentDetails>
        <AppointmentDetailsLocationContainer>
          <AppointmentDetailsLocation>
            <Text fontStyle="semibold" size="md">
              {appointment.location.name}
            </Text>
            <Text
              multilineEllipses={2}
              fontStyle="regular"
              size="md"
              color={theme.colors.neutral.lightNavy}
            >
              {formattedAddress?.streetAddress || appointment.location.address}
            </Text>
            {formattedAddress && (
              <Text fontStyle="regular" size="md" color={theme.colors.neutral.lightNavy}>
                {formattedAddress?.cityStateZip}
              </Text>
            )}
            <AppointmentDetailsResponsiveText>
              <Text fontStyle="semibold" size="md" color={theme.colors.neutral.navy}>
                Phone:
              </Text>
              <Text fontStyle="regular" size="md" color={theme.colors.neutral.lightNavy}>
                <a href={`tel:${labInfo.number}`}>{labInfo.number}</a>
              </Text>
            </AppointmentDetailsResponsiveText>
            <AppointmentDetailsResponsiveText>
              <Text fontStyle="semibold" size="md" color={theme.colors.neutral.navy}>
                Email:
              </Text>
              <Text
                multilineEllipses={1}
                fontStyle="regular"
                size="md"
                color={theme.colors.neutral.lightNavy}
              >
                <a href={`mailto:${labInfo.email}`}>{labInfo.email}</a>
              </Text>
            </AppointmentDetailsResponsiveText>
          </AppointmentDetailsLocation>
          <AppointmentDetailsLocationActionButtons>
            {isFuture && (
              <>
                {isVideoCall && (
                  <Button size="md" label="JOIN VIDEO CONSULT" onClick={goToVideoLink} />
                )}
                <Button
                  size="md"
                  category="secondary"
                  label="Add to Calendar"
                  fullWidth
                  leftIcon={<CalendarPlus type="solid" />}
                  onClick={addToCalendar}
                />
                <Button
                  size="md"
                  label="Reschedule"
                  category="danger"
                  fullWidth
                  onClick={() => onRescheduledClick?.(appointment)}
                />
              </>
            )}
          </AppointmentDetailsLocationActionButtons>
        </AppointmentDetailsLocationContainer>
        {showDisclaimerText && (
          <AppointmentDetailsDisclaimerContainer>
            <AppointmentDetailsDisclaimerIcon>
              <IconContainer
                $iconWidth={12}
                $iconHeight={12}
                $height={16}
                $width={16}
                $color={theme.colors.neutral.primaryNavy}
              >
                <CircleInfo type="solid" />
              </IconContainer>
            </AppointmentDetailsDisclaimerIcon>
            <AppointmentDetailsDisclaimerText>
              {isVideoCall && (
                <Text fontStyle="regular" size="sm" color={theme.colors.neutral.lightNavy}>
                  {VIRTUAL_APPOINTMENT_LICENSING_DISCLAIMER}
                </Text>
              )}
              {isPhoneCall && (
                <Text fontStyle="regular" size="sm" color={theme.colors.neutral.lightNavy}>
                  {PHONE_CALL_DISCLAIMER}
                </Text>
              )}
            </AppointmentDetailsDisclaimerText>
          </AppointmentDetailsDisclaimerContainer>
        )}
      </AppointmentDetailsContainer>
      {((isMobile && !isFuture) || !isMobile) && (
        <AppointmentDetailsButton>
          <Button onClick={onClose} label="Got it!" size="lg" category="secondary" fullWidth />
        </AppointmentDetailsButton>
      )}
    </Modal>
  );
};
