import { gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';

import { Invoice } from 'screens/Invoices/types/invoice';

import { showErrorToast } from '../../../utilities/notificationUtils';
import { InvoiceDetails, LineItemNextPayer } from '../types/invoices-details';

export const INVOICE_DETAILS_QUERY = gql`
  query invoiceDetails($invoiceIdentifier: String!) {
    invoiceDetails(invoiceIdentifier: $invoiceIdentifier) {
      info {
        number
        clinicName
        isAzInvoice
      }
      dates {
        invoiceDate
        datesOfService
      }
      invoice {
        id
        azEncounterIdentifier
        balance
        dateOfInvoice
        marketId
        paidViaPpApp
        service
      }
      amounts {
        adjustmentsCents
        remainingBalanceCents
        performedServicesTotalCents
        originalRateCents
        convertedCreditsCents
        insuranceAdjustmentsCents
      }
      discount {
        cents
        reason
      }
      serviceProvidedBy {
        address
        name
        taxId
      }
      appliedCredits {
        cents
      }
      payments {
        cents
        date
        reason
        type
      }
      performedServices {
        cents
        nextPayer
        name
        description
        cptCode
        diagnosisCodes
      }
    }
    patient {
      id
      firstName
      lastName
      address {
        id
        address1
        address2
        city
        state
        zipcode
      }
    }
  }
`;

const convertCentsToAmount = (cents: string | null) => {
  if (!cents) {
    return 0;
  }

  try {
    return parseInt(cents) / 100;
  } catch (e) {
    console.error(e);
    return 0;
  }
};

const useInvoiceDetails = (invoiceIdentifier: string): InvoiceDetailsResponse => {
  const { loading, data, error, refetch } = useQuery<
    InvoiceDetailsQueryResponse,
    InvoiceDetailsQueryVariables
  >(INVOICE_DETAILS_QUERY, {
    variables: { invoiceIdentifier }
  });

  useEffect(() => {
    if (error) {
      console.error(error);
      showErrorToast("We're sorry, something went wrong");
    }
  }, [error]);

  if (!data) {
    return { loading };
  }

  return {
    details: {
      organizationName: data.invoiceDetails.serviceProvidedBy.name,
      organizationAddress: data.invoiceDetails.serviceProvidedBy.address,
      organizationTaxId: data.invoiceDetails.serviceProvidedBy.taxId,
      clinicLocation: data.invoiceDetails.info.clinicName,
      isAzInvoice: data.invoiceDetails.info.isAzInvoice,
      invoiceNumber: data.invoiceDetails.info.number,
      invoiceDate: data.invoiceDetails.dates.invoiceDate,
      datesOfService: data.invoiceDetails.dates.datesOfService ?? [],
      terms: 'Due on Receipt',
      lineItemsTotalAmount: convertCentsToAmount(
        data.invoiceDetails.amounts.performedServicesTotalCents
      ),
      invoiceAdjustmentsAmount: convertCentsToAmount(data.invoiceDetails.amounts.adjustmentsCents),
      balanceDueAmount: convertCentsToAmount(data.invoiceDetails.amounts.remainingBalanceCents),
      kindbodyRateAmount: convertCentsToAmount(data.invoiceDetails.amounts.originalRateCents),
      convertedCreditsAmount: convertCentsToAmount(
        data.invoiceDetails.amounts.convertedCreditsCents
      ),
      insuranceAdjustmentsAmount: convertCentsToAmount(
        data.invoiceDetails.amounts.insuranceAdjustmentsCents
      ),
      discount: data.invoiceDetails.discount && {
        amount: convertCentsToAmount(data.invoiceDetails.discount.cents),
        reason: data.invoiceDetails.discount.reason
      },
      billTo: {
        patientName: `${data.patient.firstName} ${data.patient.lastName}`,
        addressLine1: data.patient.address?.address1 ?? null,
        addressLine2: data.patient.address?.address2 ?? null,
        city: data.patient.address?.city ?? null,
        state: data.patient.address?.state ?? null,
        zipCode: data.patient.address?.zipcode ?? null
      },
      appliedCredits:
        data.invoiceDetails.appliedCredits?.map(credit => ({
          amount: convertCentsToAmount(credit.cents)
        })) ?? [],
      payments:
        data.invoiceDetails.payments?.map(payment => ({
          amount: convertCentsToAmount(payment.cents),
          reason: payment.reason,
          date: payment.date,
          type: payment.type
        })) ?? [],
      lineItems:
        data.invoiceDetails.performedServices?.map(service => ({
          amount: convertCentsToAmount(service.cents),
          name: service.name,
          nextPayer: service.nextPayer,
          description: service.description,
          cptCode: service.cptCode,
          diagnosisCodes: service.diagnosisCode
        })) ?? [],
      invoice: {
        ...data.invoiceDetails.invoice
      }
    },
    loading,
    refetch
  };
};

interface InvoiceDetailsResponse {
  details?: InvoiceDetails;
  loading: boolean;
  refetch?: () => {};
}

interface InvoiceDetailsQueryResponse {
  invoiceDetails: {
    info: {
      number: string;
      clinicName: string;
      isAzInvoice: boolean;
    };
    invoice: Invoice;
    dates: {
      invoiceDate: string;
      datesOfService: string[] | null;
    };
    discount: {
      cents: string;
      reason: string;
    } | null;
    amounts: {
      adjustmentsCents: string;
      remainingBalanceCents: string;
      performedServicesTotalCents: string;
      originalRateCents: string;
      convertedCreditsCents: string;
      insuranceAdjustmentsCents: string | null;
    };
    serviceProvidedBy: {
      address: string;
      name: string;
      taxId: string;
    };
    appliedCredits:
      | {
          cents: string;
        }[]
      | null;
    payments:
      | {
          cents: string;
          date: string;
          reason: string;
          type: string;
        }[]
      | null;
    performedServices:
      | {
          cents: string;
          name: string;
          nextPayer: LineItemNextPayer;
          description: string;
          cptCode: string;
          diagnosisCode: string;
        }[]
      | null;
  };
  patient: {
    firstName: string;
    lastName: string;
    address: {
      address1: string | null;
      address2: string | null;
      city: string | null;
      state: string | null;
      zipcode: string | null;
    } | null;
  };
}

interface InvoiceDetailsQueryVariables {
  invoiceIdentifier: string;
}

export default useInvoiceDetails;
