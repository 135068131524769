import { Page } from 'react-pdf';

import styled from 'styled-components';

export const ContentContainer = styled.div`
  width: 100%;
  max-width: 310px;

  text-align: center;

  @media ${({ theme }) => theme.queries.minTablet} {
    max-width: none;
  }

  @media ${({ theme }) => theme.queries.minDesktop} {
    max-width: 600px;
  }

  @media ${({ theme }) => theme.queries.minLargeDesktop} {
    max-width: 800px;
  }
`;

export const CustomPage = styled(Page)`
  > canvas {
    max-width: 100%;
  }
`;

export const PdfErrorContainer = styled.div`
  text-align: center;
`;
