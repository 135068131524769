import styled, { css } from 'styled-components';

import { Size } from './Initials.types';

const smCss = css`
  height: 24px;
  width: 24px;
  min-width: 24px;
  font-size: 12px;
`;

const mdCss = css`
  height: 48px;
  width: 48px;
  min-width: 48px;
  font-size: 18px;
`;

const smMdCss = css`
  height: 32px;
  width: 32px;
  min-width: 32px;
  font-size: 14px;
`;

const getSizeStyles = ($size: Size) => {
  switch ($size) {
    case 'sm':
      return smCss;
    case 'sm-md':
      return smMdCss;
    case 'md':
      return mdCss;
    default:
      return smCss;
  }
};

export const Container = styled.div<{ $size: Size }>`
  align-items: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  color: ${({ theme }) => theme.colors.white};
  display: inline-flex;
  justify-content: center;

  ${({ $size }) => getSizeStyles($size)}
`;
