import React, { ReactNode } from 'react';

import { XMark } from 'assets/icons/Icons';
import { Heading } from 'components/v2/Typography';

import { CloseIconContainer, CustomReactModal, Header } from './Modal.styled';

interface Props {
  title: string;
  open: boolean;
  children: ReactNode;
  onCancel: () => void;
  maxWidth?: number;
  closable?: boolean;
}

const DEFAULT_MAX_WIDTH = 800;

export const Modal = ({
  title,
  open,
  children,
  onCancel,
  maxWidth = DEFAULT_MAX_WIDTH,
  closable = true
}: Props) => {
  return (
    <CustomReactModal open={open} maxWidth={maxWidth}>
      <Header>
        <Heading tag="div" styledAs="h2" noMargin>
          {title}
        </Heading>

        {closable && (
          <CloseIconContainer onClick={onCancel}>
            <XMark type="solid" />
          </CloseIconContainer>
        )}
      </Header>

      {children}
    </CustomReactModal>
  );
};
