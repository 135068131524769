import React from 'react';

import { AloeButton, YellowButton } from 'components/Buttons/Buttons';
import ArrowDown from 'components/SVG/ArrowDown';
import { themes } from 'kb-shared';
import styled from 'styled-components';
import { analytics, EventName } from 'utilities/analytics';

import { ReactComponent as Cart } from '../../assets/icons/results-cart.svg';
import { ReactComponent as Messaging } from '../../assets/icons/results-messaging.svg';
interface GetRecommendationProps {
  name: string;
  value: number;
}

export const getRecommendation = ({ name, value }: GetRecommendationProps) => {
  switch (name) {
    case 'Vitamin D': {
      // >30 = no vitamin d recommendation / do not add this section.
      // ]25-30] ng/ml = 2000 IU
      // [20-25] ng/ml = 4000 IU
      // <20 ng/ml = 6000 IU
      if (value > 30) return null;
      const vitaminDDose = value < 20 ? 6000 : value <= 25 ? 4000 : 2000;
      return {
        // eslint-disable-next-line react/display-name
        text: () => (
          <Text>
            <b>Looks like your Vitamin D levels could use a boost!</b>
            <br />
            Based on your results, we recommend taking {vitaminDDose} IU / day for 8 weeks to get
            your levels up.
          </Text>
        ),
        buy: 'https://kindbody.com/shop/vitamin-d3/',
        track: 'Shop Vitamin D' as EventName
      };
    }
    default:
      return null;
  }
};

interface ResultRecommendationProps {
  text?: Function;
  buy?: string;
  track?: EventName;
  onTalkToUs?: Function;
}

const ResultRecommendation = ({ text, buy, track, onTalkToUs }: ResultRecommendationProps) => (
  <Container>
    <ArrowDown fill={themes.colors.neutral.navy} />
    {text && text()}
    <ButtonGroup>
      {buy && (
        <Buy
          onClick={() => {
            if (track) {
              analytics.track(track);
            }
            window.location.href = buy;
          }}
        >
          <Cart />
          Buy now
        </Buy>
      )}
      {onTalkToUs && (
        <TalkToUs onClick={() => onTalkToUs()}>
          <Messaging />
          Talk to us
        </TalkToUs>
      )}
    </ButtonGroup>
  </Container>
);

const Container = styled.div`
  width: 100%;
  min-height: 104px;
  margin: 0 16px;
  padding: 48px 16px 28px;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: ${themes.colors.neutral.primaryNavy};

  & > svg {
    position: absolute;
    left: 16px;
    top: 0;
  }

  @media screen and (min-width: ${themes.breakpoints.raw.lg}px) {
    padding: 40px 24px 28px;
    margin: 0 20px;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
  }
`;
const Text = styled.div`
  padding: 8px 0;
  margin: 0;

  color: ${themes.colors.white};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  white-space: pre-wrap;
`;
const ButtonGroup = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  & > :not(:first-child) {
    margin-top: 16px;
  }

  @media (min-width: ${themes.breakpoints.raw.md}px) {
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    & > :not(:first-child) {
      margin-top: 0;
      margin-left: 16px;
    }
  }

  @media (min-width: ${themes.breakpoints.raw.lg}px) {
    margin-top: 0;
    margin-left: 24px;
    justify-content: flex-end;

    & > :not(:first-child) {
      margin-left: 24px;
    }
  }
`;
const Buy = styled(AloeButton)`
  padding: 0 16px;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;

  & > svg {
    margin-right: 8px;
  }
`;
const TalkToUs = styled(YellowButton)`
  padding: 0 16px;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;

  & > svg {
    margin-right: 8px;
  }
`;

export default ResultRecommendation;
