import { Button } from 'components/v2/Buttons/Button';
import { TextField } from 'components/v2/Form';
import styled from 'styled-components';

export const PromoCodeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding-top: 32px;

  @media ${({ theme }) => theme.queries.minTablet} {
    flex-direction: row;
    align-items: center;
    gap: 20px;
    padding-top: 0;
  }
`;

export const StretchTextField = styled(TextField)`
  width: 100%;

  @media ${({ theme }) => theme.queries.minTablet} {
    width: auto;
    flex: 1;
  }
`;

export const ApplyButton = styled(Button).attrs({ type: 'button', size: 'lg' })`
  width: 100%;

  @media ${({ theme }) => theme.queries.minTablet} {
    width: auto;
    margin-top: 8px;
  }
`;
