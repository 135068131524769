import React from 'react';

import { Alert } from 'components/v2/Alert/Alert';
import { Text } from 'components/v2/Typography';
import { AppointmentProduct, AppointmentType } from 'kb-shared';

type Props = {
  hasEmployer: boolean;
  product: AppointmentProduct<AppointmentType> | null;
};

export const CoachingBenefitsDisclaimer = ({ hasEmployer, product }: Props) => {
  const categoryName = product?.type === 'appointment' ? product?.data?.category?.name : undefined;

  if (hasEmployer || categoryName !== 'Coaching') return null;

  return (
    <Alert
      type="info"
      message={
        <Text>
          If you have employer benefits with us, please call us at 855-KND-BODY or email
          navigator@kindbody.com to book your appointment and apply any applicable benefit
          discounts.
        </Text>
      }
    />
  );
};
