import { Lock } from 'assets/icons/Icons';
import { Button } from 'components/v2/Buttons/Button';
import { Text } from 'components/v2/Typography';
import styled from 'styled-components';

export const InstructionText = styled(Text).attrs({ tag: 'p', size: 'sm', fontStyle: 'semibold' })`
  color: ${({ theme }) => theme.colors.neutral.lightNavy};

  @media ${({ theme }) => theme.queries.minDesktop} {
    width: 672px;
  }
`;

export const WidgetList = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  align-items: stretch;

  gap: 8px;
  margin-top: 16px;

  @media ${({ theme }) => theme.queries.minTablet} {
    grid-template-columns: repeat(2, minmax(50%, 1fr));
  }
`;

export const WidgetId = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  gap: 8px;
  color: ${({ theme }) => theme.colors.neutral.lightNavy};
`;

export const WidgetTitle = styled(Text).attrs({ tag: 'span', fontStyle: 'semibold', size: 'md' })`
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
`;

export const WidgetButton = styled.button.attrs({ type: 'button' })<{ $shownToUser: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.neutral.lightNavy};
  background-color: ${({ theme }) => theme.colors.neutral.lavenderWhite};
  box-shadow: ${({ theme }) => theme.shadows.card.level1};

  &:disabled {
    background-color: ${({ theme }) => theme.colors.neutral.lavenderLight};
    cursor: not-allowed;
  }

  &:not(:disabled):hover {
    box-shadow: ${({ theme }) => theme.shadows.card.level2};
  }

  ${({ $shownToUser, theme }) =>
    !$shownToUser &&
    `
    background-color: ${theme.colors.neutral.lavenderLight};
    color: ${theme.colors.neutral.lighterNavy};
    box-shadow: none;

    ${WidgetId}, ${WidgetTitle} {
      color: ${theme.colors.neutral.lighterNavy};
    }
  `}
`;

export const ActionButton = styled(Button)``;

export const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;

  @media ${({ theme }) => theme.queries.minTablet} {
    flex-direction: row;
    justify-content: space-between;

    ${ActionButton} {
      flex: 1;
    }
  }
`;

export const LockIcon = styled(Lock)`
  color: ${({ theme }) => theme.colors.neutral.lighterNavy};
  margin-right: 2px;
`;
