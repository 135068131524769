import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { VerificationCode } from 'components/auth/VerificationCode/VerificationCode';
import { LinkButton } from 'components/v2/Buttons/LinkButton/LinkButton';
import { Text } from 'components/v2/Typography';
import * as redux from 'kb-redux';
import { signUpPatient } from 'kb-redux/user.redux';
import { LoginError, SignUpProps, PatientState, AppleAuthError } from 'kb-shared';
import { SignUpButtons } from 'screens/Book/components/SignUpButtons/SignUpButtons';
import { ForgotPasswordForm } from 'screens/ForgotPassword/ForgotPasswordForm';
import { FormType } from 'screens/ForgotPassword/ForgotPasswordForm.types';
import { LoginForm } from 'screens/Login/LoginForm/LoginForm';
import { getAppleSignUpDisabledMessage } from 'screens/Login/LoginForm/LoginForm.utils';
import { SignUpScreen } from 'screens/SignUp/SignUpScreen';
import { analytics } from 'utilities/analytics';
import { showErrorToast, showSuccessToast } from 'utilities/notificationUtils';
import { SIGNUP_TYPE } from 'utilities/userUtil.types';

import { Container, Content, SignInLinkContainer } from './CreateAccount.styled';
import { Props, SubStep } from './CreateAccount.types';

const { login, clearGoogleAuthError, resetConfirmationState } = redux.patient;

const CreateAccount = (props: Props) => {
  const {
    mobileLayout,
    lab,
    userConfirmationState,
    patient,
    isLoggedIn,
    loading,
    createAccountSubstep,
    signUpError,
    googleAuthError,
    appleAuthError,
    onAuthentication,
    signUpUser,
    clearGoogleError,
    login,
    onChangeSubstep,
    resetConfirmationState,
    signInLinkIsVisible
  } = props;
  const [currentStep, setCurrentStep] = useState<string>('choose_signup_option');
  const [formType, setFormType] = useState<FormType>('request_code');
  const [userEmail, setUserEmail] = useState<string | undefined>(undefined);
  const [userPassword, setUserPassword] = useState<string | null>(null);
  const history = useHistory();

  const getEventFromUrl = () => {
    const event = new URLSearchParams(history.location.search).get('event');

    if (typeof event === 'string') {
      return event;
    }
  };

  useEffect(() => {
    handleGoogleAuthError(true);
    if (appleAuthError != null) {
      handleAppleAuthError(appleAuthError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { googleUserId: nextGoogleUserId, userConfirmationState: confirmationState } = props;
  const prevPropsRef = useRef(props);

  useEffect(() => {
    const prevProps = prevPropsRef.current;

    if (
      prevProps.createAccountSubstep === 'verification_code' &&
      createAccountSubstep === 'choose_signup_option'
    ) {
      resetConfirmationState();
      updateStep('choose_signup_option');
    }

    if (nextGoogleUserId && prevProps.googleUserId !== nextGoogleUserId) {
      if (nextGoogleUserId && (!confirmationState || confirmationState === 'unconfirmed')) {
        analytics.track(analytics.EVENTS.OATH_SIGN_UP_STARTED, {
          provider: 'Google'
        });
        updateStep('google_attributes');
      }
    }

    if (googleAuthError && googleAuthError !== prevProps.googleAuthError) {
      handleGoogleAuthError();
    }

    if (appleAuthError && appleAuthError !== prevProps.appleAuthError) {
      handleAppleAuthError(appleAuthError);
    }

    const { patient } = props;
    if (
      patient.apple?.token &&
      patient.email &&
      currentStep !== 'apple_attributes' &&
      appleAuthError?.type !== 'UserAlreadyExistsAsEmail' &&
      appleAuthError?.type !== 'UserHasNotSignedUp'
    ) {
      setCurrentStep('apple_attributes');
    }

    prevPropsRef.current = props;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const handleAppleAuthError = (error: AppleAuthError) => {
    if (error.type === 'UserHasNotSignedUp') {
      showErrorToast(getAppleSignUpDisabledMessage(error.email || ''));
      setCurrentStep('login');
    } else if (error.type === 'UserAlreadyExistsAsEmail') {
      showErrorToast(
        'Sorry, we can not proceed with that request. If you are registered with a Gmail account, please log in with Google. If you are registered with an Apple ID, please log in with Apple.'
      );

      setCurrentStep('choose_signup_option');
    }
  };

  const handleGoogleAuthError = (showMessage?: boolean) => {
    if (!googleAuthError) return;
    const { type } = googleAuthError;
    if (type === 'UserHasNotSignedUp') {
      if (showMessage) {
        showSuccessToast('Please finish signing up with your Google Account.');
      }

      setCurrentStep('google_attributes');
      clearGoogleError();
    } else if (type === 'UserAlreadyExistsAsEmail') {
      showErrorToast(
        'Sorry, we can not proceed with that request. If you are registered with a Gmail account, please log in with Google. If you are registered with an Apple ID, please log in with Apple.'
      );
      setTimeout(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const target = urlParams.get('target');
        const membershipId = urlParams.get('membership_id');
        const enterpriseMembership = urlParams.get('enterprise_membership');
        let newLocation = '/login';
        if (target) {
          newLocation = `/login?target=${encodeURIComponent(target)}`;
          if (membershipId) {
            // hacky fix because membership id is not being parsed as a part of the target param
            newLocation = `/login?target=${encodeURIComponent(
              `${target}&membership_id=${membershipId}`
            )}`;
          } else if (enterpriseMembership) {
            newLocation = `/login?target=${encodeURIComponent(
              `${target}&enterprise_membership=true`
            )}`;
          }
        }
        history.replace(newLocation);
      });
    }
  };

  const updateStep = (step: SubStep) => {
    setCurrentStep(step);
    onChangeSubstep?.(step);
  };

  const renderSignUpOptions = () => {
    return (
      <>
        <SignUpButtons onSignUpWithEmail={() => updateStep('email')} />
        <SignInLinkContainer>
          <div>
            <Text tag="p" size="sm" fontStyle="medium">
              ALREADY HAVE AN ACCOUNT?
            </Text>
          </div>
          <div>
            <Text tag="p" size="sm" fontStyle="medium">
              <LinkButton onClick={() => updateStep('login')} text="SIGN IN HERE" />
            </Text>
          </div>
        </SignInLinkContainer>
      </>
    );
  };

  const renderEmailSignupForm = () => {
    return (
      <SignUpScreen
        preselectedLab={lab}
        signUpUser={(props: SignUpProps) => {
          signUpUser({ ...props, event: getEventFromUrl() }, SIGNUP_TYPE.EMAIL);
          const { email, password } = props;
          setUserEmail(email);
          setUserPassword(password || null);
        }}
        userConfirmationState={userConfirmationState}
        loading={loading}
        signUpError={signUpError}
        onError={(error: string) => {
          showErrorToast(error);
        }}
        onUserAlreadyExists={() => {
          // If user attempts to create an account w email that already exists, show login.
          updateStep('login');
        }}
        onSuccess={(state?: string) => {
          if (state === 'unconfirmed') {
            updateStep('verification_code');
          } else {
            onAuthentication();
          }
        }}
        signUpType={SIGNUP_TYPE.EMAIL}
        signInLinkIsVisible={signInLinkIsVisible}
      />
    );
  };

  const renderAppleAttributes = () => {
    return (
      <SignUpScreen
        patient={patient}
        isLoggedIn={isLoggedIn}
        preselectedLab={lab}
        signUpUser={(props: SignUpProps) => {
          signUpUser({ ...props, event: getEventFromUrl() }, SIGNUP_TYPE.APPLE);
        }}
        userConfirmationState={userConfirmationState}
        loading={loading}
        googleAuthError={googleAuthError}
        signUpError={signUpError}
        onError={(error: string) => {
          showErrorToast(error);
        }}
        onUserAlreadyExists={() => {}}
        onSuccess={() => {
          onAuthentication();
        }}
        signUpType={SIGNUP_TYPE.APPLE}
        signInLinkIsVisible={signInLinkIsVisible}
      />
    );
  };

  const renderGoogleAttributes = () => {
    return (
      <SignUpScreen
        patient={patient}
        isLoggedIn={isLoggedIn}
        preselectedLab={lab}
        signUpUser={(props: SignUpProps) => {
          signUpUser({ ...props, event: getEventFromUrl() }, SIGNUP_TYPE.GOOGLE);
        }}
        userConfirmationState={userConfirmationState}
        loading={loading}
        googleAuthError={googleAuthError}
        signUpError={signUpError}
        onError={(error: string) => {
          showErrorToast(error);
        }}
        onUserAlreadyExists={() => {}}
        onSuccess={() => {
          onAuthentication();
        }}
        signUpType={SIGNUP_TYPE.GOOGLE}
        signInLinkIsVisible={signInLinkIsVisible}
      />
    );
  };

  const renderLoginScreen = () => {
    return (
      <LoginForm
        mobile={mobileLayout}
        onLogin={() => {
          onAuthentication();
        }}
        onError={(error: LoginError) => {
          if (error.type === 'Unconfirmed') {
            setTimeout(() => {
              // If user is unconfirmed, show the confirmation code component
              updateStep('verification_code');
            }, 5000);
          }
        }}
        onForgotPassword={() => {
          updateStep('forgot_password');
        }}
      />
    );
  };

  const renderForgotPassword = () => {
    return (
      <ForgotPasswordForm
        onSuccessfulReset={() => {
          updateStep('login');
        }}
        onGoBack={() => {
          updateStep('login');
        }}
        handleUnconfirmed={email => {
          history.replace('/verification-code', { email, forgotPassword: true });
        }}
        formType={formType}
        updateFormType={formType => setFormType(formType)}
      />
    );
  };

  const renderVerificationCode = () => {
    return (
      <VerificationCode
        email={userEmail}
        onSuccess={async () => {
          if (userEmail && userPassword) {
            await login(userEmail, userPassword);
            onAuthentication();
            return;
          }

          // This is the final step before, confirm
          showSuccessToast('Thank you for verifying your account! You may now log in.');
          updateStep('login');
        }}
      />
    );
  };

  return (
    <Container>
      <Content>
        {currentStep === 'choose_signup_option' && renderSignUpOptions()}
        {currentStep === 'email' && renderEmailSignupForm()}
        {currentStep === 'google_attributes' && renderGoogleAttributes()}
        {currentStep === 'apple_attributes' && renderAppleAttributes()}
        {currentStep === 'login' && renderLoginScreen()}
        {currentStep === 'forgot_password' && renderForgotPassword()}
        {currentStep === 'verification_code' && renderVerificationCode()}
      </Content>
    </Container>
  );
};

const mapStateToProps = ({ patient }: { patient: PatientState }) => ({
  userConfirmationState: patient.confirmationState,
  patient: patient.patient,
  isLoggedIn: patient.isLoggedIn,
  loading: patient.loading,
  signUpError: patient.signUpError,
  googleUserId: patient.patient.google && patient.patient.google.id,
  googleAuthError: patient.googleAuthError,
  appleAuthError: patient.appleAuthError
});

const mapDispatchToProps = (dispatch: Function) => ({
  signUpUser: (props: SignUpProps, signUpType: SIGNUP_TYPE) =>
    dispatch(signUpPatient(props, signUpType)),
  login: (email: string, password: string) => dispatch(login(email, password)),
  clearGoogleError: () => dispatch(clearGoogleAuthError()),
  resetConfirmationState: () => dispatch(resetConfirmationState())
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccount);
