import React from 'react';

import { ReactComponent as CheckmarkIcon } from 'assets/icons/checkmark.svg';
import { Text } from 'components/v2/Typography';
import theme from 'kb-shared/theme';

import { Checkmark, CheckmarkContainer, ContainerLabel } from './Checkbox.styled';
import { Props } from './Checkbox.types';

export const Checkbox = ({
  id,
  label,
  isChecked,
  onChange,
  size,
  useAlternateColor = false,
  isCheckedAndReviewed = false,
  className,
  disabled = false
}: Props) => {
  return (
    <ContainerLabel
      className={className}
      htmlFor={id}
      size={size}
      disabled={disabled}
      isChecked={isChecked}
      $isCheckedAndReviewed={isCheckedAndReviewed}
    >
      <input
        id={id}
        type="checkbox"
        className="v2"
        checked={isChecked}
        onChange={onChange}
        disabled={disabled}
      />
      <CheckmarkContainer size={size} useAlternateColor={useAlternateColor}>
        <Checkmark $isCheckedAndReviewed={isCheckedAndReviewed}>
          <CheckmarkIcon />
        </Checkmark>
      </CheckmarkContainer>
      <Text size="md" color={disabled ? theme.colors.neutral.lighterNavy : ''}>
        {label}
      </Text>
    </ContainerLabel>
  );
};
