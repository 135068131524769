import { ApolloError } from '@apollo/client';

import { NormalizedBookingSearchParams } from '../types/types';
import { LoadedEntities } from '../types/types';
import { containsOnlyVirtualAppointmentType, isVirtualAppointmentType } from '../utils/validation';
import { useAppointment } from './useAppointment';
import { useAppointmentType } from './useAppointmentType';
import { useAppointmentTypes } from './useAppointmentTypes';
import { useDefaultLab } from './useDefaultLab';
import { useLab } from './useLab';
import { useLocation } from './useLocation';
import { useTimeSlot } from './useTimeSlot';

export type LoadResult = {
  loading: boolean;
  error: ApolloError | undefined;
  loadedEntities: Partial<LoadedEntities>;
};

export const useLoadEntities = ({
  appointmentId,
  appointmentTypeIds,
  labId,
  clinicId,
  timeSlotId,
  confirmedAppointmentId
}: NormalizedBookingSearchParams): LoadResult => {
  const responseAppointmentType = useAppointmentType(appointmentId);
  const responseAppointmentTypes = useAppointmentTypes(appointmentTypeIds);
  const responseConfirmedAppointment = useAppointment(confirmedAppointmentId);
  const responseLab = useLab(labId);
  const responseDefaultLab = useDefaultLab({ skip: Boolean(labId) });
  const responseLocation = useLocation(clinicId);
  const responseTimeSlot = useTimeSlot(timeSlotId);

  const loading =
    responseAppointmentType.loading ||
    responseAppointmentTypes.loading ||
    responseConfirmedAppointment.loading ||
    responseLab.loading ||
    responseDefaultLab.loading ||
    responseLocation.loading ||
    responseTimeSlot.loading;

  const error =
    responseAppointmentType.error ||
    responseAppointmentTypes.error ||
    responseConfirmedAppointment.error ||
    responseLab.error ||
    responseDefaultLab.error ||
    responseLocation.error ||
    responseTimeSlot.error;

  if (loading) {
    return { loading, error: error, loadedEntities: {} };
  }

  const loadedEntities: Partial<LoadedEntities> = {};
  const labData = responseLab.data || responseDefaultLab.data;

  if (responseAppointmentType.data) {
    loadedEntities.selectedProduct = {
      type: 'appointment',
      data: responseAppointmentType.data
    };
  }
  if (responseAppointmentTypes.data) {
    loadedEntities.selectedAppointmentTypes = responseAppointmentTypes.data;
  }

  if (labData) {
    loadedEntities.selectedLab = labData;
  }
  if (responseLocation.data) {
    loadedEntities.selectedClinic = responseLocation.data;
  }
  if (responseTimeSlot.data) {
    loadedEntities.selectedTimeSlot = responseTimeSlot.data;
  }
  if (responseConfirmedAppointment.data) {
    loadedEntities.purchasedProduct = {
      type: 'appointment',
      data: responseConfirmedAppointment.data
    };
  }

  loadedEntities.allAppointmentTypes = (loadedEntities.selectedAppointmentTypes || []).concat(
    responseAppointmentType.data || []
  );

  const virtualAppointmentType = loadedEntities.allAppointmentTypes.find(isVirtualAppointmentType);
  const isOnlyVirtual = containsOnlyVirtualAppointmentType(loadedEntities.allAppointmentTypes);

  if (virtualAppointmentType && isOnlyVirtual && !loadedEntities.selectedProduct) {
    loadedEntities.selectedProduct = {
      type: 'appointment',
      data: virtualAppointmentType
    };
  }

  return {
    loading,
    error,
    loadedEntities
  };
};
