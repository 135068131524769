import React from 'react';

import { CloseIcon } from 'assets/icons';
import Modal from 'components/Modal/Modal';

import {
  Body,
  Container,
  CloseIconButton,
  Description,
  Header,
  Instructions,
  TopBar
} from './RiskAssessmentResultModal.styled';
import { RiskAssessmentResultModalProps } from './RiskAssessmentResultModal.types';

export const RiskAssessmentResultModal = ({ isOpen, onClose }: RiskAssessmentResultModalProps) => {
  return (
    <Modal onRequestClose={onClose} open={isOpen}>
      <Container>
        <TopBar>
          <CloseIconButton onClick={onClose} aria-label="Close">
            <CloseIcon width={30} height={30} />
          </CloseIconButton>
        </TopBar>
        <Body>
          <Header>Thank you for your submission</Header>
          <Description>
            Your assessment helps us understand when you should come in for your initial pregnancy
            appointment.
          </Description>
          <Instructions>
            If you have already booked your first pregnancy appointment, there is nothing you need
            to do. Otherwise, please schedule a pregnancy confirmation visit with your OBGYN
            provider.
          </Instructions>
        </Body>
      </Container>
    </Modal>
  );
};
