import React from 'react';

import { Text } from 'components/v2/Typography';
import { Clinic, TimeSlot, AppointmentType } from 'kb-shared';
import getAppointmentChannel from 'utilities/getAppointmentChannel';

import { Location, PinIcon, VideoLink } from './AppointmentLocation.styled';

interface Props {
  timeSlot: TimeSlot;
  appointmentType: AppointmentType;
  clinic: Clinic | null;
}

export const AppointmentLocation = ({ appointmentType, timeSlot, clinic }: Props) => {
  const { isPhoneCall, isVideoCall } = getAppointmentChannel(appointmentType);

  if (isPhoneCall) {
    return (
      <Location>
        <Text fontStyle="bold">
          {
            'Your provider will call you at the selected time. By joining this phone call appointment, you confirm that you are physically present in one of the state(s) where this provider is licensed.'
          }
        </Text>
      </Location>
    );
  }

  if (isVideoCall) {
    const url = timeSlot.videoLink || appointmentType.videoLink;

    return (
      <Location>
        <Text fontStyle="bold">{'Video Consult'}</Text>
        {url && (
          <VideoLink href={url} target="_blank" rel="noopener noreferrer">
            {url}
          </VideoLink>
        )}
      </Location>
    );
  }

  if (clinic) {
    return (
      <Location>
        <PinIcon />
        <Text fontStyle="bold">{clinic.name}</Text>, {clinic.address}
      </Location>
    );
  }

  return null;
};
