import React from 'react';

import { useBreakpoints } from 'utilities/useBreakpoints';

import { Container, TabHeading } from './EggEmbryoInventory.styled';
import { Props } from './EggEmbryoInventory.types';
import { EggEmbryoInventoryStackView } from './EggEmbryoInventoryStackView';
import { EggEmbryoInventoryTableView } from './EggEmbryoInventoryTableView';

export const EggEmbryoInventory = ({ oocytes }: Props) => {
  const { isMobile } = useBreakpoints();

  return (
    <Container>
      <TabHeading>Egg & Embryo Inventory Details</TabHeading>

      {isMobile ? (
        <EggEmbryoInventoryStackView oocytes={oocytes} />
      ) : (
        <EggEmbryoInventoryTableView oocytes={oocytes} />
      )}
    </Container>
  );
};
