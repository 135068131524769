import React from 'react';

import { Text } from 'components/v2/Typography';
import { formatAmount } from 'screens/Invoices/components/UnappliedPayments/UnappliedPayments.utils';
import { Payment } from 'screens/Invoices/types/payment';

import { TableBody, TableComponent, TableCell, TableHead } from './PaymentPlanPaymentsTable.styled';

export function PaymentPlanPaymentsTable({ payments }: { payments: Payment[] }) {
  const rows = payments.map(payment => (
    <tr key={payment.id}>
      <TableCell>
        <Text tag="span" fontStyle="regular">
          {payment.date}
        </Text>
      </TableCell>
      <TableCell>
        <Text tag="span" fontStyle="regular">
          {formatAmount(payment.amountCents)}
        </Text>
      </TableCell>
    </tr>
  ));

  return (
    <TableComponent>
      <thead>
        <tr>
          {TABLE_HEADERS.map(header => (
            <TableHead key={header.text} $alignRight={header.alignRight}>
              <Text tag="span" fontStyle="medium">
                {header.text}
              </Text>
            </TableHead>
          ))}
        </tr>
      </thead>

      {<TableBody>{rows}</TableBody>}
    </TableComponent>
  );
}

const TABLE_HEADERS = [
  { text: 'Payment Date', alignRight: false },
  { text: 'Amount', alignRight: false }
];
