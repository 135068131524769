import React from 'react';

import { XMark } from 'assets/icons/Icons';
import { ModalContent, ModalHeader } from 'components/ConsentModal/ConsentModal.styles';
import { InsuranceForm } from 'components/InsuranceForm/InsuranceForm';
import { InsuranceProvider } from 'components/InsuranceForm/InsuranceForm.types';
import { COB_PRIORITY_CODE } from 'components/InsuranceForm/InsuranceForm.utils';
import Modal from 'components/Modal/Modal';
import { LinkButton } from 'components/v2/Buttons/LinkButton/LinkButton';
import { Heading } from 'components/v2/Typography';

interface Props {
  title: string;
  open: boolean;
  cobPriorityCode?: COB_PRIORITY_CODE;
  insuranceProvider?: InsuranceProvider;
  onClose: () => void;
  onSuccess: () => void;
}

const MODAL_MAX_WIDTH = '880px';

export const InsuranceModalForm = ({
  title,
  open,
  cobPriorityCode,
  insuranceProvider,
  onClose,
  onSuccess
}: Props) => {
  return (
    <Modal
      label={title}
      open={open}
      onRequestClose={onClose}
      maxWidth={MODAL_MAX_WIDTH}
      mobileFullscreen
    >
      <ModalContent>
        <ModalHeader>
          <Heading noMargin tag="h4">
            {title}
          </Heading>
          <LinkButton leftIcon={<XMark type="solid" />} size="sm" onClick={onClose} />
        </ModalHeader>
        <InsuranceForm
          insuranceProvider={insuranceProvider}
          cobPriorityCode={cobPriorityCode}
          onSuccess={onSuccess}
        />
      </ModalContent>
    </Modal>
  );
};
