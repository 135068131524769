import React, { ReactNode } from 'react';

import Modal from 'components/Modal/Modal';

import { CloseIcon } from '../../assets/icons';
import { Container, Content, IconContainer, Title, Text } from './HelperModal.styled';

type Props = {
  title: string;
  copy?: ReactNode;
  content?: ReactNode;
  open: boolean;
  onDismiss: () => void;
};

export const HelperModal = ({ title, copy, open, content, onDismiss }: Props) => {
  return (
    <Modal onRequestClose={onDismiss} open={open}>
      <Container data-testid="helper-modal-container">
        <Content>
          <IconContainer onClick={onDismiss} aria-label="Close">
            <CloseIcon data-testid="helper-modal-close-icon" />
          </IconContainer>
          <Title>{title}</Title>
          <Text>{copy}</Text>
          {content}
        </Content>
      </Container>
    </Modal>
  );
};

export default HelperModal;
