import { LinkButton } from 'components/v2/Buttons/LinkButton/LinkButton';
import { themes } from 'kb-shared';
import styled from 'styled-components';

export const Title = styled.span`
  left: 12px;

  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: ${themes.colors.neutral.primaryNavy};
  display: block;

  @media ${({ theme }) => theme.queries.maxTablet} {
    margin-top: 0;
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 16px;

  //Without this media query the consent with the shortest description would not be aligned with others.
  @media ${({ theme }) => theme.queries.tablet} {
    max-width: 800px;
  }
`;

export const Action = styled.button`
  flex: 0;
  justify-self: right;
  background: ${themes.colors.neutral.primaryNavy};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.neutral.lavenderWhite};
  text-transform: uppercase;

  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-align: center;
  height: 48px;
  align-content: center;
  justify-content: center;
  text-align: center;
  padding: 4px 16px 4px 16px;
  margin-top: 8px;
  margin-bottom: 8px;
`;

export const Paragraph = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: ${themes.colors.neutral.primaryNavy};
`;

export const OpenModalLink = styled(LinkButton)`
  color: ${themes.colors.neutral.primaryNavy};
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0px;
  font-weight: 600;
  cursor: pointer;
`;
