import React from 'react';

import Modal from 'components/Modal/Modal';
import styled from 'styled-components';
import { useBreakpoints } from 'utilities/useBreakpoints';

import { CloseIcon, LeftArrow2 } from '../../../assets/icons';
import RiskAssessmentFormType from '../types/RiskAssessmentForm';
import RiskAssessmentForm from './RiskAssessmentForm';
interface Props {
  isOpen: boolean;
  form?: RiskAssessmentFormType;
  onClose: () => void;
  onSaveAnswer: (fieldId: number, answer: boolean) => Promise<any>;
  onSubmit: () => void;
}

const RiskAssessmentFormModal = ({ isOpen, form, onClose, onSaveAnswer, onSubmit }: Props) => {
  const { isMobile } = useBreakpoints();

  return (
    <Modal onRequestClose={onClose} open={isOpen} mobileFullscreen>
      <TopBar>
        <CloseIconButton onClick={onClose} aria-label="Close">
          {isMobile ? <LeftArrow2 width={16} height={16} /> : <CloseIcon width={30} height={30} />}
        </CloseIconButton>
        <Header>Pregnancy Assessment</Header>
      </TopBar>

      {!!form && <RiskAssessmentForm form={form} onSubmit={onSubmit} onSaveAnswer={onSaveAnswer} />}
    </Modal>
  );
};

export default RiskAssessmentFormModal;

const TopBar = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
`;

const Header = styled.h1`
  margin: 0 auto;

  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  font-size: 18px;
  line-height: 23px;
  font-weight: 600;

  @media ${({ theme }) => theme.queries.minTablet} {
    display: none;
  }
`;

const CloseIconButton = styled.button`
  padding: 10px 8px 8px 0px;

  svg path {
    fill: ${({ theme }) => theme.colors.neutral.primaryNavy};
  }

  @media ${({ theme }) => theme.queries.minTablet} {
    margin-left: auto;
  }
`;
