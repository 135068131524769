import React from 'react';

import { isHiddenWidget } from '../DashboardSettings/DashboardSettings.utils';
import { AppointmentsWidgetProps } from './AppointmentsWidget.types';
import { BookAppointmentWidget } from './BookAppointmentWidget/BookAppointmentWidget';
import { UpcomingAppointmentsWidget } from './UpcomingAppointmentsWidget/UpcomingAppointmentsWidget';

export const AppointmentsWidget: React.FC<AppointmentsWidgetProps> = ({
  upcomingAppointments,
  refetch,
  hiddenWidgets
}) => {
  if (isHiddenWidget(hiddenWidgets, 'appointments')) return null;

  return upcomingAppointments.length > 0 ? (
    <UpcomingAppointmentsWidget upcomingAppointments={upcomingAppointments} refetch={refetch} />
  ) : (
    <BookAppointmentWidget />
  );
};
