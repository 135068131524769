import styled from 'styled-components';

// TODO: should be replaced by button new button component
// plus icon on the left side is also missing

export const PartnerListContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral.lavenderWhite};
  padding: 16px 12px;
  margin-bottom: 24px;
  border-radius: 8px;
  @media ${({ theme }) => theme.queries.minDesktop} {
    padding: 0 0;
    background-color: white;
  }
`;

export const PartnerListItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media ${({ theme }) => theme.queries.minDesktop} {
    gap: 24px;
  }
`;

export const TitleContainer = styled.div`
  margin-bottom: 12px;

  @media ${({ theme }) => theme.queries.minDesktop} {
    display: none;
  }
`;
