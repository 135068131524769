import React, { MouseEvent } from 'react';

import DeprecatedCheckBox from 'components/DeprecatedCheckBox';

import {
  TermsBlock,
  TermsText,
  TermsTextContainer,
  TextContainer
} from './TermsDetails.common-styled';
import { TermsLinks } from './TermsLinks/TermsLinks';

type Props = {
  selected: boolean;
  onCheckBoxSelected: (e: MouseEvent<HTMLDivElement>) => void;
  showSessionDisclaimerCharge?: boolean;
};

export const TermsDetails = (props: Props) => {
  const { selected, onCheckBoxSelected, showSessionDisclaimerCharge } = props;

  return (
    <TermsBlock>
      <DeprecatedCheckBox selected={selected} onSelect={onCheckBoxSelected} />
      <TextContainer>
        {showSessionDisclaimerCharge && (
          <TermsTextContainer>
            <TermsText>
              I will not be charged for any sessions available to me under my Kindbody benefit. Once
              I have used all sessions available under my benefit, I will be responsible for the
              cost of the session.
            </TermsText>
          </TermsTextContainer>
        )}

        <TermsTextContainer>
          <TermsText>
            I authorize Kindbody to send me secure communications, which may include my medical
            information. Kindbody only sends unencrypted text messages for appointment reminders and
            portal notifications.
          </TermsText>
        </TermsTextContainer>

        <TermsLinks />
      </TextContainer>
    </TermsBlock>
  );
};
