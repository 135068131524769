import React from 'react';

import { Props } from './EggEmbryoInventory.types';
import { Table, Th, Td, TableRow, ThText, TdText } from './EggEmbryoInventoryTableView.styled';

export const EggEmbryoInventoryTableView = ({ oocytes }: Props) => {
  return (
    <Table>
      <thead>
        <tr>
          <Th>
            <ThText fontStyle="semibold">ID</ThText>
          </Th>
          <Th>
            <ThText fontStyle="semibold">Specimen Type</ThText>
          </Th>
          <Th>
            <ThText fontStyle="semibold">Cryo Date</ThText>
          </Th>
          <Th>
            <ThText fontStyle="semibold">Cryo Day</ThText>
          </Th>
          <Th>
            <ThText fontStyle="semibold">Grade</ThText>
          </Th>
          <Th>
            <ThText fontStyle="semibold">Lab Location</ThText>
          </Th>
        </tr>
      </thead>
      <tbody>
        {oocytes.map(oocyte => (
          <TableRow key={oocyte.id}>
            <Td>
              <TdText>{oocyte.id}</TdText>
            </Td>
            <Td>
              <TdText>{oocyte.specimenType}</TdText>
            </Td>
            <Td>
              <TdText>{oocyte.cryoDate}</TdText>
            </Td>
            <Td>
              <TdText>{oocyte.cryoDay}</TdText>
            </Td>
            <Td>
              <TdText>{oocyte.grade}</TdText>
            </Td>
            <Td>
              <TdText>{oocyte.labLocation}</TdText>
            </Td>
          </TableRow>
        ))}
      </tbody>
    </Table>
  );
};
