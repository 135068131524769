import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { ReactComponent as LockIcon } from 'assets/icons/secure-lock.svg';
import Button from 'components/Buttons/NewButton';
import SelectInput from 'components/SelectInput';
import { StepTrackerLayout } from 'components/v2/Layouts/StepTrackerLayout/StepTrackerLayout';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import {
  Container,
  Form
} from 'screens/ActivateMembership/EnterAccessCode/ActivateMembershipScreen.styled';
import { analytics } from 'utilities/analytics';
import { graphQLErrorMessage } from 'utilities/errors';
import { showErrorToast, showSuccessToast } from 'utilities/notificationUtils';
import { pageUrl, RoutesPath } from 'utilities/pageUrl';

import { PATIENT } from '../../kb-shared/graphql/queries';
import { AcceptTermsWrapper } from './components/ConfirmationBox/ConfirmationBox.styled';
import { CREATE_WALMART_PATIENT_MEMBERSHIP } from './WalmartConfirmYourIdentityScreen.graphql';
import {
  IdentityConfirmationStep,
  Description,
  Title,
  TitleContainer
} from './WalmartConfirmYourIdentityScreen.styled';
import {
  CreateWalmartDependentPatientMembershipVariables,
  CreateWalmartDependentPatientMembershipResult,
  BenefitsProfileOption,
  BenefitsProfile,
  STEPS_NAME
} from './WalmartConfirmYourIdentityScreen.types';

const benefitsProfileSelectedInitialValue = {
  value: '',
  label: ''
};

export const WalmartConfirmYourIdentityScreen = () => {
  const location = useLocation();
  const history = useHistory();
  const { benefitsProfiles }: { benefitsProfiles: BenefitsProfile[] } = location?.state ?? [];
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [dependentsDateOfBirth, setDependentsDateOfBirth] = useState<string>('');
  const [bid, setBid] = useState<string>('');
  const [selected, setSelected] = useState<BenefitsProfile>();

  const [benefitsProfileSelected, setBenefitsProfileSelected] = useState<BenefitsProfileOption>(
    benefitsProfileSelectedInitialValue
  );

  const [createWalmartDependentPatientMembership, { loading }] = useMutation<
    CreateWalmartDependentPatientMembershipResult,
    CreateWalmartDependentPatientMembershipVariables
  >(CREATE_WALMART_PATIENT_MEMBERSHIP, {
    refetchQueries: [{ query: PATIENT }],
    onError: error => {
      showErrorToast(graphQLErrorMessage(error));
      setButtonDisabled(true);
      BugTracker.notify(error, 'Activate membership error(walmart)');
    },
    onCompleted: data => {
      analytics.track(analytics.EVENTS.EMU_ACCOUNT_ACTIVATED, {
        enterprise_name: data.createWalmartPatientMembership.patientMembership?.employerName ?? ''
      });
      analytics.track(analytics.EVENTS.WALMART_REGISTRATION_SUCCEEDED);

      showSuccessToast('Your membership has been activated');

      history.push(pageUrl.membershipActivationSuccess(), {
        title: `${selected?.firstName} ${selected?.lastName}`
      });
    }
  });

  useEffect(() => {
    setButtonDisabled(!bid || !dependentsDateOfBirth || loading);
  }, [bid, dependentsDateOfBirth, loading]);

  useEffect(() => {
    if (!benefitsProfileSelected?.value) return;
    const selectedValue = benefitsProfiles.find(
      (item: { bid: string }) => item.bid === benefitsProfileSelected?.value
    );
    setSelected(selectedValue);
  }, [benefitsProfileSelected, benefitsProfiles]);

  useEffect(() => {
    if (!selected) return;
    setDependentsDateOfBirth(selected.dateOfBirth);
    setBid(selected.bid);
  }, [selected]);

  useEffect(() => {
    if (!benefitsProfiles) {
      history.replace(RoutesPath.ENTER_MEMBERSHIP_ACCESS_CODE);
    }
  }, [benefitsProfiles, history]);

  if (!benefitsProfiles) {
    return null;
  }

  const options = benefitsProfiles.map((item: BenefitsProfile) => ({
    value: item.bid,
    label: `${item.firstName}  ${item.lastName} - ${item.dateOfBirth}`
  }));

  const handleConfirm = () =>
    createWalmartDependentPatientMembership({
      variables: {
        patientsDateOfBirth: dependentsDateOfBirth,
        bid: bid
      }
    });

  return (
    <StepTrackerLayout
      title={'Activate Membership'}
      stepNames={STEPS_NAME}
      selectedStep={2}
      renderMainContent={() => {
        return (
          <Container>
            <TitleContainer>
              <Title>
                Select your benefits profile from the list <LockIcon width={23} height={23} />
              </Title>
              <Description>
                Your benefits provider has provided a list of benefits profiles for the associate's
                account. Please select yourself from the list.
              </Description>
            </TitleContainer>
            <Form>
              <IdentityConfirmationStep>
                <SelectInput
                  label="Confirm Identity"
                  placeholder="Please identify yourself from this list"
                  value={benefitsProfileSelected?.value}
                  selectedOption={benefitsProfileSelected}
                  type="select"
                  fullMode
                  extractTitle={item => (item ? item.label : '')}
                  onSelect={select => setBenefitsProfileSelected(select)}
                  options={options}
                />
              </IdentityConfirmationStep>
            </Form>
            <AcceptTermsWrapper>
              <Button
                text={'Next'}
                type={'submit'}
                loading={loading}
                onClick={() => handleConfirm()}
                disabled={buttonDisabled}
              />
            </AcceptTermsWrapper>
          </Container>
        );
      }}
    />
  );
};
