import React from 'react';

import Button from 'components/Buttons/NewButton';
import { utils } from 'kb-shared';

import {
  TitleText,
  AllSetContainer,
  FinishedSubtitle,
  Link
} from '../Components/Comprehension/Comprehension.styled';

export const Error = () => {
  return (
    <AllSetContainer>
      <TitleText>Something went wrong</TitleText>
      <FinishedSubtitle>
        An error happened while we were trying to show your consent. If the problem persist, contact
        your care team at{' '}
        <Link href={`mailto:${utils.KBContacts.navigatorEmail}?subject=Consent Forms`}>
          {utils.KBContacts.navigatorEmail}
        </Link>{' '}
        or <Link href="tel://1-855-KND-BODY">1-855-KND-BODY</Link>.
      </FinishedSubtitle>
      <Button text="Reload" onClick={() => window.location.reload()} />
    </AllSetContainer>
  );
};
