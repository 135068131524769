import { ApolloError, gql, useQuery } from '@apollo/client';

import { BugTracker } from 'kb-shared/utilities/bugTracker';

import { Payment } from '../types/payment';

export const UNAPPLIED_INVOICES_QUERY = gql`
  query unappliedPayments {
    unappliedPayments {
      id
      amountCents
      azAppliedAmountCents
      reason
      date
    }
  }
`;

const useUnappliedPayments = (skip: boolean): UseUnappliedPaymentsResponse => {
  const { loading, data, error, refetch } = useQuery<UnappliedPaymentsResponse>(
    UNAPPLIED_INVOICES_QUERY,
    {
      skip,
      onError: error => BugTracker.notify(error, 'Unapplied Payments Fetch Failed')
    }
  );

  const unappliedPayments = data?.unappliedPayments ?? [];
  return { unappliedPayments, loading, error, refetch };
};

export default useUnappliedPayments;

interface UseUnappliedPaymentsResponse {
  unappliedPayments: Payment[];
  error: ApolloError | undefined;
  loading: boolean;
  refetch: () => {};
}

export interface UnappliedPaymentsResponse {
  unappliedPayments: Payment[];
}
