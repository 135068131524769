import { bookingDataParams, bookingParams } from '../constants/urlParameters';
import {
  BookingUrlParameters,
  BookingUrlParameterKeys,
  NormalizedBookingSearchParams
} from '../types/types';
import { toIntOrNull } from './validation';

export class BookingSearchParams {
  // search query string without the "?" prefix
  private search = '';

  constructor(searchQueryOrUrl = '') {
    // if full/relative URL provided get the search query
    const isNotPlainSearchQueryString = searchQueryOrUrl.includes('?');
    const searchParams = isNotPlainSearchQueryString
      ? new URLSearchParams(searchQueryOrUrl.substring(searchQueryOrUrl.indexOf('?')))
      : new URLSearchParams(searchQueryOrUrl);

    this.search = searchParams.toString();
  }

  toString(): string {
    return this.search;
  }

  has(key: BookingUrlParameterKeys): boolean {
    const searchParams = new URLSearchParams(this.search);

    return searchParams.has(key);
  }

  get(key: BookingUrlParameterKeys): string | null {
    const searchParams = new URLSearchParams(this.search);

    return searchParams.get(key);
  }

  set(key: BookingUrlParameterKeys, value: string): void {
    const searchParams = new URLSearchParams(this.search);
    searchParams.set(key, value);
    this.search = searchParams.toString();
  }

  merge(params: Partial<Record<BookingUrlParameterKeys, string>>): void {
    const searchParams = new URLSearchParams(this.search);
    Object.entries(params).forEach(([key, value]) => {
      searchParams.set(key, value);
    });
    this.search = searchParams.toString();
  }

  remove(key: BookingUrlParameterKeys): void {
    const searchParams = new URLSearchParams(this.search);
    searchParams.delete(key);
    this.search = searchParams.toString();
  }

  clone(): BookingSearchParams {
    return new BookingSearchParams(this.search);
  }

  getBookingParams(): BookingUrlParameters {
    const searchParams = new URLSearchParams(this.search);

    return Object.fromEntries(
      bookingParams.map(key => [key, searchParams.get(key)])
    ) as BookingUrlParameters;
  }

  containsBookingDataParams(): boolean {
    const params = this.getBookingParams();

    return bookingDataParams.some(key => params[key] !== null);
  }

  toNormalizedBookingParams(): NormalizedBookingSearchParams {
    const params = new URLSearchParams(this.search);

    return {
      appointmentId: toIntOrNull(params.get('appointment_id')),
      appointmentTypeIds: (params
        .get('appointment_types')
        ?.split(',')
        .map(toIntOrNull)
        .filter(Number.isInteger) ?? []) as Array<number>,
      labId: toIntOrNull(params.get('lab_id')),
      clinicId: toIntOrNull(params.get('clinic_id')),
      timeSlotId: toIntOrNull(params.get('time_slot_id')),
      confirmedAppointmentId: toIntOrNull(params.get('confirmed_appointment_id'))
    };
  }
}
