import { ApolloError, useQuery } from '@apollo/client';

import useHomeMarket from 'hooks/useHomeMarket';
import { BugTracker } from 'kb-shared/utilities/bugTracker';

import { STATUS } from '../screens/InterstitialModal/InterstitialModal.graphql';
import {
  InterstitialStatusResponse,
  Status
} from '../screens/InterstitialModal/InterstitialModal.types';
import { usePatient } from './usePatient';

export const useInterstitialStatus = (): {
  status: Status | null;
  loading: boolean;
  error?: ApolloError;
} => {
  const { isLoggedIn } = usePatient();
  const { id } = useHomeMarket();
  const { data, loading, error } = useQuery<InterstitialStatusResponse>(STATUS, {
    skip: !isLoggedIn,
    onError: err => {
      BugTracker.notify(err, 'Failed to fetch interstitial status');
    }
  });

  return {
    status: !data || !id ? null : { ...data.interstitialStatus },
    loading,
    error
  };
};
