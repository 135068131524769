import React from 'react';

import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';
import { ReactComponent as MessagingIcon } from 'assets/icons/messaging.svg';
import Modal from 'components/Modal/Modal';
import { usePatient } from 'hooks';
import { getLabInfo } from 'kb-shared/utilities/kindbody_contacts';

import {
  Container,
  Content,
  IconContainer,
  Title,
  Body,
  PhoneNumberSmall
} from './ContactModal.styled';

interface Props {
  visible: boolean;
  onDismiss: Function;
}

const ContactModal = ({ visible, onDismiss }: Props) => {
  const { patient } = usePatient();
  const marketId = patient?.lab?.id;
  const lab = getLabInfo(marketId);

  return (
    <Modal open={visible} onRequestClose={() => onDismiss()}>
      <Container>
        <Content>
          <IconContainer onClick={() => onDismiss()}>
            <CloseIcon />
          </IconContainer>
          <Title>
            <MessagingIcon /> Contact us
          </Title>
          <Body>
            If this is a medical emergency, please call{' '}
            <PhoneNumberSmall href="tel:911">911</PhoneNumberSmall> <br />
            <br />
            If you need to speak to medical provider urgently please <br />
            call <PhoneNumberSmall href={`tel:${lab.number}`}>{lab.numberLabel}.</PhoneNumberSmall>
            <br />
          </Body>
        </Content>
      </Container>
    </Modal>
  );
};

export default ContactModal;
