import { Patient } from 'kb-shared';

export const hasEmployer = (patient?: Patient): boolean => {
  return !!patient?.patientMembership?.employer;
};

export const hasMembershipDocument = (patient: Patient): boolean => {
  const documentUrl = patient?.patientMembership?.membership?.documentUrl;

  return hasEmployer(patient) && !!documentUrl && documentUrl !== 'false';
};

export const hasMembership = (patient: Patient): boolean => {
  return hasEmployer(patient) && !!patient?.patientMembership?.membership?.id;
};

export const hasMembershipDescription = (patient: Patient): boolean => {
  return hasEmployer(patient) && !!patient?.patientMembership?.membership?.description;
};
