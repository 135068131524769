import { Heading, Text } from 'components/v2/Typography';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${({ theme }) => theme.queries.minTablet} {
    width: 480px;
  }
`;

export const TodoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 24px 0 0 0;
`;

export const TodoItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.lavender};
  padding-bottom: 16px;

  @media ${({ theme }) => theme.queries.minTablet} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const TodoInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  padding-bottom: 16px;

  @media ${({ theme }) => theme.queries.minTablet} {
    width: 315px;
  }
`;

export const TodoHeader = styled(Heading).attrs({ styledAs: 'h5', tag: 'div' })`
  margin-bottom: 12px;
`;

export const TodoCardTitle = styled(Text).attrs({
  tag: 'span',
  fontStyle: 'semibold',
  size: 'md'
})``;

export const TodoAdditionalInfo = styled(Text).attrs({ tag: 'p', size: 'sm' })``;
