import styled, { css } from 'styled-components';

import { TabIconPosition, TabsSize } from './DeprecatedTabs.types';

export const Container = styled.div<{ offset?: number }>`
  display: flex;
  flex-wrap: wrap;

  // on mobile we need to make tabs full page width
  // 16 is usual padding for the page on small screens
  margin: 24px ${({ offset }) => offset || 0}px 0 ${({ offset }) => offset || 0}px;

  @media ${({ theme }) => theme.queries.minDesktop} {
    margin: 48px 0 0 0;
  }
`;

export const TabGroup = styled.ul`
  display: flex;
  width: 100%;
  transition: margin-left 0.5s ease-in-out;
  border-radius: 8px;
  padding: 2px;
  background-color: ${({ theme }) => theme.colors.neutral.lavenderLight};

  @media ${({ theme }) => theme.queries.minDesktop} {
    border-radius: 0;
    background-color: white;
    padding: 0;
  }
`;

export const TabGroupContainer = styled.div<{ overflowMode: boolean }>`
  width: 100%;
  position: relative;
  overflow: hidden;

  ${TabGroup} {
    ${({ overflowMode }) =>
      overflowMode &&
      css`
        padding-left: 50px;
        padding-right: 50px;
      `}
  }
`;

const arrowButtonStyles = css<{ display: boolean; disabled: boolean }>`
  width: 50px;
  height: 100%;
  background: white;
  position: absolute;
  z-index: 100;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ display }) =>
    !display &&
    css`
      display: none;
    `}

  img {
    width: 16px;
    height: 16px;

    ${({ disabled }) =>
      disabled &&
      css`
        opacity: 0.3;
      `}
  }
`;

export const TabGroupPreviousButton = styled.button.attrs({ type: 'button' })<{
  display: boolean;
  disabled: boolean;
}>`
  ${arrowButtonStyles}
  left: 0;
`;

export const TabGroupNextButton = styled.button.attrs({ type: 'button' })<{
  display: boolean;
  disabled: boolean;
}>`
  ${arrowButtonStyles}
  right: 0;
`;

export const Tab = styled.li<{ active: boolean; size?: TabsSize }>`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 8px 16px;
  gap: 10px;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral.lightNavy};
  background-color: ${({ theme }) => theme.colors.neutral.lavenderLight};
  border-radius: 6px;
  height: 32px;

  @media ${({ theme }) => theme.queries.minDesktop} {
    height: 54px;
    font-size: 18px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.neutral.lightNavy};
    background-color: white;
    border-radius: 0;
    padding: 0;
  }

  ${({ size }) =>
    size === 'lg' &&
    css`
      font-size: 24px;
      line-height: 32px;
      padding: 12px 16px;
    `}

  ${({ active }) =>
    active &&
    css`
      background-color: white;
      color: ${({ theme }) => theme.colors.neutral.primaryNavy};

      @media ${({ theme }) => theme.queries.minDesktop} {
        background: ${({ theme }) => theme.colors.yellow.lightestYellow};
        color: ${({ theme }) => theme.colors.neutral.primaryNavy};
        border-radius: 8px;
        box-shadow: 0 0 0 5px ${({ theme }) => theme.colors.yellow.lighterYellow} inset;
        position: relative;
      }
    `};

  @media (min-width: ${({ theme }) => theme.breakpoints.tabletLandscape}) {
    min-width: fit-content;
  }
`;

export const Text = styled.span`
  order: 2;
`;

export const PanelContainer = styled.div<{ scrollContainerHeight?: string }>`
  width: 100%;
  margin-top: 24px;

  @media ${({ theme }) => theme.queries.minDesktop} {
    margin-top: 16px;
  }
`;

export const Panel = styled.section<{ active: boolean }>`
  order: 999;
  width: 100%;
  display: none;

  ${({ active }) =>
    active &&
    css`
      display: flex;
      flex-direction: column;
    `}
`;

export const Icon = styled.span<{ active: boolean; size?: TabsSize; position?: TabIconPosition }>`
  opacity: 0.6;
  order: 3;

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
    `}

  ${({ position }) =>
    position === 'left' &&
    css`
      order: 1;
    `}

  > svg {
    width: 12px;
    height: 12px;

    ${({ size }) =>
      size === 'lg' &&
      css`
        width: 20px;
        height: 20px;
      `}
  }
`;
