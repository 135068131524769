import { pageUrl } from 'utilities/pageUrl';

import { KB360AppointmentType } from '../graphql/queries';

/* Therapy is a special case - we deep link into the booking quiz since it has separate appointment types for different locations. */
export const THERAPY_APPOINTMENT_TYPE: KB360AppointmentType = {
  id: -1,
  overrideUrl: pageUrl.booking.bookingQuiz({ type: 'question', id: 110 }),
  kb360Heading: 'General Wellness',
  name: 'Therapy',
  description:
    'We believe strongly in integrating physical and emotional health, and know that sometimes maintaining a sense of well-being and balance is best optimized with support from someone outside your friends and family. This virtual therapy session offers support and guidance from experienced therapists for life transitions and stressful events, whether involving fertility, identity issues, career, adulthood, grief, anxiety, depression, relationships or parenting. We will prioritize what you want to cover, and at your own pace. You are not alone! NOTE: This is not the therapy appointment that you are required to book if you are building your family using a donor. Please call 855-KND-BODY (563-2639) and a Patient Care Navigator will happily assist you with scheduling.',
  serviceTypes: [{ name: 'Pregnancy and Beyond' }, { name: 'Wellness' }]
};
