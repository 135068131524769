import { Tab } from 'components/v2/DeprecatedTabs/DeprecatedTabs.styles';
import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  padding: 24px 16px;

  @media ${({ theme }) => theme.queries.minDesktop} {
    width: 900px;
    padding: 64px;
    padding-top: 48px;
  }

  &:focus-visible {
    outline: none;
  }

  ${Tab} {
    min-width: fit-content;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  gap: 16px;
  margin-top: 24px;

  @media ${({ theme }) => theme.queries.minDesktop} {
    justify-content: flex-end;
    flex-direction: row;
    vertical-align: center;
  }
`;

export const Logo = styled.img`
  margin-top: 32px;
  margin-bottom: 16px;
  height: 24px;

  @media ${({ theme }) => theme.queries.maxTablet} {
    height: 16px;
    margin-top: 16px;
    width: 100%;
  }
`;

export const LogoutButtonContainer = styled.div`
  height: 64px;
`;
