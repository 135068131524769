import React from 'react';

import { Props } from './EggEmbryoInventory.types';
import {
  Card,
  CardContainer,
  CardItem,
  CardLabel,
  CardValue,
  HighlightedCardValue
} from './EggEmbryoInventoryStackView.styled';

export const EggEmbryoInventoryStackView = ({ oocytes }: Props) => {
  return (
    <CardContainer>
      {oocytes.map(item => (
        <Card key={item.id}>
          <CardItem>
            <CardLabel>ID</CardLabel>
            <HighlightedCardValue>{item.id}</HighlightedCardValue>
          </CardItem>
          <CardItem>
            <CardLabel>{item.cryoDate}</CardLabel>
          </CardItem>
          <CardItem>
            <CardLabel>Type</CardLabel>
            <CardValue>{item.specimenType}</CardValue>
          </CardItem>
          <CardItem>
            <CardLabel>Cryo Day</CardLabel>
            <CardValue>{item.cryoDay}</CardValue>
          </CardItem>
          <CardItem>
            <CardLabel>Lab Location</CardLabel>
            <CardValue>{item.labLocation}</CardValue>
          </CardItem>
          <CardItem>
            <CardLabel>Grade</CardLabel>
            <CardValue>{item.grade}</CardValue>
          </CardItem>
        </Card>
      ))}
    </CardContainer>
  );
};
