import { CircleExclamation, DocumentLink } from 'assets/icons/Icons';
import { AbsoluteCentered } from 'styled';
import styled from 'styled-components';

export const Consent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  border-bottom: 1px solid #abaeba;
  padding: 12px 0 12px 0;
  min-height: 72px;

  @media ${({ theme }) => theme.queries.maxTablet} {
    margin-top: 0px;
  }

  .checkmark {
    width: 24px;
    height: 24px;
    min-width: 24px;

    @media ${({ theme }) => theme.queries.maxTablet} {
      margin-top: 0;
    }
  }
`;

export const ConsentsSection = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 12px;
  padding: 16px 12px 16px 12px;
  background-color: ${({ theme }) => theme.colors.neutral.lavenderWhite};
  border-radius: 8px;
  @media ${({ theme }) => theme.queries.minDesktop} {
    border-radius: 16px;
    padding: 24px 24px 32px 24px;
  }
`;

export const CustomAbsoluteCentered = styled(AbsoluteCentered)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutral.lavenderLight};
  opacity: 0.4;
`;

export const RedExclamationIcon = styled(CircleExclamation).attrs({ type: 'solid' })`
  width: 16px;
  height: 16px;
  color: ${({ theme }) => theme.colors.semantic.darkRed};
`;

export const DocumentLinkSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const DocumentLinkContainer = styled.div`
  width: 48px;
  height: 48px;
`;

export const DocumentLinkIcon = styled(DocumentLink)`
  width: 16px;
  height: 16px;
`;

export const ContentSection = styled.div``;
