import React, { forwardRef, useEffect, useCallback, useRef, useState } from 'react';

import { escapeRegex } from '../CountrySelect';
import { TextInput } from '../TextInput';
import {
  Container,
  OptionList,
  OptionStyled,
  SearchContainer
} from './InputWithAutocomplete.styled';
import { Props, Option } from './InputWithAutocomplete.types';

export const InputWithAutocomplete = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    id,
    onSelect,
    onChange,
    searchFn,
    selected,
    options,
    maxNumberOfDisplayedOptions,
    minValueLengthToDisplayOptions,
    placeholder,
    status
  } = props;
  const isSelected = (option: Option): boolean =>
    selected?.value === option.value && selected?.label === option.label;

  const [value, setValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<Props['options']>(options);
  const [filteredOptionsVisible, setFilteredOptionsVisible] = useState(false);
  const [isUserInteracting, setIsUserInteracting] = useState(false);
  const hideTimeoutRef = useRef<NodeJS.Timeout>();
  const firstOptionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    return () => {
      if (hideTimeoutRef.current) {
        clearTimeout(hideTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const refElement = ref as React.RefObject<HTMLDivElement>;
      if (refElement?.current && !refElement.current.contains(event.target as Node)) {
        setFilteredOptionsVisible(false);
      }
    };

    if (filteredOptionsVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [filteredOptionsVisible, ref]);

  const startHideTimer = useCallback(() => {
    if (hideTimeoutRef.current) {
      clearTimeout(hideTimeoutRef.current);
    }

    if (filteredOptionsVisible && !isUserInteracting) {
      hideTimeoutRef.current = setTimeout(() => {
        setFilteredOptionsVisible(false);
      }, 10000);
    }
  }, [filteredOptionsVisible, isUserInteracting]);

  useEffect(() => {
    if (!isUserInteracting && filteredOptionsVisible) {
      startHideTimer();
    }
  }, [filteredOptionsVisible, startHideTimer, isUserInteracting]);

  const search = (term: string): void => {
    if (!term) {
      return setFilteredOptions(getFirstNOptionsForDisplay(maxNumberOfDisplayedOptions, options));
    }

    if (searchFn) {
      const filteredByTerm = searchFn(options, term);
      setFilteredOptions(getFirstNOptionsForDisplay(maxNumberOfDisplayedOptions, filteredByTerm));
    } else {
      const regex = new RegExp(`.*${escapeRegex(term)}.*`, 'i');
      const filteredByTerm = options.filter(option => regex.test(option.label));
      setFilteredOptions(getFirstNOptionsForDisplay(maxNumberOfDisplayedOptions, filteredByTerm));
    }
  };

  const getFirstNOptionsForDisplay = (
    n: Props['maxNumberOfDisplayedOptions'],
    optionsForDisplay: Props['options']
  ) => {
    if (!n || n > optionsForDisplay.length) return [...optionsForDisplay];
    if (n < 0) return [];
    return optionsForDisplay.slice(0, n);
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'ArrowDown' && filteredOptionsVisible && filteredOptions.length > 0) {
      e.preventDefault();

      requestAnimationFrame(() => {
        const firstOption = document.getElementById(optionId(id, 0));
        if (firstOption) {
          firstOption.focus({ preventScroll: true });
        }
      });
    } else if (e.key === 'Escape' && filteredOptionsVisible) {
      setFilteredOptionsVisible(false);
    }
  };

  const handleOptionKeyDown = (
    e: React.KeyboardEvent<HTMLDivElement>,
    option: Option,
    currentIndex: number
  ) => {
    switch (e.key) {
      case 'Enter':
        setValue(option.label);
        setFilteredOptionsVisible(false);
        onSelect(option);
        break;
      case 'ArrowUp':
        e.preventDefault();
        if (currentIndex === 0) {
          const inputElement = document.getElementById(`input-autocomplete-search-${id}`);
          inputElement?.focus();
        } else {
          const prevOption = document.getElementById(optionId(id, currentIndex - 1));
          prevOption?.focus();
        }
        break;
      case 'ArrowDown':
        e.preventDefault();
        if (currentIndex < filteredOptions.length - 1) {
          const nextOption = document.getElementById(optionId(id, currentIndex + 1));
          nextOption?.focus();
        }
        break;
    }
  };

  const optionId = (inputId: string, optionIndex: number) => `option-${inputId}-${optionIndex}`;

  return (
    <Container id={id} role="listbox" ref={ref}>
      <SearchContainer>
        <TextInput
          id={`input-autocomplete-search-${id}`}
          type="search"
          placeholder={placeholder}
          value={value}
          onChange={e => {
            const newValue = e.currentTarget.value;
            setValue(newValue);
            const shouldShowOptions = newValue.length >= minValueLengthToDisplayOptions;
            setFilteredOptionsVisible(shouldShowOptions);

            if (shouldShowOptions) startHideTimer();

            search(newValue);
            onChange?.(e);
          }}
          autoComplete="off"
          onKeyDown={handleInputKeyDown}
          status={status}
        />
      </SearchContainer>

      {filteredOptionsVisible && filteredOptions.length > 0 && (
        <OptionList
          onMouseEnter={() => setIsUserInteracting(true)}
          onMouseLeave={() => setIsUserInteracting(false)}
        >
          {filteredOptions.map((option, index) => (
            <OptionStyled
              id={optionId(id, index)}
              ref={index === 0 ? firstOptionRef : null}
              onClick={() => {
                setValue(option.label);
                setFilteredOptionsVisible(false);
                onSelect(option);
              }}
              key={`${option.value} ${option.label}`}
              aria-selected={isSelected(option)}
              onKeyDown={e => handleOptionKeyDown(e, option, index)}
              data-focusable="true"
              tabIndex={-1}
              role="option"
            >
              {option.label}
            </OptionStyled>
          ))}
        </OptionList>
      )}
    </Container>
  );
});

// must be set because of forwardRef otherwise eslint complains
InputWithAutocomplete.displayName = 'InputWithAutocomplete';
