import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import CreateAccount from 'components/auth/CreateAccount/CreateAccount';
import { SubStep } from 'components/auth/CreateAccount/CreateAccount.types';
import { AuthFlowLayout } from 'components/AuthFlowLayout/AuthFlowLayout';
import { EmployerBenefitCTA } from 'components/EmployerBenefitCTA/EmployerBenefitCTA';
import { Loader } from 'components/Loader/Loader';
import { StepCountHeading } from 'components/StepCountHeading/StepCountHeading';
import { Button } from 'components/v2/Buttons/Button';
import { usePatient } from 'hooks';
import { useBookingState } from 'kb-shared/booking/hooks/useBookingState';
import { StepType } from 'kb-shared/booking/types/types';
import { bookingAnalytics } from 'kb-shared/booking/utils/bookingAnalytics';
import { BookingSearchParams } from 'kb-shared/booking/utils/bookingSearchParams';
import { getAppointmentType } from 'kb-shared/booking/utils/getters';
import { toIntOrNull } from 'kb-shared/booking/utils/validation';
import { PartnerClinicDetails } from 'kb-shared/types';
import { BackButtonContainer, Container, Content } from 'screens/Book/Book.styled';
import { getPageTitle } from 'screens/Book/Book.utils';
import { LocationStep } from 'screens/Book/steps/Location/Location';
import { TimeStep } from 'screens/Book/steps/Time/Time';
import { analytics } from 'utilities/analytics';

import { steps } from './CreateAccount.constants';
import { useLab } from './CreateAccount.hooks';
import { getAuthSubStepTitle, getStepNumber } from './CreateAccount.utils';

export const CreateAccountScreen = () => {
  const history = useHistory();
  const location = useLocation();
  const { isLoggedIn } = usePatient();
  const [currentSubStep, setCurrentSubStep] = useState<SubStep>('choose_signup_option');
  const searchParams = new URLSearchParams(history.location.search);
  const { loading, error, entities, bookingParams, calculatedStep } = useBookingState();
  const previousScreenIsLogin = searchParams.get('previous') === 'login';
  const target = searchParams.get('target');
  const labId = bookingParams.get('lab_id') ?? '';
  const authSubStepTitle = getAuthSubStepTitle(currentSubStep);
  const { lab, loading: loadingLab } = useLab(parseInt(labId));
  const isEnterpriseMembership = Boolean(target?.includes('enterprise_membership=true'));
  const redirectingUserRef = useRef(false);

  const [partnerClinic, setPartnerClinic] = useState<PartnerClinicDetails | null>(null);
  const currentStepNumber = getStepNumber(calculatedStep as typeof steps[keyof typeof steps]);
  const bookingPageTitle = getPageTitle({
    partnerClinicSearch: bookingParams.get('partner_clinic_search') ?? '',
    selectedProduct: entities.selectedProduct || null,
    stepType: calculatedStep as StepType,
    partnerClinicSelected: partnerClinic
  });

  // used to redirect the user after a successful login
  useEffect(() => {
    // if redirect is already in progress, do not redirect again,
    // or if user is not logged in, skip everything
    if (redirectingUserRef.current || !isLoggedIn) {
      return;
    }

    // continue booking flow if booking params are present
    if (bookingParams.containsBookingDataParams()) {
      const params = bookingParams.clone();

      // if time slot is selected, skip to insurance step
      if (bookingParams.has('time_slot_id')) {
        params.set('step', 'insurance');
        history.push(`/Book?${params}`);
      } else {
        // if no time slot is selected, keep user on time step
        history.push(`/Book?${params}`);
      }
    } else {
      // If no booking params are present, redirect to home page or target page.
      // It seems like there is some race condition where user info is not available before redirect.
      // In that case user is redirected to /login first and then to the target page
      if (target) {
        history.push(target);
      } else {
        history.push('/');
      }
    }

    redirectingUserRef.current = true;
  }, [isLoggedIn, history, bookingParams, target]);

  const {
    selectedClinic,
    selectedLab,
    selectedProduct,
    selectedTimeSlot,
    allAppointmentTypes
  } = entities;

  const appointmentType = getAppointmentType(entities.selectedProduct);

  const renderLocationStep = (mobile: boolean) => (
    <LocationStep
      appointmentType={appointmentType || undefined}
      selectedAppointmentTypes={allAppointmentTypes ?? []}
      partnerClinicSearch={bookingParams.get('partner_clinic_search') ?? ''}
      mobile={mobile}
      partnerClinicSelected={partnerClinic}
      selectedClinic={selectedClinic}
      selectedProduct={selectedProduct}
      // @ts-ignore-next-line
      selectedLab={selectedLab}
      onSelectClinic={(clinic, inPersonAppointmentType) => {
        const params = new BookingSearchParams(location.search);
        params.merge({
          clinic_id: clinic?.id.toString() ?? '',
          step: 'time',
          appointment_id: inPersonAppointmentType?.id || bookingParams.get('appointment_id') || ''
        });
        bookingAnalytics.track.clinicSelected(clinic);
        history.push(`/create-account?${params}`);
      }}
      onSelectLab={lab => {
        const params = new BookingSearchParams(location.search);
        // remain on the same step to allow user to select a clinic
        params.set('lab_id', lab?.id ?? '');
        bookingAnalytics.track.labSelected(lab?.id);
        history.push(`/create-account?${params}`);
      }}
      onSelectVirtual={appointmentType => {
        if (!appointmentType) {
          return;
        }

        const params = new BookingSearchParams(location.search);
        params.remove('clinic_id');
        params.merge({
          step: 'time',
          appointment_id: appointmentType?.id ?? ''
        });
        bookingAnalytics.track.virtualClinicSelected();
        history.push(`/create-account?${params}`);
      }}
      onContactUsClick={() => {
        const params = new BookingSearchParams(location.search);
        params.set('partner_clinic_search', 'search');
        bookingAnalytics.track.partnerClinicSearchStarted();
        history.push(`/create-account?${params}`);
      }}
      onPartnerClinicSelected={clinic => setPartnerClinic(clinic)}
      onAfterSend={() => {
        const params = new BookingSearchParams(location.search);
        params.set('step', 'create_account');
        history.push(`/create-account?${params}`);
      }}
    />
  );

  const renderTimeStep = () => (
    <TimeStep
      date={bookingParams.get('date') ?? undefined}
      providerId={toIntOrNull(bookingParams.get('provider_id')) ?? undefined}
      appointmentType={appointmentType || undefined}
      selectedClinic={selectedClinic}
      selectedLab={selectedLab}
      selectedTimeSlot={selectedTimeSlot}
      onTimeSlotSelect={timeSlot => {
        const params = new BookingSearchParams(location.search);
        params.merge({
          time_slot_id: timeSlot.id,
          step: 'create_account',
          appointment_id: bookingParams.get('appointment_id') ?? ''
        });
        bookingAnalytics.track.timeSlotSelected(timeSlot);
        history.push(`/create-account?${params}`);
      }}
    />
  );

  const renderCreateAccountStep = ({
    mobile,
    asBookingStep
  }: {
    mobile: boolean;
    asBookingStep: boolean;
  }) => (
    <CreateAccount
      mobileLayout={mobile}
      lab={lab ?? null}
      createAccountSubstep={currentSubStep}
      onAuthentication={() => {
        // onAuthentication callback is called on signup, but not on login even though the name might suggest otherwise
        if (asBookingStep) {
          const params = bookingParams.clone();
          params.set('step', 'insurance');
          // TODO: this should not trigger on every flow(some users already have insurance and will skip this step)
          // should be moved to insurance step itself
          bookingAnalytics.page.enterInsuranceInformation();
          history.push(`/Book?${params}`);
        } else {
          if (target && target.startsWith('/')) {
            history.push(target);
          } else {
            history.push('/');
          }
        }
      }}
      onChangeSubstep={(step: SubStep) => setCurrentSubStep(step)}
      signInLinkIsVisible={previousScreenIsLogin}
    />
  );

  if (loading || error) {
    return <Loader container />;
  }

  if (isLoggedIn) {
    return null;
  }

  const showBookingStepTitle =
    bookingParams.containsBookingDataParams() && calculatedStep !== 'create_account';

  const handleClick = () => {
    analytics.track(analytics.EVENTS.CREATE_ACCOUNT_SIGN_UP_TO_ACTIVATE);
  };

  return (
    <AuthFlowLayout
      title={showBookingStepTitle ? bookingPageTitle : authSubStepTitle}
      renderAboveTitleContent={() => (
        <>
          {!isEnterpriseMembership && <EmployerBenefitCTA onClick={handleClick} />}
          {bookingParams.containsBookingDataParams() && (
            <StepCountHeading currentStep={currentStepNumber} totalSteps={5} />
          )}
        </>
      )}
      renderMainContent={(mobile: boolean) => {
        if (loadingLab) return <Loader />;

        return (
          <Container>
            <Content>
              {bookingParams.containsBookingDataParams() ? (
                <>
                  {calculatedStep === 'location' && renderLocationStep(mobile)}
                  {calculatedStep === 'time' && renderTimeStep()}
                  {calculatedStep === 'create_account' &&
                    renderCreateAccountStep({ mobile, asBookingStep: true })}
                </>
              ) : (
                renderCreateAccountStep({
                  mobile,
                  asBookingStep: false
                })
              )}
            </Content>
            {!previousScreenIsLogin && (
              <BackButtonContainer>
                <Button
                  label="Back"
                  fullWidth
                  size="lg"
                  category="secondary"
                  onClick={() => {
                    if (currentSubStep === 'verification_code') {
                      setCurrentSubStep('choose_signup_option');
                    } else {
                      history.goBack();
                    }
                  }}
                />
              </BackButtonContainer>
            )}
          </Container>
        );
      }}
    />
  );
};
