import React, { useState } from 'react';

import { InvitePartnerFormProps } from '../../InterstitialModal.types';
import { InvitePartner } from './InvitePartner';
import { PartnerList } from './PartnerList';

export const InvitePartnerForm = ({ invites, refetch }: InvitePartnerFormProps) => {
  const [invitePartner, setInvitePartner] = useState<boolean>(false);

  return (
    <>
      <PartnerList invites={invites} onAddPartner={() => setInvitePartner(true)} />
      {invitePartner && (
        <InvitePartner
          onDone={() => {
            setInvitePartner(false);
            refetch();
          }}
        />
      )}
    </>
  );
};
