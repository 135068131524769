import React from 'react';

import { PromoCodeWrapper, ApplyButton, StretchTextField } from './PromoCode.styled';

type Props = {
  value: string;
  error: boolean | null;
  onChange: (value: string) => void;
  onSubmit: () => void;
  className?: string;
};

export const PromoCode = (props: Props) => {
  const { value, error, onChange, onSubmit, className } = props;
  const canSubmit = value.length && !error;

  return (
    <PromoCodeWrapper className={className}>
      <StretchTextField
        id="promo-code"
        type="text"
        label="Promo code (Optional)"
        placeholder="Enter promo code..."
        value={value}
        onChange={e => onChange(e.currentTarget.value)}
        status={value.length && error ? 'error' : 'default'}
      />
      <ApplyButton onClick={onSubmit} label="APPLY" isDisabled={canSubmit ? false : true} />
    </PromoCodeWrapper>
  );
};
