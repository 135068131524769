import React, { Fragment, useRef, useEffect } from 'react';
import { useParams } from 'react-router';

import { Headset } from 'assets/icons/Icons';
import { Loader } from 'components/Loader/Loader';
import useContactInfo from 'hooks/useContactInfo';
import { Category, MessageParams } from 'screens/Messages/Messages.type';
import { getCategoryInfo, isResolvedConversation } from 'screens/Messages/Messages.utils';

import { CATEGORY_SLUG } from '../../Messages.constants';
import { CategoryIcon } from '../CategoryIcon/CategoryIcon';
import { DateSeparator } from '../DateSeparator/DateSeparator';
import { Message } from '../Message/Message';
import { MessageOwner } from '../MessageOwner/MessageOwner';
import { ReassignmentInfo } from '../ReassignmentInfo/ReassignmentInfo';
import {
  Container,
  Empty,
  Title,
  Description,
  ConversationInfo,
  EmergencyContact
} from './MessageList.styled';
import { Props } from './MessageList.types';
export { CATEGORY_SLUG } from '../../Messages.constants';

export const MessageList = ({ loading, conversation }: Props) => {
  const { categoryName } = useParams<MessageParams>();
  const messageRef = useRef<HTMLDivElement>(null);
  const { number } = useContactInfo();

  const currentCategory = getCategoryInfo(categoryName);

  const isConversationResolved = isResolvedConversation(conversation?.status);
  const numberOfMessages = conversation?.messages?.length;

  useEffect(() => {
    if (messageRef.current) {
      messageRef.current.scrollTop = messageRef.current?.scrollHeight;
    }
  }, [numberOfMessages]);

  if (loading) return <Loader container />;

  return (
    <Container ref={messageRef}>
      <Empty>
        <CategoryIcon size="lg" icon={currentCategory.icon} />
        <Title>{currentCategory?.title}</Title>
        <Description>{currentCategory.description}</Description>
        {!isConversationResolved && (
          <EmergencyContact>
            <Headset type="solid" />
            <span>{getEmergencyMessage(currentCategory, number)}</span>
          </EmergencyContact>
        )}
      </Empty>

      {conversation?.messages?.map((message, index) => {
        const previousMessage = conversation.messages[index - 1];

        return (
          <Fragment key={index}>
            <DateSeparator previousMessage={previousMessage} currentMessage={message} />

            <ReassignmentInfo message={message} conversation={conversation} />

            <MessageOwner
              previousMessage={previousMessage}
              currentMessage={message}
              currentCategory={currentCategory}
            />

            <Message message={message} />
          </Fragment>
        );
      })}

      {isConversationResolved && (
        <ConversationInfo>
          A member of the Kindbody team has closed this conversation. Please send us a new message
          if you have another issue or question.
        </ConversationInfo>
      )}
    </Container>
  );
};

const isBenefit = (slug: string) =>
  slug === CATEGORY_SLUG.BENEFITS || slug === CATEGORY_SLUG.EMPLOYER_BENEFIT;

const getEmergencyMessage = (currentCategory: Category, defaultNumber: string) => {
  if (currentCategory.slug === CATEGORY_SLUG.BILLING)
    return 'For immediate assistance related to your bill, please call the phone number on your statement. If this is a medical emergency, please call 911.';

  return (
    <>
      We typically reply within {isBenefit(currentCategory.slug) ? '3-4' : '2'} business days. If
      this is a medical emergency, please call 911.
      {currentCategory.slug === 'medical' && (
        <span>
          {' '}
          If this is an urgent medical issue that cannot wait until the next day, call the
          after-hours on-call team at <a href={`tel:${defaultNumber}`}>{defaultNumber}</a>
        </span>
      )}
    </>
  );
};
