import { useMutation } from '@apollo/client';
import React, { useState } from 'react';

import Button from 'components/Buttons/NewButton';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import KBContacts from 'kb-shared/utilities/kindbody_contacts';
import { analytics } from 'utilities/analytics';
import { showErrorToast } from 'utilities/notificationUtils';

import { PATIENT_CONSENT_SUBMIT } from './ConsentAcceptAction.graphql';
import { ConsentAcceptActionButtonWrapper } from './ConsentAcceptAction.styled';
import {
  ConsentAcceptActionProps,
  PatientConsentSubmitResponseData,
  PatientConsentSubmitVariables,
  PatientFillableField,
  PatientFillableFieldBorder
} from './ConsentAcceptAction.types';

export function ConsentAcceptAction({ consentId, buttonText, onAgree }: ConsentAcceptActionProps) {
  const [defaultPatientFieldsBorders, setDefaultPatientFieldsBorders] = useState<
    Array<PatientFillableFieldBorder>
  >([]);

  const [submitConsent, { loading }] = useMutation<
    PatientConsentSubmitResponseData,
    PatientConsentSubmitVariables
  >(PATIENT_CONSENT_SUBMIT, {
    onCompleted: (response: PatientConsentSubmitResponseData) => onDidAgree(response),
    onError: error => {
      if (
        error.graphQLErrors?.length === 1 &&
        error.graphQLErrors[0].extensions &&
        error.graphQLErrors[0].extensions['unfilled_fields']
      ) {
        const markedFieldsBorders = markUnfilledFields(
          error.graphQLErrors[0].extensions['unfilled_fields']
        );
        if (markedFieldsBorders) {
          setDefaultPatientFieldsBorders(markedFieldsBorders);
        }

        showErrorToast(
          'Failed to sign the consent. Please fill out all required fields marked with red border and then try again.'
        );
      } else {
        BugTracker.notify(error, 'Failed to Sign Consent');
        showErrorToast(
          `Failed to sign the consent. Please wait then try again, or contact ${KBContacts.navigatorEmail}`
        );
      }
    }
  });

  const onDidAgree = (response: PatientConsentSubmitResponseData) => {
    if (!response?.createPatientConsent) return;
    onAgree(response.createPatientConsent.patientConsent.id);
  };

  const onSubmitConsent = () => {
    analytics.track(analytics.EVENTS.PATIENT_CONSENT_SIGN_ATTEMPTED);
    const patientFilledFields: PatientFillableField = collectPatientFilledFields();
    const variables: PatientConsentSubmitVariables = {
      consentId: consentId
    };
    if (Object.keys(patientFilledFields).length !== 0)
      variables.patientFilledFields = patientFilledFields;

    unmarkPatientFilledFields(defaultPatientFieldsBorders);
    submitConsent({
      variables: variables
    });
  };

  return (
    <ConsentAcceptActionButtonWrapper>
      <Button text={buttonText} loading={loading} disabled={loading} onClick={onSubmitConsent} />
    </ConsentAcceptActionButtonWrapper>
  );
}

const PATIENT_FIELDS_SELECTOR = '#consentContainer input, #consentContainer textarea';
const MARKED_PATIENT_FIELDS_BORDER = '2px solid red';

const collectPatientFilledFields = () => {
  const patientFilledFields: PatientFillableField = {};
  document.querySelectorAll(PATIENT_FIELDS_SELECTOR).forEach(fillableField => {
    const field = fillableField as HTMLInputElement;
    // BE expects all values to be strings
    patientFilledFields[field.id] = field.checked != null ? field.checked.toString() : field.value;
  });

  return patientFilledFields;
};

const unmarkPatientFilledFields = (defaultPatientFieldsBorders: PatientFillableFieldBorder[]) => {
  document.querySelectorAll(PATIENT_FIELDS_SELECTOR).forEach(fillableField => {
    const field = fillableField as HTMLInputElement;
    if (field && field.style.border === MARKED_PATIENT_FIELDS_BORDER) {
      const defaultBorder = defaultPatientFieldsBorders.find(
        border => border.fieldId === fillableField.id
      );
      if (defaultBorder) {
        field.style.border = defaultBorder.fieldBorder;
      }
    }
  });
};

const markUnfilledFields = (fieldsKeys: string[]): PatientFillableFieldBorder[] | undefined => {
  if (!fieldsKeys) return;

  const fieldsBorders: PatientFillableFieldBorder[] = [];

  fieldsKeys.forEach((fieldKey, index) => {
    const field = document.getElementById(fieldKey) as HTMLInputElement;
    if (!field) return;

    fieldsBorders.push({ fieldId: fieldKey, fieldBorder: field.style.border });
    field.style.border = MARKED_PATIENT_FIELDS_BORDER;

    if (index === 0) {
      field.scrollIntoView({ block: 'start' });
      // scroll 140px above the field to prevent toast or page header overiding the field
      window.scrollBy(0, -140);
    }
  });

  return fieldsBorders;
};
