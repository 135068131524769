import BaseModal from 'components/Modal/BaseModal';
import styled from 'styled-components';

const overlayClassName = 'styled-overlay-classname';
const modalClassName = 'styled-modal-classname';

interface StyledModalProps {
  mobileFullscreen?: boolean;
  fullScreen?: boolean;
  maxWidth?: number;
  addPadding?: boolean;
}

export const CustomReactModal = styled(BaseModal).attrs({
  overlayClassName,
  modalClassName
})<StyledModalProps>`
  .${overlayClassName} {
    position: fixed;
    z-index: 301;
    inset: 0px;
    height: 100%;
    overflow-y: scroll;
    padding: 0px;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(39, 42, 94, 0.48);
  }

  .${modalClassName} {
    position: absolute;
    top: auto;
    bottom: 0;
    border-radius: 16px 16px 0px 0px;
    background: ${({ theme }) => theme.colors.white};
    box-shadow: ${({ theme }) => theme.shadows.card.level3};
    max-width: ${({ maxWidth }) => `${maxWidth}px`};
    max-height: 100%;
    height: auto;
    overflow-y: auto;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media ${({ theme }) => theme.queries.minTablet} {
      padding: 48px;
    }

    @media ${({ theme }) => theme.queries.minDesktop} {
      position: relative;
      border-radius: 16px;
      padding: 48px 64px 64px 64px;
    }
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-bottom: 8px;
  margin-bottom: 24px;
`;

export const CloseIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 32px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.neutral.lighterNavy};
  font-size: 20px;
`;
