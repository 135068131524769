import React, { ReactNode } from 'react';

import Footer from 'components/Footer/Footer';
import styled from 'styled-components';

import { NavigationContainer } from './Navigation/NavigationContainer/NavigationContainer';

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'main'
    'footer';
  height: 100vh;

  @media ${({ theme }) => theme.queries.minDesktop} {
    grid-template-columns: 80px auto;

    grid-template-areas:
      'navbar main'
      'navbar footer';
  }
`;

const Main = styled.main`
  grid-area: main;
  margin-bottom: 80px;
`;

interface Props {
  children: ReactNode;
}

const MainLayout = (props: Props) => {
  const { children } = props;

  return (
    <GridContainer>
      <NavigationContainer />
      <Main>{children}</Main>
      <Footer />
    </GridContainer>
  );
};

export default MainLayout;
