import { Text, Heading } from 'components/v2/Typography';
import styled from 'styled-components';

export const CardLabel = styled(Text).attrs({ size: 'sm' })`
  color: ${({ theme }) => theme.colors.neutral.lightNavy};
`;

export const HighlightedCardValue = styled(Heading).attrs({
  styledAs: 'h4',
  tag: 'span'
})`
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  font-size: 25px;
`;

export const CardValue = styled.div`
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  font-size: 16px;
  font-weight: 600;
  margin-top: 4px;
`;

export const CardItem = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const Card = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;

  ${CardItem}:nth-child(2) ${CardLabel}:nth-child(1) {
    text-align: right;
  }
`;
