import styled from 'styled-components';

export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px;
  box-shadow: -4px 4px 0 0 ${({ theme }) => theme.colors.neutral.primaryNavy};
  border: solid 2px ${({ theme }) => theme.colors.neutral.primaryNavy};

  @media ${({ theme }) => theme.queries.minDesktop} {
    width: 359px;
  }

  @media ${({ theme }) => theme.queries.tablet} {
    width: 276px;
  }

  @media ${({ theme }) => theme.queries.mobile} {
    width: 288px;
  }
`;

export const SecureMessageLink = styled.a`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  text-decoration: underline;
  font-weight: bold;
`;

export const Container = styled.div`
  padding: 20px;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  height: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
`;

export const CardTitle = styled.div`
  font-weight: bold;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
`;

export const AppointmentName = styled.span`
  font-size: 14px;
  background-color: ${({ theme }) => theme.colors.neutral.lavender};
  padding: 4px 12px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white};
  margin-top: 20px;
`;

export const AppointmentLocation = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: column;
`;

export const ClinicName = styled.span`
  font-size: 24px;
  margin-bottom: 10px;
`;

export const Address = styled.span``;

export const LocationWrapper = styled.div`
  display: flex;
  margin: 28px 0;
`;

export const FutureActionsWrapper = styled.div`
  align-self: flex-end;
  align-items: center;
  font-size: 16px;
  padding: 5px;
  padding-right: 10px;
`;

export const Tag = styled.span`
  font-size: 14px;
  background-color: ${({ theme }) => theme.colors.semantic.lightPink};
  padding: 4px 5px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.neutral.navy};
  margin-top: 20px;
`;
